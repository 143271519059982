<template>
  <div class="mb-10 pb-20 bg-dark text-white">
    <div class="container">
      <div class="flex flex-wrap items-center">
        <DetailList :details="details" background="dark" />

        <VenueAmenities v-if="story?.content?.facilities" :amenities="story.content.facilities" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTranslation } from '~/composables/useTranslation';
import type { ISbStoryData } from 'storyblok-js-client';
import type { VenueProps } from '~/types/Venue';

const story = useState<ISbStoryData<VenueProps>>('story');
const storyType = story.value?.content?.component;

const translate = useTranslation();

const details = computed(() => {
  if (storyType === 'Venue') {
    const halls = story.value?.content?.SyncVenueHalls;

    return halls?.reduce((accumulator: { label?: string; value: string }[], hall, hallIndex) => {
      accumulator.push({
        label: hallIndex === 0 ? translate('label.halls') : '',
        value: hall.content?.Name || hall.content?.SyncName,
      });

      return accumulator;
    }, []);
  }

  return [];
});
</script>
