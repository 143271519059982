<template>
  <div
    class="richText-embedWrapper grid grid-cols-8 lg:grid-cols-12 gap-x-3 lg:gap-x-6"
    :class="[`richText-embedWrapper--${container}`, smallHorizontal]"
    v-editable="blok"
  >
    <Shade v-if="layered" :class="classStack" position="topLeft" transparent>
      <MxAssetRenderer
        :asset="asset"
        :show-caption="blok.showCaption"
        :video-config="{
          autoplay: autoplayVideo,
          muted: autoplayVideo,
          wrapperClass: 'not-prose',
        }"
      />
    </Shade>

    <div v-if="Array.isArray(assets) && assets?.length > 1" :class="classStack">
      <a v-for="asset in assets" :href="asset.meta_data?.MediaURL">
        <MxAssetRenderer
          :key="asset.public_id"
          :asset="asset"
          image-class="h-full !h-[80px] w-auto max-w-none"
          :show-caption="blok.showCaption"
          :video-config="{
            autoplay: autoplayVideo,
            muted: autoplayVideo,
            wrapperClass: 'not-prose',
          }"
        />
      </a>
    </div>

    <MxAssetRenderer
      v-else
      :class="classStack"
      :asset="asset"
      :show-caption="blok.showCaption"
      :video-config="{
        autoplay: autoplayVideo,
        muted: autoplayVideo,
        wrapperClass: 'not-prose',
      }"
    />
  </div>
</template>

<script setup lang="ts">
import type { RichTextContainerType, RichTextMediaProps } from '~/types/RichTextBlock';
import { Shade } from '#components';

const { blok, container = 'narrow' } = defineProps<{
  blok: RichTextMediaProps;
  container: RichTextContainerType;
}>();
const { layered, layout, asset, assets, autoplayVideo } = blok;

const classStack = [
  'col-span-8',
  {
    'lg:col-span-12': layout === 'full',
    'lg:col-span-8': layout === 'left' || layout === 'right',
    'lg:col-start-5': layout === 'right',
    'flex flex-row flex-wrap gap-6 lg:gap-8': layout === 'small-horizontal',
  },
];

const smallHorizontal = [{ '!ml-0 !w-full': layout === 'small-horizontal' }];
</script>
