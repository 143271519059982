import type { TranslationsType } from '~/types/common';

const useTranslations = () => {
  try {
    const translations = useState<TranslationsType>('translations');
    const currentLanguage = useState<string>('language');

    if (!translations.value) {
      console.error('Cannot useTranslation: missing translation data')
      return {}
    }

    return translations.value[currentLanguage.value];
  } catch (error) {
    console.error('Cannot useTranslation')
    console.error(error)
    return {}
  }
};

export const useTranslation = () => {
  return (marker: string) => {
    return useTranslations()?.[marker] || marker;
  };
};
