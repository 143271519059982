<template>
  <div
    class=""
    :class="{
      'bg-dark text-white': background === 'dark',
      'bg-light-white': background === 'light',
      'py-16 lg:py-20': !separators,
      'py-24 lg:py-32': !!separators,
    }"
    v-editable="blok"
  >
    <div
      class="container"
      :class="{
        'hasSeparators flex flex-col gap-y-20 overflow-hidden': !!separators,
      }"
      :id="blok?.id"
    >
      <div
        :class="{
          'max-w-[626px] mx-auto text-center': layout === 'single',
        }"
      >
        <h2
          v-if="heading"
          class="mb-9 xl:mb-10 font-secondary text-5xl"
          :class="{
            'lg:text-7xl': layout === 'single',
            'lg:text-6xl': layout === 'double',
          }"
        >
          {{ heading }}
        </h2>

        <div
          class="text-2xl"
          :class="{
            'lg:text-3xl font-light': layout === 'single',
            'lg:columns-2 lg:gap-x-8': layout === 'double',
          }"
        >
          <p v-if="typeof text === 'string'">{{ text }}</p>
          <Vue3RuntimeTemplate v-else :template="renderRichText(text)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Vue3RuntimeTemplate from 'vue3-runtime-template';
import type { FeaturedTextBlockProps } from '~/types/FeaturedTextBlock';

const { blok } = defineProps<{ blok: FeaturedTextBlockProps }>();
const { heading, text, layout, background, separators } = blok;
</script>

<style scoped>
/* Note: hasSeparators styled in tailwind.css */
</style>
