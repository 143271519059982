<template>
  <div
    :class="{
      'bg-dark text-white': background === 'dark',
      'bg-light-white': background === 'light',
    }"
  >
    <div
      class="hasSeparators container overflow-hidden py-8 lg:py-12"
      :class="{
        alignedLeft: alignment === 'left',
        alignedRight: alignment === 'right',
      }"
      v-editable="blok"
    ></div>
  </div>
</template>

<script setup lang="ts">
import type { SeparatorBlockProps } from '~/types/Separator';

const { blok } = defineProps<{
  blok: SeparatorBlockProps;
}>();
const { background, alignment } = blok;
</script>

<style scoped>
/* Note: hasSeparators styled in tailwind.css */
.hasSeparators.alignedLeft:after {
  display: none;
}
.hasSeparators.alignedRight:before {
  display: none;
}
</style>
