<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import type { _AsyncData } from '#app/composables/asyncData';
import type { NewsletterContentType, TranslationsType, SocialNetwork } from '~/types/common';

const route = useRoute();
const runtimeConfig = useRuntimeConfig();

const { defaultLanguage, supportedLanguages } = runtimeConfig.public;

let locale = defaultLanguage;

if (route.query.path) {
  locale = (route.query.path as string).split('/')?.[0];
} else if (route.params.slug?.[0] && supportedLanguages.includes(route.params.slug?.[0])) {
  locale = route.params.slug?.[0];
}

const language = useState('language', () => locale);
const headerContent = useState<any>('headerContent');
const footerContent = useState<any>('footerContent');
const newsletterContent = useState<NewsletterContentType>('newsletterContent');
const translations = useState<TranslationsType>('translations', () => {
  return {
    no: {},
    en: {},
  };
});
const socialNetworks = useState<any[]>('socialNetworks', () => []);

useHead({
  link: [
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
    { rel: 'manifest', href: '/site.webmanifest' },
    { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#2cd5c4' },
  ],
  meta: [
    { name: 'msapplication-TileColor', content: '#000000' },
    { name: 'theme-color', content: '#ffffff' },
  ],
  // note: when loading scripts, refer to the following page and apply as needed
  // https://flaviocopes.com/javascript-async-defer/#async-and-defer
});

await callOnce(async () => {
  let response: any = {};
  try {
    response = await $fetch(`/api/site-config`);
    if (!response) {
      throw new Error('Failed to load remote site configuration from api');
    }
  } catch (error) {
    console.error('Failed to load remote site configuration', error);
    throw new Error('Failed to load remote site configuration');
  }

  const {
    headerData,
    footerData,
    newsletterData,
    translationData,
    socialNetworksData,
    priceGroupsData,
  } = response || {};

  headerContent.value = headerData;
  footerContent.value = footerData;
  newsletterContent.value = newsletterData;

  translationData?.forEach((translation: any) => {
    translations.value.no[translation.name] = translation.value;
    translations.value.en[translation.name] = translation.dimension_value;
  });

  // Note: price groups are translated separate from regular translations
  priceGroupsData?.forEach((translation: any) => {
    translations.value.no[translation.name] = translation.value;
    translations.value.en[translation.name] = translation.dimension_value;
  });

  const selectedSocialMedia = footerContent.value[language.value].social_media || [];
  socialNetworks.value = socialNetworksData.filter((network: SocialNetwork) =>
    selectedSocialMedia.includes(network.value),
  );
});
</script>

<style>
@import '@unocss/reset/tailwind-compat.css';
@import '~/assets/css/fonts.css';
@import '~/assets/css/app.css';
</style>
