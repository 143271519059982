// Note: we have two newsletter implementations (one as separate component and another in the footer)
// hence this file, to extract common functionality between them

import { customerIoAPI } from '~/composables/customerIoApi';

export type NewsletterFormData = {
  email: string;
};

export const useNewsletter = () => {
  const subscribe = async (data: NewsletterFormData) => {
    const language = useState('language');
    await customerIoAPI.sendIdentifyRequest(data.email, {
      language_code: String(language.value),
      newsletterSubscription: true,
    })
  };

  return {
    subscribe,
  }
};

