<template>
  <form class="" @submit.prevent="submitForm">
    <template v-if="formState === 'form'">
      <slot v-if="description" name="description" :description="description" />

      <div class="grow">
        <div class="w-full flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
          <input
            v-model="formData.email"
            @change="v$.email.$touch"
            type="text"
            :placeholder="placeholder"
            :class="{ 'border-tertiary': v$.email.$error, [inputClassNames]: true }"
          />

          <slot name="submit" />
        </div>

        <div class="mt-2 ml-6 sm:ml-8 text-sm text-tertiary" v-if="v$.email.$error">
          {{ v$.email.$errors?.[0].$message }}
        </div>

        <template v-if="termsText">
          <MxChoice
            :class="termsClassNames"
            type="checkbox"
            :id="termsId"
            :label="termsText"
            v-model="formData.terms"
            @change="v$.terms.$touch"
          />

          <p class="mt-1 ml-6 sm:ml-8 text-sm text-tertiary" v-if="v$.terms.$error">
            {{ v$.terms.$errors?.[0].$message }}
          </p>
        </template>
      </div>
    </template>

    <div v-else-if="formState === 'success'" class="text-2xl space-y-4">
      <Vue3RuntimeTemplate :template="successMessage" />
    </div>
  </form>
</template>

<script setup lang="ts">
import Vue3RuntimeTemplate from 'vue3-runtime-template';
import { ref, computed } from 'vue';
import { required, email, helpers, sameAs } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useTranslation } from '~/composables/useTranslation';

defineProps<{
  description?: string;
  placeholder?: string;
  termsText?: string;
  termsClassNames?: string;
  termsId: string; // note: to differentiate id's in different instances of component
  inputClassNames: string;
  buttonLabel?: string;
  successMessage?: string;
}>();

const emit = defineEmits(['submit']);

const formState = ref<'form' | 'success'>('form');

const formData = reactive({
  email: '',
  terms: false,
});

const translate = useTranslation();
const rules = computed(() => {
  return {
    email: {
      required: helpers.withMessage(translate('label.emailIsRequired'), required),
      email: helpers.withMessage(translate('label.invalidEmailFormat'), email),
    },
    terms: {
      sameAs: helpers.withMessage(translate('label.youMustAgreeToTerms'), sameAs(true)),
    },
  };
});

const v$ = useVuelidate(rules, formData);

const submitForm = () => {
  v$.value.$validate();
  if (!v$.value.$error) {
    emit('submit', formData);
    formState.value = 'success';
  }
};
</script>
