<template>
  <div
    v-editable="blok"
    :id="data.block_id"
    :class="{
      'bg-dark': blok.background === 'dark',
      'bg-light-white': blok.background === 'light',
    }"
  >
    <div class="container py-9 md:py-20">
      <h2
        v-if="data.heading"
        class="mb-[0.5em] text-3xl md:text-6xl font-secondary"
        :class="{
          'text-white': blok.background === 'dark',
          'text-dark': blok.background === 'light',
        }"
      >
        {{ data.heading }}
      </h2>

      <Shade position="topLeft" size="large">
        <div class="py-9 px-6 lg:p-14 space-y-9 md:space-y-12 bg-light">
          <NewsletterComponent
            class="flex max-w-[880px] mx-auto flex-wrap flex-col sm:flex-row gap-x-6"
            :description="data.description"
            :termsText="data.terms_label"
            terms-class-names="mt-3 ml-6 sm:mt-6 sm:ml-8 text-xl"
            terms-id="newsletter-terms"
            input-class-names="flex-grow px-6 py-5 sm:px-8 text-xl leading-none rounded-full"
            :placeholder="data.field_placeholder"
            :buttonLabel="data.button_label"
            :successMessage="data.success_message"
            @submit="handleSubmit"
          >
            <template #submit>
              <MxButton class="" theme="primary" type="submit" size="large">
                {{ data.button_label }}
                <Icon class="text-xl" name="ArrowRight" width="1.5" />
              </MxButton>
            </template>
            <template #description="{ description }">
              <p v-if="description" class="basis-full mb-6 ml-6 sm:ml-8 text-xl">
                {{ description }}
              </p>
            </template>
          </NewsletterComponent>
        </div>
      </Shade>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NewsletterContentType } from '~/types/common';
import { useNewsletter, type NewsletterFormData } from '~/composables/useNewsletter';

const language = useState('language');
const newsletterContentMultilang = useState<{ [x: string]: NewsletterContentType }>(
  'newsletterContent',
);
const defaultValues = computed(() => newsletterContentMultilang.value[String(language.value)]);

const { blok } = defineProps<{ blok: any }>();

const data = computed(() => {
  return {
    heading: blok.heading || defaultValues.value.heading,
    description: blok.description || defaultValues.value.description,
    terms_label:
      renderRichText(blok.terms_label) || renderRichText(defaultValues.value.terms_label),
    button_label: blok.button_label || defaultValues.value.button_label,
    success_message:
      renderRichText(blok.success_message) || renderRichText(defaultValues.value.success_message),
    field_placeholder: blok.field_placeholder || defaultValues.value.field_placeholder,
    block_id: blok.id || defaultValues.value.block_id,
  };
});

const newsletter = useNewsletter();
const handleSubmit = async (data: NewsletterFormData) => newsletter.subscribe(data);
</script>
