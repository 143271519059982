<template>
  <HeroGenerator :blok="blok" :id="blok?.id">
    <template #top></template>

    <template #bottom> </template>
  </HeroGenerator>
  <div class="bg-grey-dark">
    <MxBreadcrumbs v-if="!isHomepage" class="container !py-2" align="left" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { HeroBlockProps } from '~/types/HeroBlock';
import { useRuntimeConfig, useState } from 'nuxt/app';

const { blok } = defineProps<{ blok: HeroBlockProps }>();

const runtimeConfig = useRuntimeConfig();

const { supportedLanguages } = runtimeConfig.public;
const story = useState<any>('story');

const isHomepage = computed(() => {
  const fullSlug = story.value?.full_slug;
  const languages = supportedLanguages as string[];

  // Regular slug check
  if (languages.includes(fullSlug.replace('/', ''))) {
    return true;
  }

  // Duplicate lang prefix check (ie en/en/)
  for (const lang of languages) {
    if (fullSlug === `${lang}/${lang}/`) {
      return true;
    }
  }

  return false;
});
</script>
