<template>
  <ul class="flex lg:mt-0 gap-x-2 ml-auto">
    <li
      v-for="amenity in amenities"
      class="p-2 lg:p-2.5 flex items-center relative bg-primary-accent text-black text-xl lg:text-2xl leading-none rounded-full transition-colors hover:bg-black hover:text-primary-accent"
      :key="amenity.uuid"
      :aria-label="amenity.content?.name"
      :title="amenity.content?.name"
    >
      <Icon :name="amenityIconMap[amenity.slug]" />
    </li>
  </ul>
</template>

<script setup lang="ts">
import type { ISbStoryData } from 'storyblok-js-client';
import type { CategoryProps } from '~/types/common';

defineProps<{
  amenities: ISbStoryData<CategoryProps>[];
}>();

const amenityIconMap = {
  bar: 'Drink',
  lytt: 'HearingAid',
  rullestol: 'Wheelchair',
};
</script>
