<template>
  <div
    class="pt-16 pb-8"
    :class="{
      'bg-dark text-white': background === 'dark',
      'bg-light-white': background === 'light',
    }"
    v-editable="blok"
  >
    <div class="container grid grid-cols-8 lg:grid-cols-12 gap-x-3 lg:gap-x-6">
      <div
        class="col-span-8 space-y-3"
        :class="{
          'lg:col-span-10 lg:col-start-2': container === 'wide',
          'lg:col-start-3': container === 'narrow',
        }"
      >
        <DetailList v-if="author || date" :details="details" :background="background" />

        <p v-if="lead" class="text-2xl lg:text-3xl whitespace-pre-wrap">{{ lead }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { LeadBlockProps } from '~/types/LeadBlock';
import { formatDate } from '~/utils/formatDate';

const { blok } = defineProps<{ blok: LeadBlockProps }>();
const { lead, date, author, background, container } = blok;

const translate = useTranslation();

const details = [
  {
    label: translate('label.by'),
    value: author,
  },
  {
    label: '',
    value: date ? formatDate(date, { year: 'numeric', month: 'long', day: '2-digit' }) : undefined,
  },
];
</script>
