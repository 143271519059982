<template>
  <div class="overflow-hidden">
    <div
      v-if="blok.transition_type == 'fixedBackground'"
      class=""
      v-for="(section, index) in blok.sections"
      :key="section._uid"
    >
      <component
        :transitionDuration="blok.transition_duration"
        :is="activeTransitionComponent"
        :section="section"
        :index="section._uid"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import fixedBackground from '~/components/fixedBackground.vue';
import type { ScrollyTellingBlockProps } from '~/types/Scrollytelling';

const components = {
  fixedBackground,
};

const activeTransitionComponent = computed(() => {
  return components[props.blok.transition_type];
});

const props = defineProps<{ blok: ScrollyTellingBlockProps }>();
</script>
