export const isUrlImage = (url: string) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp'];

  const pattern = new RegExp('\\.(' + imageExtensions.join('|') + ')$', 'i');
  return pattern.test(url);
};

export const isUrlVideo = (url: string) => {
  const videoExtensions = ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv', 'webm'];

  const pattern = new RegExp('\\.(' + videoExtensions.join('|') + ')$', 'i');
  return pattern.test(url);
};

export const isUrlMedia = (url: string) => isUrlImage(url) || isUrlVideo(url);
