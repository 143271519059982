import { $fetch } from 'ofetch';

// Note: /api/tixly proxy is configured in server/api/[...].ts (catchAll route)

export type TixlyCustomerInfo = {
  CustomerId: number;
  Email: string;
  Mobile: string;
  Name: string;
  FirstName: string;
  LastName: string;
};

const getCustomerInfo = async (email: string): Promise<TixlyCustomerInfo | undefined> => {
  if (!email) {
    throw new Error('Missing tixly user email when fetching customerId from tixly');
  }

  // Get customers
  // https://posapi.tix-support.com/#9e3442cb-5c3b-4811-8eb3-c2769573fb70
  const response = await $fetch(`/api/tixly/customer/search`, {
    method: 'POST',
    body: {
      email,
    },
  });
  if (!response?.Data?.length) {
    console.error(`Tixly customerId not found for email ${email}`);
    return;
  }
  // TODO what if there are two customer objects for the same email?
  return response.Data[0];
};

export type TixlyEventOrder = {
  orderId: number;
  eventId: number;
  sold: boolean;
  links: {
    en: string;
    no: string;
  };
};

const getMyOrders = async (customerId: number): Promise<TixlyEventOrder[]> => {
  const response = await $fetch(`/api/tixly/customer/orders/${customerId}`, {
    method: 'GET',
  });

  const orders: TixlyEventOrder[] = [];
  const soldOrders = response.Data.filter((item: any) => item.Sold);

  for (const result of soldOrders) {
    for (const event of result.Events) {
      const linkEn = result.Links.find((link: any) => link.Language.TwoLetterISOCulture === 'en');
      const linkNo = result.Links.find((link: any) => link.Language.TwoLetterISOCulture === 'nb');

      orders.push({
        orderId: result.OrderId,
        sold: result.Sold,
        eventId: event.EventId,
        links: {
          en: linkEn?.Link,
          no: linkNo?.Link,
        },
      });
    }
  }
  return orders;
};

const clear = () => {};

export const tixlyApi = {
  getCustomerInfo,
  getMyOrders,
  clear,
};
