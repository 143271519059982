import { computed } from 'vue';
import { customerIoAPI } from '~/composables/customerIoApi';
import { tixlyApi } from '~/composables/tixlyApi';
import { useState } from 'nuxt/app';

export type TickslyUserData = {
  id: number;
  name: string;
  email: string;
  hash: string;
  tags: unknown[];
};
export type TickslyEmptyUserData = {
  id: null | false;
  email: null;
};
export type TickslyPostMessageData = {
  user: TickslyUserData;
};

// TODO move iframeEmbedDomain to .env when tested
const iframeEmbedDomain = 'https://billett.fib.no';

// note: this default object allows for simpler access to the property in components
const emptyAuth: TickslyEmptyUserData = {
  id: null,
  email: null,
};
// Note: id=false should signal we loaded the auth data and user is not logedin
const noAuth: TickslyEmptyUserData = {
  id: false,
  email: null,
};

export const useAuthUser = () =>
  useState<TickslyUserData | TickslyEmptyUserData>('userData', () => emptyAuth);

export function useAuth() {
  const authUser = useAuthUser();
  const runtimeConfig = useRuntimeConfig();
  // Note: destructuring route will break it's reactivity
  const route = useRoute();

  // https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
  const clearSession = () => {
    console.log('Clearing user session');
    authUser.value = noAuth;
    window.sessionStorage.removeItem('email');
    customerIoAPI.clear();
    tixlyApi.clear();
  };

  const storeSession = (user: TickslyUserData) => {
    if (!user?.id) {
      //console.error('Received an invalid TickslyUserData response', user);
      return;
    }
    //console.log('Storing user session from tixly', user);
    authUser.value = user;
    window.sessionStorage.setItem('email', user.email);

    // Trigger loading favourites now that we know user's email
    customerIoAPI.loadFavourites();
  };

  const user = computed<TickslyUserData | TickslyEmptyUserData>(() => authUser.value);

  const returnUrlString = computed(() => {
    const siteUrl = runtimeConfig.public.siteUrl;
    // Note: tixly will throw an error if we try to redirect to localhost
    if (siteUrl.includes('localhost')) {
      return '';
    }
    const encodedReturnUrl = encodeURIComponent(`${siteUrl}${route.path}`);
    return `/?returnUrl=${encodedReturnUrl}`;
  });

  const language = useState<string>('language');
  const tixlyLanguage = language.value === 'no' ? 'nb' : 'en';

  const iframeEmbedUrl = `${iframeEmbedDomain}/${tixlyLanguage}/itix`;
  const logInUrl = computed(
    () => `${iframeEmbedDomain}/${tixlyLanguage}/login${returnUrlString.value}`,
  );
  const logOutUrl = computed(
    () => `${iframeEmbedDomain}/${tixlyLanguage}/logout${returnUrlString.value}`,
  );
  const profileUrl = `${iframeEmbedDomain}/${tixlyLanguage}/profile`;

  return {
    iframeEmbedDomain,
    iframeEmbedUrl,
    // Note: login & logout links need to be used with .value in templates :(
    logInUrl,
    logOutUrl,
    profileUrl,
    clearSession,
    storeSession,
    user,
  };
}
