<template>
  <div
    class="py-8 lg:py-12"
    :class="{
      'bg-dark text-white': background === 'dark',
      'bg-light-white': background === 'light',
    }"
    v-editable="blok"
    :id="id"
  >
    <div class="container relative">
      <p v-if="titleBar" class="p-2 bg-black/50 text-white text-xs leading-none">
        {{ titleBar }}
      </p>

      <component :is="linkComponent" v-bind="linkProps">
        <MxAssetRenderer
          class="md:hidden"
          :asset="mobileAsset"
          :show-caption="showCaptions"
          fit="fill"
          :enable-download="enableDownload"
        />
        <MxAssetRenderer
          class="hidden md:block xl:hidden"
          :asset="tabletAsset"
          :show-caption="showCaptions"
          fit="fill"
          :enable-download="enableDownload"
        />
        <MxAssetRenderer
          class="hidden xl:block"
          :asset="desktopAsset"
          :show-caption="showCaptions"
          fit="fill"
          :enable-download="enableDownload"
        />
      </component>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PublicFundingBlockProps } from '~/types/PublicFundingBlock';
import { NuxtLink } from '#components';
import { getStoryblokLink } from '~/composables/storyblokLink';

const { blok } = defineProps<{ blok: PublicFundingBlockProps }>();
const {
  desktopAsset,
  tabletAsset,
  mobileAsset,
  titleBar,
  background,
  id,
  showCaptions,
  enableDownload,
  link,
} = blok;

const linkComponent = computed(() => (link ? NuxtLink : 'div'));
const linkProps = computed(() => (link ? { to: getStoryblokLink(link), target: '_blank' } : {}));
</script>
