<template>
  <div class="mb-10 pt-6 pb-20 bg-dark">
    <div class="container">
      <div class="flex flex-wrap items-center">
        <form class="w-full mb-16 md:grid md:grid-cols-2 gap-8" @submit.prevent="onSubmit">
          <div class="flex flex-grow space-x-4 py-4">
            <input
              class="flex flex-grow items-center pl-4 py-2 pr-22 md:pr-4 -mr-18 md:mr-0 w-64 bg-white border border-greyDark text-xl rounded-full"
              type="text"
              :placeholder="translate('label.searchVenues')"
              name="q"
              v-model="searchTerm"
            />

            <MxButton type="submit">
              <Icon class="text-2xl" name="Search" />
            </MxButton>
          </div>
          <div class="flex flex-grow space-x-4 py-4">
            <MxButton class="w-full h-full justify-between" @click="filtersOpen = !filtersOpen">
              {{ translate('label.filterByCategory') }}
              <Icon
                class="text-2xl transform transition-transform"
                :class="{
                  'rotate-180': filtersOpen,
                }"
                name="ChevronDown"
              />
            </MxButton>
          </div>
          <div v-if="filtersOpen" class="flex space-x-8 col-span-2 text-white text-xl">
            <MxChoice
              v-for="(option, idx) in facilitiesOptions"
              :key="idx"
              type="checkbox"
              :id="`facility-${idx}`"
              :label="option.label"
              :checked="selectedFacilities.includes(option.value)"
              :value="option.value"
              @change="() => toggleFacilityFilter(option.value)"
            />
          </div>
        </form>
        <div class="container pl-0 pb-[1rem]">
          <div v-if="isLoadingSearchResults" class="space-y-2">
            <div class="h-7 mb-4 w-[70%] bg-black rounded"></div>
            <div class="h-12 w-[90%] bg-black rounded"></div>
          </div>
          <h2
            v-else-if="resultsMessage"
            class="font-primary text-white text-2xl md:text-3xl lg:text-5xl italic"
          >
            {{ resultsMessage }}
          </h2>
        </div>
        <ContentCollectionGrid
          class="text-white"
          :items="venuesFormatted"
          item-style="simple"
          :collection-heading="false"
        />
        <div class="flex justify-center space-x-8 mt-10">
          <MxButton v-if="showLoadMore" size="large" class="text-xl" @click="loadMore">
            {{ translate('action.loadMore') }}
            <Icon class="text-xl" name="ArrowRight" width="1.5" />
          </MxButton>

          <MxButton @click="scrollToTop">
            <Icon class="text-4xl" name="ArrowUp" />
          </MxButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core';
import { useStoryblokApi } from '@storyblok/vue';
import { STORY_RELATIONS } from '~/composables/relations';
import type { CollectionItemType } from '~/types/ContentCollection';
import type { ListingResults } from '~/types/common';
import type { VenueListingProps } from '~/types/Venue';
import type { ISbStoryData } from 'storyblok-js-client';

const runtimeConfig = useRuntimeConfig();
const { publishState, storyBlokApiKey } = runtimeConfig.public;
const language = useState('language');
const translate = useTranslation();
const router = useRouter();
const route = useRoute();

const perPage = 30;
const currentPage = ref(1);

const searchTerm = ref(route.query?.search as string);
const filtersOpen = ref<boolean>(false);

const selectedFacilities = ref<string[]>(
  route.query?.facilities ? String(route.query?.facilities).split(',') : [],
);

declare type SearchVenuesOptions = {
  page?: number;
};

const storyblokApi = useStoryblokApi();

const { blok } = defineProps<{ blok: VenueListingProps }>();
const { categories } = blok;

const fetchVenues = async (options: SearchVenuesOptions = {}) => {
  // Note: restricting story path to language is needed to load correct version
  const q = {
    version: publishState as 'draft' | 'published',
    token: storyBlokApiKey,
    language: language.value as string,
    resolve_relations: STORY_RELATIONS,
    content_type: 'Venue',
    starts_with: language.value as string,
    cv: new Date().getTime(),
    sort_by: 'sort_by_date:asc',
    per_page: perPage,
    page: options.page || 1,
  };

  if (searchTerm.value?.length) {
    Object.assign(q, { search_term: searchTerm.value });
  }

  const filter_query = {};
  if (categories?.length) {
    Object.assign(filter_query, {
      categories: {
        any_in_array: categories.join(','),
      },
    });
  }

  if (selectedFacilities.value?.length) {
    Object.assign(filter_query, {
      facilities: {
        all_in_array: selectedFacilities.value.join(','),
      },
    });
  }

  if (Object.keys(filter_query).length) {
    Object.assign(q, { filter_query });
  }

  try {
    const response = await storyblokApi.get(`cdn/stories/`, q);
    currentPage.value = options.page || 1;

    return {
      // note: incomplete type returned by storyblok
      total: parseInt((response?.headers as any)?.total || 0),
      stories: response?.data?.stories || [],
    };
  } catch (error) {
    console.error(error);
    return {
      total: 0,
      stories: [],
    };
  }
};

const fetchFacilities = async () => {
  try {
    const response = await storyblokApi.get(`cdn/stories`, {
      version: publishState as 'draft' | 'published',
      token: storyBlokApiKey,
      cv: new Date().getTime(),
      starts_with: 'data/kategori/fasilitet',
      language: language.value as string,
      resolve_relations: STORY_RELATIONS,
      content_type: 'Category',
    });
    return response.data?.stories;
  } catch (error) {
    console.error(error);
  }
};

const facilitiesOptions = computed(() => {
  return (facilities.value || []).map((story) => ({
    value: story.uuid,
    label: story.content?.name || story.name,
  }));
});

const results = ref<ListingResults>(
  (
    await useAsyncData(`venues_${language.value}`, async () => {
      return await fetchVenues();
    })
  ).data as unknown as ListingResults,
);

const facilities = ref<Array<ISbStoryData>>(
  (
    await useAsyncData(`venues_facilities_${language.value}`, async () => {
      return await fetchFacilities();
    })
  ).data as unknown as Array<ISbStoryData>,
);

const toggleFacilityFilter = (uuid: string) => {
  const existingIndex = selectedFacilities.value.indexOf(uuid);
  if (existingIndex !== -1) {
    selectedFacilities.value.splice(existingIndex, 1);
  } else {
    selectedFacilities.value.push(uuid);
  }
  onSubmit();
};

const venuesFormatted = computed<CollectionItemType[]>(() => {
  return (results.value?.stories || []).map((story) => {
    return {
      _uid: story.uuid,
      storyToShow: story,
      customHeading: story.content.name || story.name,
      // customText ?
      // customMedia ?
      // ctaText ?
      // ctaType ?
      // highlighted ?
    };
  });
});

const totalPages = computed(() => Math.ceil((results.value?.total || 0) / perPage));

const showLoadMore = computed(() => totalPages.value && currentPage.value !== totalPages.value);

const loadMore = async () => {
  if (currentPage.value === totalPages.value) return;
  const response = await fetchVenues({
    page: currentPage.value + 1,
  });

  results.value.total = response.total;
  results.value.stories = [...results.value.stories, ...response.stories];
};

const getSearchTerm = () => {
  return searchTerm.value;
};

const resultsMessage = ref('');
const isLoadingSearchResults = ref(false);

const onSubmit = async () => {
  isLoadingSearchResults.value = true;
  router.push({
    query: {
      ...(route.query.value as any),
      search: searchTerm.value ? encodeURIComponent(searchTerm.value) : undefined,
      facilities: selectedFacilities.value.length ? selectedFacilities.value.join(',') : undefined,
    },
  });
  results.value = await fetchVenues();
  isLoadingSearchResults.value = false;
  resultsMessage.value = <string>getSearchResultsMessage();
};

const { y } = useWindowScroll();

const scrollToTop = () => {
  y.value = 0;
};

const getSearchResultsMessage = () => {
  if (isLoadingSearchResults.value) {
    return '';
  } else if (searchTerm.value) {
    const NUMBER_OF_RESULTS_PLACEHOLDER: string = '<number-of-results>';
    const SEARCH_TERM_PLACEHOLDER: string = '<search-term>';
    return venuesFormatted.value.length < 1
      ? blok?.resultsNotFoundMessage
          .replaceAll(NUMBER_OF_RESULTS_PLACEHOLDER, `${venuesFormatted.value.length}`)
          .replaceAll(SEARCH_TERM_PLACEHOLDER, `"${getSearchTerm()}"`)
      : blok?.resultsFoundMessage
          .replaceAll(NUMBER_OF_RESULTS_PLACEHOLDER, `${venuesFormatted.value.length}`)
          .replaceAll(SEARCH_TERM_PLACEHOLDER, `"${getSearchTerm()}"`);
  }
};
</script>
