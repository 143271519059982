<template>
  <div class="bg-CTA/PrimaryDefault w-full text-Theme/Dark">
    <div
      class="container mx-auto grid grid-cols-12 gap-3 md:gap-6 py-2.5 text-center md:text-left"
    >
      <div class="col-span-12">
        <slot name="message" />
      </div>
    </div>
  </div>
</template>
