<template>
  <NotificationBar v-editable="blok" :id="blok?.id">
    <template v-slot:message>
      <NuxtLink
        :to="getStoryblokLink(props.blok?.cta_link)"
        :target="props?.blok?.cta_link?.target || `_self`"
        ><div v-html="message"></div>
      </NuxtLink>
    </template>
  </NotificationBar>
</template>

<script setup>
import { getStoryblokLink } from '~/composables/storyblokLink';
const props = defineProps({ blok: Object });
const message = computed(() => renderRichText(props.blok.message));
</script>
