<template>
  <div
    ref="bgImage"
    class="animation_container relative z-0"
    :style="{ backgroundColor }"
    :id="`main_container_${index}`"
  >
    <MediaDisplay
      :video="section.background_asset.filename"
      :image="section.background_asset"
      :aspect-ratio="'1.3'"
      :class="`bg-image absolute h-screen top-0 w-full !bg-cover !bg-no-repeat transition-opacity z-0 ${
        transitionSpeed[transitionDuration as TransitionSpeed]
      } opacity-0`"
      :id="`bg_image_${section._uid}_${index}`"
    />
    <div
      :class="[
        'z-20 h-screen  flex container w-full m-auto items-center relative',
        alignBox[content.box_alignment as alignment],
      ]"
      :style="{
        zIndex: `1${index}00`,
      }"
      v-for="(content, contentIndex) in section.content"
      :key="`content_${section._uid}_${contentIndex}`"
    >
      <span
        :class="[
          'px-8 py-8 font-primary md:w-[60%] prose children:m-0 flex flex-col gap-2 [&_p]:text-xl',
          alignText[content.text_alignment as alignment],
          boxBackground[content.background as boxBackground],
        ]"
      >
        <Vue3RuntimeTemplate :template="getRenderedText(content.content)" />
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Vue3RuntimeTemplate from 'vue3-runtime-template';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isUrlMedia } from '~/utils/media';

const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
  index: {
    type: String,
    required: true,
  },
  transitionDuration: {
    type: String,
    required: false,
    default: 'medium',
  },
});

type TransitionSpeed = 'slow' | 'medium' | 'fast';
type alignment = 'left' | 'center' | 'right';
type boxBackground = 'solid' | 'transparent' | 'none';

const backgroundColor = computed(() => {
  return isUrlMedia(props.section.background_asset.filename)
    ? 'transparent'
    : `${props.section.background_color.color}`;
});

const bgImage = ref();
const alignBox: Record<alignment, string> = {
  left: 'justify-start',
  center: 'justify-center',
  right: 'justify-end',
};
const alignText: Record<alignment, string> = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
};
const boxBackground: Record<boxBackground, string> = {
  solid: 'font-light bg-primary',
  transparent: 'font-light bg-primary bg-opacity-80',
  none: 'font-normal textShadow',
};
const transitionSpeed: Record<TransitionSpeed, string> = {
  slow: 'duration-[2000ms]',
  medium: 'duration-1000',
  fast: 'duration-500',
};
onMounted(() => {
  gsap.registerPlugin(ScrollTrigger);
  gsap.from(bgImage.value, {
    scrollTrigger: {
      trigger: `#main_container_${props.index}`,
      start: 'top center',
      end: 'bottom center',
      toggleClass: {
        targets: `#main_container_${props.index} .bg-image`,
        className: 'opacity-100',
      },
      toggleActions: 'play complete play complete', // Restart the animation when entering and complete when leaving
    },
  });
});

onUnmounted(() => {
  gsap.killTweensOf(bgImage.value);
});

const getRenderedText = (text: any): any => {
  return renderRichText(text, {
    resolver(component, blok) {
      return `<component
        :blok='${JSON.stringify(blok)}'
        is="${component}"
        :is-embeded="true"
        :reduce-padding="true"
      />`;
    },
  });
};
</script>

<style>
.signal {
  color: theme('colors.primary.DEFAULT');
}
.textShadow {
  text-shadow: 2px 2px 30px rgba(0, 0, 0, 0.75);
}
</style>
