<template>
  <div
    class="py-8 lg:py-12"
    :class="{
      'bg-dark text-white': background === 'dark',
      'bg-light-white': background === 'light',
    }"
    v-editable="blok"
    :id="id"
  >
    <div class="container">
      <div v-html="embedCode" class="embedContainer aspect-video" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { EmbedBlockProps } from '~/types/EmbedBlock';

const { blok } = defineProps<{ blok: EmbedBlockProps }>();
const { embedCode, id, background } = blok;
</script>

<style scoped>
.embedContainer {
  :deep(iframe) {
    width: 100%;
    min-height: 100%;
  }
}
</style>
