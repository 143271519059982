<template>
  <div class="py-5">
    <ul
      :class="{
        'flex flex-wrap items-center gap-x-1.5': true,
        'justify-center': align === 'center',
        'justify-end': align === 'right',
        'justify-start': align === 'left',
      }"
    >
      <template v-for="(breadcrumb, breadcrumbIndex) in breadcrumbs" :key="breadcrumb.id">
        <li
          v-if="folderWithDifferentRoot(breadcrumbIndex)"
          class="flex items-center gap-x-1.5 font-primary font-semibold uppercase text-light"
        >
          <span v-if="isCurrentPage(breadcrumb.full_slug || breadcrumb.slug)">
            {{ breadcrumb.name }}
          </span>

          <component
            v-else
            :is="!!breadcrumb.rootPage ? NuxtLink : 'span'"
            class="opacity-35"
            :class="{
              'hover:underline': !!breadcrumb.rootPage,
              [`lt-lg:after:visible lt-lg:after:content-['...']`]:
                breadcrumbs.length > 4 && breadcrumbIndex === 0,
            }"
            :to="
              !!breadcrumb.rootPage
                ? getStoryblokLink({
                    linktype: 'story',
                    cached_url: breadcrumb.slug,
                  })
                : undefined
            "
          >
            <span :class="[breadcrumbs.length > 4 && breadcrumbIndex === 0 && `lt-lg:hidden`]">
              {{ breadcrumb.rootPage?.name || breadcrumb.name }}
            </span>
          </component>

          <Icon
            class="text-xs opacity-35"
            name="ChevronRight"
            v-if="breadcrumbIndex < breadcrumbs.length - 1"
          />
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components';
import type { BreadcrumbItem } from '~/types/Breadcrumbs';
import { useRoute, useRuntimeConfig, useState } from 'nuxt/app';
import { isCurrentPage } from '~/composables/isCurrentPage';
import { getStoryblokLink } from '~/composables/storyblokLink';

interface IProps {
  align: 'left' | 'center' | 'right';
}

const runtimeConfig = useRuntimeConfig();
const { meta } = useRoute();
const breadcrumbs = useState<BreadcrumbItem[]>('breadcrumbs');

const { align } = defineProps<IProps>();

const folderWithDifferentRoot = (itemIndex: number) => {
  if (itemIndex < breadcrumbs.value.length - 1) {
    return breadcrumbs.value[itemIndex].rootPage?.id !== breadcrumbs.value[itemIndex + 1].id;
  }

  return true;
};
</script>
