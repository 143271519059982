<template>
  <div class="p-4 md:p-9 bg-light" :class="alignClass">
    <slot name="topIcon" />

    <div class="space-y-5">
      <slot />
    </div>

    <slot name="bottomIcon" />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    align: 'center' | 'left' | 'right';
  }>(),
  {
    align: 'center',
  },
);

const alignClass = computed(() => {
  return {
    'text-center': props.align === 'center',
    'text-right': props.align === 'right',
    'text-left': props.align === 'left',
  };
});
</script>
