<template>
  <div
    class="richText-embedWrapper grid grid-cols-8 lg:grid-cols-12 gap-x-3 lg:gap-x-6"
    :class="[`richText-embedWrapper--${container}`]"
    v-editable="blok"
  >
    <Shade class="col-span-8 lg:col-span-6 lg:col-start-4">
      <Box align="center">
        <template #topIcon>
          <Icon class="text-primary text-7xl" name="Quote" />
        </template>

        <RuntimeTemplate :template="parsedQuote" class="font-normal font-secondary !text-3xl" />

        <span class="items-center text-primary font-normal text-2xl">- {{ author }}</span>
      </Box>
    </Shade>
  </div>
</template>
<style scoped>
.rich-text-block p:last-of-type {
  margin-bottom: 0;
}
</style>
<script setup lang="ts">
import Box from '~/components/Box/Box.vue';
import RuntimeTemplate from 'vue3-runtime-template';
import { computed } from 'vue';
import type { ISbRichtext } from 'storyblok-js-client';
import type { RichTextContainerType } from '~/types/RichTextBlock.js';

const { blok, container } = defineProps<{
  blok: {
    author: string;
    quote: ISbRichtext;
  };
  container: RichTextContainerType;
}>();
const { author, quote } = blok;

const parsedQuote = computed(() => renderRichText(quote));
</script>
