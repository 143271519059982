
import * as cloudinaryRuntime$Rav2Af6O5eG2xzOrRuTs9cR16ECQ9qG4r_45Yu04S_OfY from '/vercel/path0/website/node_modules/@nuxt/image/dist/runtime/providers/cloudinary'
import * as vercelRuntime$m3OUNvXx74MWAI2yZsKQKcUcFzgLkjIf1vFl_45_45vVRjw from '/vercel/path0/website/node_modules/@nuxt/image/dist/runtime/providers/vercel'
import * as representRuntime$R8kt9dDTy4F8sV_45w2xUoxyOhF55PmwfBIYO5f9xdlR4 from '/vercel/path0/website/providers/represent.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "vercel",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudinary']: { provider: cloudinaryRuntime$Rav2Af6O5eG2xzOrRuTs9cR16ECQ9qG4r_45Yu04S_OfY, defaults: {"baseURL":"https://res.cloudinary.com/fib/image/upload/"} },
  ['vercel']: { provider: vercelRuntime$m3OUNvXx74MWAI2yZsKQKcUcFzgLkjIf1vFl_45_45vVRjw, defaults: {} },
  ['represent']: { provider: representRuntime$R8kt9dDTy4F8sV_45w2xUoxyOhF55PmwfBIYO5f9xdlR4, defaults: {"baseURL":"https://res.cloudinary.com/fib/image/upload/"} }
}
        