<template>
  <section class="bg-light">
    <div class="container mx-auto py-6 flex gap-4 text-2xl">
      <p><span class="opacity-50">Skrevet av:</span> {{ author }}</p>
      <p><span class="opacity-50">Publisert:</span> {{ date }}</p>
    </div>
  </section>
</template>

<script setup lang="ts">
import { format, parseISO } from 'date-fns';

const { blok } = defineProps({ blok: Object });

const author = computed(() => blok?.author);
const date = computed(() => format(parseISO(blok?.date), 'd. MMMM yyyy'));
</script>
