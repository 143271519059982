export const isCurrentPage = (route: string = '') => {
  const { path, query } = useRoute();
  const runtimeConfig = useRuntimeConfig();
  const { defaultLanguage, supportedLanguages } = runtimeConfig.public;

  const targetPath = (query?.path as string) || path;
  const routeSegments = route.split('/');
  let singleLocaleRoute = route;

  if (routeSegments.length > 1 && (supportedLanguages as string[]).includes(routeSegments[1])) {
    singleLocaleRoute = routeSegments.slice(1).join('/');
  }

  const cleanPath = targetPath
    ?.replace(new RegExp(`\/?(${defaultLanguage})?(\/?)?`), '')
    .replace(/\/?$/, '');
  const cleanRoute = singleLocaleRoute
    .replace(new RegExp(`\/?(${defaultLanguage})?(\/?)?`), '')
    .replace(/\/?$/, '');

  return cleanRoute === decodeURIComponent(cleanPath);
};
