<template>
  <div
    class="py-8 lg:py-12"
    :class="{
      'bg-dark text-white': background === 'dark',
      'bg-light-white': background === 'light',
    }"
    v-editable="blok"
  >
    <div
      class="container"
      :class="{
        'grid grid-cols-8 lg:grid-cols-12 gap-x-3 lg:gap-x-6': container === 'grid',
      }"
    >
      <h2
        v-if="heading"
        class="text-3xl md:text-5xl font-secondary font-semibold"
        :class="{
          'col-span-8 lg:col-span-10 lg:col-start-2': container === 'grid',
        }"
      >
        {{ heading }}
      </h2>

      <div
        class="grid gap-x-2 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 md:gap-x-6 gap-y-10"
        :class="{
          'col-span-8 lg:col-span-10 lg:col-start-2': container === 'grid',
        }"
      >
        <Contact
          v-for="contact in contactList"
          v-bind="contact"
          :key="contact.content._uid"
          :heading-level="!!heading ? 'h3' : 'h2'"
          v-editable="contact"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ContactsBlockProps } from '~/types/ContactsBlock';

const { blok } = defineProps<{ blok: ContactsBlockProps }>();
const { heading, contactList, background, container } = blok;
</script>
