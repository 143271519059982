<template>
  <span
    :class="[
      'line inline-block relative leading-none',
      {
        'text-5xl lg:text-8xl md:px-8 px-2 py-3 lg:py-5': line?.size === 'large',
        'text-3xl lg:text-7xl md:px-5 px-2 py-2 lg:py-3': line?.size === 'medium',
        'text-lg lg:text-3xl md:px-4 px-2 py-2': line?.size === 'small',
      },
      {
        'bg-primary': line?.background_color === 'primary',
        'bg-secondary': line?.background_color === 'secondary',
        'bg-tertiary': line?.background_color === 'tertiary',
        'bg-primary-accent': line?.background_color === 'primaryAccent',
        'bg-secondary-accent': line?.background_color === 'secondaryAccent',
        'bg-tertiary-accent': line?.background_color === 'tertiaryAccent',
        'bg-primary-light': line?.background_color === 'primaryLight',
        'bg-secondary-light': line?.background_color === 'secondaryLight',
        'bg-tertiary-light': line?.background_color === 'tertiaryLight',
      },
      {
        'font-primary font-semibold': line?.font_style === 'primary',
        // Note: sectra version we use is black/italic but registered in fonts as normal
        // adding font-black would only make it (too much) thicker in safari
        'font-secondary': line?.font_style === 'secondary',
      },
    ]"
    ref="linesReference"
  >
    <a
      v-if="line.link && line?.link?.cached_url !== '' && line?.link?.cached_url !== '/en/'"
      :href="
        line.link.linktype === 'story'
          ? `${(siteUrl, line.link.cached_url.includes('/en/') ? line.link.cached_url.replace('/en/', '') : line.link.cached_url)}`
          : line.link.cached_url
      "
      class="flex flex-row gap-3 items-center"
      :class="[
        {
          'gap-5 lg:gap-8': line?.size === 'large',
          'gap-3 lg:gap-6': line?.size === 'medium',
          'gap-2 lg:gap-3': line?.size === 'small',
        },
      ]"
      >{{ line.text }}<Icon name="ArrowRight"
    /></a>
    <p v-else>{{ line.text }}</p>
  </span>
</template>

<script setup lang="ts">
import type { FancyHeadingLine } from '~/types/FancyHeading';

const runtimeConfig = useRuntimeConfig();
const { siteUrl } = runtimeConfig.public;

const props = defineProps<{
  line: FancyHeadingLine;
}>();

const linesReference = ref([]);

const desktopOffset = computed(() =>
  props.line.offset.value ? `${props.line.offset.value}%` : '',
);
const mobileOffset = computed(() =>
  props.line.offset.value ? `${props.line.offset.value / 2}%` : '',
);
</script>

<style scoped>
.line {
  left: v-bind(mobileOffset);
}
@screen lg {
  .line {
    left: v-bind(desktopOffset);
  }
}
</style>
