<template>
  <NuxtLink v-if="href" :to="href" :target="target" :class="classStack" :disabled="disabled">
    <template v-if="label">{{ label }}</template>
    <slot v-else />
  </NuxtLink>

  <button v-else :class="classStack" :type="type" :disabled="disabled">
    <template v-if="label">{{ label }}</template>
    <template v-if="icon">{{ icon }}</template>
    <slot v-else />
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type ButtonProps = {
  label?: string;
  icon?: string;
  href?: string;
  layout?: 'solid' | 'outline' | 'outline-on-dark';
  theme?: 'primary' | 'secondary' | 'tertiary' | 'inverted';
  size?: 'small' | 'medium' | 'large';
  target?: '_blank' | '_self';
  type?: 'button' | 'submit' | 'reset';
  background?: 'dark' | 'light';
  disabled?: boolean;
};

const props = withDefaults(defineProps<ButtonProps>(), {
  layout: 'solid',
  theme: 'primary',
  size: 'medium',
  type: 'button',
  background: 'light',
});

const classStack = computed(() => {
  return [
    'inline-flex items-center justify-center gap-x-4 text-2xl leading-none transition-colors duration-150 ease-in-out border rounded-[30px]',
    {
      [`bg-${props.theme} border-${props.theme} text-black`]: props.layout === 'solid',
      [`lg:hover:text-${props.theme} lg:hover:border-black lg:hover:bg-black focus:text-${props.theme} focus:border-black focus:bg-black`]:
        props.layout === 'solid' && !props.disabled,
      'bg-transparent': props.layout === 'outline',
      [`bg-transparent border-${props.theme} text-${props.theme}`]:
        props.layout === 'outline-on-dark',
      'text-dark border-dark': props.layout === 'outline' && props.background === 'light',
      'lg:hover:bg-dark lg:hover:text-primary focus:bg-dark focus:text-primary':
        props.layout === 'outline' && props.background === 'light' && !props.disabled,
      'text-primary border-primary': props.layout === 'outline' && props.background === 'dark',
      'lg:hover:bg-primary lg:hover:text-dark focus:bg-primary focus:text-dark':
        props.layout === 'outline' && props.background === 'dark' && !props.disabled,
      'px-10 py-4': props.size === 'large',
      'px-8 py-4': props.size === 'medium',
      'p-3': props.size === 'small',
      'bg-transparent lg:hover:bg-black text-black lg:hover:text-primary':
        props.theme === 'secondary',
      'bg-black lg:hover:bg-primary text-primary lg:hover:text-black border-none':
        props.theme === 'inverted',
    },
  ];
});
</script>
