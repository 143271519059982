<template>
  <div class="relative">
    <input
      class="choice-element absolute opacity-0 pointer-events-none"
      :type="type"
      :id="id"
      :name="name"
      :required="required"
      :checked="checked"
      :value="value"
      v-model="isChecked"
    />

    <label class="choice-label" :class="[`choice-label--${type}`]" :for="id">
      <Icon
        v-if="checked && type === 'checkbox'"
        class="choice-icon absolute left-0 top-0 z-10 text-dark"
        name="Checkmark"
      />

      <div v-if="label" v-html="label" />

      <slot v-else />
    </label>
  </div>
</template>

<script setup lang="ts">
const { id, type, name, value, label, checked, required } = defineProps<{
  id: string;
  type: 'radio' | 'checkbox';
  label?: string;
  value?: string;
  name?: string;
  checked?: boolean;
  required?: boolean;
}>();

const isChecked = defineModel();
// isChecked.value = checked;
</script>

<style scoped>
.choice-label {
  display: inline-flex;
  column-gap: 12px;

  .choice-icon,
  &:before {
    width: 0.8em;
    height: 0.8em;
    margin-top: 0.4em;
  }
  &:before {
    flex-shrink: 0;

    background-color: theme('colors.white');
    box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.25);
    content: '';
  }

  :deep(a) {
    text-decoration: underline;
  }

  .choice-element:checked + & {
    &:before {
      background-color: theme('colors.primary.DEFAULT');
    }
  }
  .choice-element:focus + & {
    &:before {
      outline: auto;
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
}
.choice-label--radio {
  &:before {
    border-radius: 50%;
  }
}
</style>
