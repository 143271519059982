<template>
  <div
    class="py-8 lg:py-12"
    :class="{
      'bg-dark text-white': background === 'dark',
      'bg-light-white': background === 'light',
    }"
    v-editable="blok"
    :id="id"
  >
    <div class="container relative space-y-10">
      <div v-if="assets?.length || posterAssets?.length">
        <template v-if="layout === 'grid'">
          <h2 v-if="titleBar" class="mb-[0.5em] text-3xl md:text-6xl font-secondary">
            {{ titleBar }}
          </h2>

          <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div
              v-for="asset in assets"
              :key="asset.public_id"
              class="bg-primary-accent flex flex-col"
            >
              <div class="relative w-full aspect-[4/3]">
                <MxAssetRenderer
                  class="media bg-light-accent w-full h-full object-cover object-center"
                  image-class="w-full h-full object-cover object-center"
                  :asset="asset"
                  :modifiers="{ gravity: 'center' }"
                  fit="fill"
                  :aspect-ratio="'1.3'"
                />
              </div>
              <div
                v-if="showCaptions || enableDownload"
                class="p-4 flex flex-col grow space-y-6 text-lg"
              >
                <p v-if="showCaptions && asset?.title" class="grow">{{ asset.title }}</p>
                <p v-if="enableDownload" class="mt-auto">
                  <a
                    :href="asset?.filename"
                    target="_blank"
                    download
                    class="flex items-center space-x-4 hover:underline"
                  >
                    <MxButton size="small" class="text-2xl">
                      <Icon class="text-xl" name="Download" width="1.5" />
                    </MxButton>
                    <span>
                      {{ translate('action.download') }}
                    </span>
                  </a>
                </p>
              </div>
            </div>
            <div
              v-for="asset in posterAssets"
              :key="asset.Asset.public_id"
              class="bg-primary-accent flex flex-col"
            >
              <div class="relative w-full aspect-[4/3]">
                <MxAssetRenderer
                  class="media bg-light-accent w-full h-full object-cover object-center"
                  image-class="w-full h-full object-cover object-center"
                  :asset="asset.PosterImage.filename ? asset.PosterImage : asset.Asset"
                  :modifiers="{ gravity: 'center' }"
                  fit="fill"
                  :aspect-ratio="'1.3'"
                />
              </div>
              <div
                v-if="showCaptions || enableDownload"
                class="p-4 flex flex-col grow space-y-6 text-lg"
              >
                <p v-if="showCaptions && asset?.Asset.title" class="grow">
                  {{ asset?.Asset.title }}
                </p>
                <p v-if="enableDownload" class="mt-auto">
                  <a
                    :href="asset?.Asset.filename"
                    target="_blank"
                    download
                    class="flex items-center space-x-4 hover:underline"
                  >
                    <MxButton size="small" class="text-2xl">
                      <Icon class="text-xl" name="Download" width="1.5" />
                    </MxButton>
                    <span>
                      {{ translate('action.download') }}
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <p v-if="titleBar" class="p-2 bg-black/50 text-white text-xs leading-none">
            {{ titleBar }}
          </p>

          <MxCarousel
            v-if="(assets ? assets?.length : 0) + (posterAssets ? posterAssets?.length : 0) > 1"
            :configuration="{
              type: 'loop',
              autoHeight: true,
              pagination: false,
            }"
            arrow-position="side"
            arrow-size="text-6xl lg:text-[135px]"
          >
            <MxCarouselSlide v-for="asset in assets" :key="asset.public_id">
              <MxAssetRenderer
                class="h-full"
                image-class="xl:max-h-[800px] xl:h-[800px] max-h-[500px] h-[500px] min-w-full w-auto object-cover"
                :asset="asset"
                :show-caption="showCaptions"
                :enable-download="enableDownload"
              />
            </MxCarouselSlide>
            <MxCarouselSlide v-for="asset in posterAssets" :key="asset?.Asset.public_id">
              <MxAssetRenderer
                class="h-full"
                image-class="xl:max-h-[800px] xl:h-[800px] max-h-[500px] h-[500px] min-w-full w-auto object-cover"
                :asset="asset.Asset.filename.includes('image') ? asset.PosterImage : asset.Asset"
                :poster="asset.PosterImage.public_id"
                :show-caption="showCaptions"
                :enable-download="enableDownload"
              />
            </MxCarouselSlide>
          </MxCarousel>

          <div v-else>
            <MxAssetRenderer
              v-if="assets?.length"
              image-class="xl:max-h-[800px] xl:h-[800px] max-h-[500px] h-[500px] min-w-full w-auto object-cover"
              :asset="assets[0]"
              :show-caption="showCaptions"
              :enable-download="enableDownload"
            />
            <MxAssetRenderer
              v-if="posterAssets?.length"
              image-class="xl:max-h-[800px] xl:h-[800px] max-h-[500px] h-[500px] min-w-full w-auto object-cover"
              :asset="
                posterAssets[0].Asset.filename.includes('image')
                  ? posterAssets[0].PosterImage
                  : posterAssets[0].Asset
              "
              :poster="posterAssets[0].PosterImage.public_id"
              :show-caption="showCaptions"
              :enable-download="enableDownload"
              :download="posterAssets[0].Asset.filename"
            />
          </div>
        </template>
      </div>

      <figure v-if="videoUrl" class="relative bg-black">
        <MxVideoEmbed :url="videoUrl" :caption="videoCaption" />
        <figcaption
          v-if="videoCaption && showCaptions"
          class="flex w-full px-5 py-3 justify-between items-end gap-x-10 text-xl bg-black/50"
        >
          <span class="text-white">{{ videoCaption }}</span>
        </figcaption>
      </figure>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { MediaBlockProps } from '~/types/MediaBlock';

const translate = useTranslation();
const { blok } = defineProps<{ blok: MediaBlockProps }>();
const {
  assets,
  posterAssets,
  titleBar,
  background,
  id,
  videoUrl,
  videoCaption,
  showCaptions,
  enableDownload,
  layout,
} = blok;
</script>
