<template>
  <div class="lg:grid lg:grid-cols-12 lg:gap-x-6" v-editable="content">
    <Shade class="lg:col-span-8" position="topLeft" transparent>
      <MxAssetRenderer
        class="h-[600px] lg:h-[700px]"
        :asset="asset"
        :image-class="assetConfiguration.imageClass"
        :video-config="assetConfiguration.videoConfig"
      />
    </Shade>

    <div class="lg:pb-14 lg:col-span-4 lg:self-end">
      <div class="max-w-[315px] lg:max-w-[360px] mx-auto -mt-12 lg:mt-0">
        <FancyHeading
          v-if="fancyHeading?.length"
          class="w-full mb-6 lg:mb-24 relative lg:-left-64"
          :lines="fancyHeading"
          headingLevel="h2"
        />

        <h2 v-else class="mt-6 lg:mt-0 text-4xl sm:text-5xl font-secondary">
          {{ title }}
        </h2>

        <div
          v-if="parsedCustomText || description"
          class="pb-5 text-xl border-b border-b-black/50 border-dashed"
        >
          <Vue3RuntimeTemplate v-if="parsedCustomText" :template="parsedCustomText" />

          <p v-else-if="description" class="text-xl">{{ description }}</p>
        </div>

        <MxButton
          v-if="mainCta"
          class="mt-6 lg:mt-12"
          :href="getStoryblokLink(mainCta)"
          layout="solid"
          size="large"
        >
          {{ mainCtaText }} <Icon class="text-xl" name="ArrowRight" width="1.5" />
        </MxButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Vue3RuntimeTemplate from 'vue3-runtime-template';
import type { CollectionItemFeaturedType } from '~/types/ContentCollection';
import { computed } from 'vue';
import type { SbAsset, SbLink } from '~/types/StoryBlok';
import { getStoryblokLink } from '~/composables/storyblokLink';
import type { ISbStoryData } from 'storyblok-js-client';
import type { EventProps, ProductionProps, TicketProps } from '~/types/Production';
import { safelyRenderRichText } from '~/utils/richText';

const props = defineProps<{
  content: CollectionItemFeaturedType;
}>();

const { storyToShow, customHeading, customText, customMedia, ctaText, fancyHeading } =
  props.content;

const translate = useTranslation();
const language = useState('language');

const assetConfiguration = {
  imageClass: 'w-full h-full absolute top-0 left-0',
  videoConfig: {
    wrapperClass: '!absolute top-0 left-0 media lg:!aspect-auto h-full min-w-full',
    playerClass: '!h-full w-full z-0 object-cover object-center',
    autoplay: true,
    muted: true,
    loop: true,
    hideControls: true,
    loadOnVisible: true,
    nativePlayer: true, // use native html video player with auto-adjusting source
    transformations: 'eo_7', // note: for VideoPlayerNative, limit length to 7 seconds
  },
};

const storyType = storyToShow?.content?.component;

const eventProduction = computed<ISbStoryData<ProductionProps> | undefined>(() => {
  if (storyType === 'Event') {
    return (storyToShow as ISbStoryData<EventProps>)?.content?.SyncProduction.find(
      (production) => production.full_slug.split('/')[0] === language.value,
    );
  }

  return undefined;
});

const title = computed(() => {
  if (storyType === 'Event') {
    return storyToShow?.content?.Name || storyToShow?.content?.SyncName || '';
  }

  return customHeading || storyToShow?.content?.summaryTitle || storyToShow?.name || '';
});
const description = computed(() => {
  if (storyType === 'Event' && eventProduction.value) {
    return (
      eventProduction.value?.content?.excerpt ||
      eventProduction.value?.content?.SyncDescription ||
      ''
    );
  }

  return storyToShow?.content?.excerpt || storyToShow?.content?.SyncDescription || '';
});
const parsedCustomText = computed(() => safelyRenderRichText(customText));
const mainCta = computed<SbLink | undefined>(() => {
  if (storyType === 'Event') {
    return {
      linktype: 'story',
      cached_url: eventProduction.value?.full_slug || '',
    };
  }

  if (storyToShow?.full_slug) {
    return {
      linktype: 'story',
      cached_url: storyToShow.full_slug,
    };
  }

  return undefined;
});
const mainCtaText = computed(() => {
  if (storyType === 'Event') {
    const freeEvent = storyToShow?.content?.SyncPricing?.every((pricing: TicketProps) => {
      return pricing.PriceZoneA <= 0 && pricing.PriceZoneB <= 0 && pricing.PriceZoneC <= 0;
    });

    return ctaText || translate(freeEvent ? 'action.read_more' : 'action.purchase');
  }

  return ctaText;
});
const asset = computed<SbAsset>(() => {
  if (customMedia?.public_id) {
    return customMedia;
  }

  if (storyType === 'Event' && eventProduction.value) {
    if (eventProduction.value?.content.thumbnail?.public_id) {
      return eventProduction.value.content.thumbnail;
    }
    if (eventProduction.value?.content.SyncImages?.[0]?.public_id) {
      return eventProduction.value.content.SyncImages[0];
    }
  }

  if (storyToShow?.content?.thumbnail?.public_id) {
    return storyToShow?.content?.thumbnail;
  }

  if (
    storyToShow?.content?.body?.find((block: any) => block.component === 'Hero')?.asset?.public_id
  ) {
    return storyToShow?.content?.body?.find((block: any) => block.component === 'Hero')?.asset;
  }
});
</script>
