<template>
  <div class="fixed top-0 left-0 max-w-none min-w-full min-h-screen z-10">
    <video
      v-if="isUrlVideo(video)"
      @error="(e) => console.error('Video error:', e)"
      autoplay
      muted
      loop
      playsinline
      class="absolute top-0 left-0 max-w-none min-w-full min-h-screen transition-opacity duration-1000 left-1/2 -translate-x-1/2 md:-translate-x-0"
    >
      <source :src="video" type="video/mp4" />
    </video>
    <NuxtImg
      v-if="isMounted && isUrlImage(image.filename)"
      :src="image.filename"
      provider="cloudinary"
      :alt="image.alt"
      class="fixed top-0 left-0 max-w-none min-w-full min-h-screen transition-opacity duration-1000"
      loading="lazy"
      fit="fill"
      :modifiers="{
        w: width,
        ar: aspectRatio(width),
        gravity: focalPoint ? 'xy_center' : 'auto',
        x: focalPoint ? focalPoint.x : null,
        y: focalPoint ? focalPoint.y : null,
      }"
    />
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import { isUrlImage, isUrlVideo } from '~/utils/media';
import { getFocalCoordinates } from '~/utils/getFocalCoordinates';

// Note: used in scrollytelling components

const props = defineProps({
  image: {
    type: Object,
    required: false,
    default: '',
  },
  video: {
    type: String,
    required: false,
    default: '',
  },
  aspectRatio: {
    type: String,
    required: false,
    default: '',
  },
  fit: {
    type: String,
    required: false,
    default: '',
  },
});

const isMounted = ref(false);
const width = ref(1920);
const height = ref(1080);

const aspectRatio = (width: number) => {
  if (width < 768) return '0.5';
  if (width >= 768 && width < 1024) return '0.9';
  if (width >= 1024 && width < 1280) return '1.2';
  if (width >= 1280 && width < 1536) return '1.4';
  if (width >= 1536) return '1.8';
  return '1.4';
};

const focalPoint = computed(() => {
  if (!props.image?.focus) return null;
  return getFocalCoordinates(props.image.focus);
});

const isStoryblokEditor = ref(false);

onMounted(() => {
  isMounted.value = true;

  isStoryblokEditor.value =
    window.location.href.includes('_storyblok') || window.location.search.includes('_storyblok');
  // If we're in the editor, set width immediately. If we wait for the next tick,
  // storyblok won't be able to show content in the visual editor
  if (isStoryblokEditor.value) {
    width.value = window.innerWidth;
    return;
  }

  // Otherwise use the deferred approach
  nextTick(() => {
    width.value = window.innerWidth;

    window.addEventListener('resize', () => {
      width.value = window.innerWidth;
    });
  });
});

onUnmounted(() => {
  window.removeEventListener('resize', () => {});
});
</script>
