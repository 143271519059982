<template>
  <div
    class="py-8 lg:py-12"
    :class="{
      'bg-dark text-white': background === 'dark',
      'bg-light-white': background === 'light',
    }"
    v-editable="blok"
  >
    <div class="container">
      <h2 v-if="heading" class="mb-12 lg:mb-20 text-5xl md:text-6xl font-secondary">
        {{ heading }}
      </h2>

      <Shade size="large" position="topLeft">
        <div class="p-6 lg:grid lg:grid-cols-2 lg:grid-rows-1 bg-white">
          <div
            class="lg:px-14 lg:py-16 space-y-10"
            :class="{
              [`p-6 bg-${contentBackgroundLeft}`]: !!contentBackgroundLeft,
            }"
          >
            <div :class="[...richTextStyling]" v-if="contentLeft">
              <Vue3RuntimeTemplate :template="renderRichText(contentLeft)" />
            </div>

            <div v-if="ctaLeft && ctaLabelLeft" class="text-center">
              <MxButton :href="getStoryblokLink(ctaLeft)" theme="primary">
                {{ ctaLabelRight }}

                <Icon class="text-xl" name="ArrowRight" width="1.5" />
              </MxButton>
            </div>
          </div>

          <div
            class="lg:px-14 lg:py-16 space-y-10"
            :class="{
              [`p-6 bg-${contentBackgroundRight}`]: !!contentBackgroundRight,
            }"
          >
            <div class="mt-10 lg:mt-0" :class="[...richTextStyling]" v-if="contentRight">
              <Vue3RuntimeTemplate :template="renderRichText(contentRight)" />
            </div>

            <div v-if="ctaRight && ctaLabelRight" class="text-center">
              <MxButton :href="getStoryblokLink(ctaRight)" theme="primary">
                {{ ctaLabelRight }}

                <Icon class="text-xl" name="ArrowRight" width="1.5" />
              </MxButton>
            </div>
          </div>
        </div>
      </Shade>
    </div>
  </div>
</template>

<script setup lang="ts">
import Vue3RuntimeTemplate from 'vue3-runtime-template';
import { getStoryblokLink } from '~/composables/storyblokLink';
import type { SideBySideBlockProps } from '~/types/SideBySideBlock';

const { blok } = defineProps<{
  blok: SideBySideBlockProps;
}>();
const {
  heading,
  background,
  contentLeft,
  ctaLeft,
  ctaLabelLeft,
  contentBackgroundLeft,
  contentRight,
  ctaRight,
  ctaLabelRight,
  contentBackgroundRight,
} = blok;

const richTextStyling = [
  'mx-auto prose richTextLargeLists richTextHeadingsFontSecondary richTextNoImageMargin lg:text-xl',
];
</script>

<style scoped>
.prose {
  :deep(.large-text) {
    font-size: 1.75rem;
    line-height: 1.15;
  }

  @screen xs {
    :deep(.large-text) {
      font-size: 2rem;
    }
  }
  @screen lg {
    :deep(.large-text) {
      font-size: 4rem;
    }
  }
}
</style>
