const timeZone = 'Europe/Oslo';
const defaultOptions: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

export const formatDate = (date: string, options: Intl.DateTimeFormatOptions = defaultOptions) => {
  const language = useState<string>('language');
  const dateTimeFormat = new Intl.DateTimeFormat(language.value, {
    // Note: this will print the date in the selected timezone, ignoring the browser system timezone
    // If you want to display dates in the user's timezone, remove this property
    timeZone, // Keep timezone when customizing format options
    ...options,
  });

  /**
   * Capitalizing the first letter of the weekday
   * norwegian weekdays are lowercase by default
   */
  const formatParts = dateTimeFormat.formatToParts(new Date(date));
  const weekdayIndex = formatParts.findIndex((part) => part.type === 'weekday');
  const weekday = formatParts[weekdayIndex];
  if (weekday) {
    formatParts[weekdayIndex].value =
      weekday.value.charAt(0).toUpperCase() + weekday.value.slice(1);
  }

  // Replace colon with period in time parts
  const timePartTypes = ['hour', 'minute', 'second'];
  let previousPartType = '';

  formatParts.forEach((part, index) => {
    // If this is a literal part that contains ":" and is between time parts, replace it with "."
    if (
      part.type === 'literal' &&
      part.value === ':' &&
      index > 0 &&
      timePartTypes.includes(previousPartType) &&
      index < formatParts.length - 1 &&
      timePartTypes.includes(formatParts[index + 1].type)
    ) {
      formatParts[index].value = '.';
    }
    previousPartType = part.type;
  });

  const datePartValues = formatParts.map((part) => part.value);
  return datePartValues.join('');
};

export const addTimeZoneToSBDate = (date: string) => {
  return `${date.replace(' ', 'T')}:00.000Z`;
};

export const formatDateForSB = (date: Date) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

export function areDatesSameDay(d1: Date, d2: Date) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

// Helper function to format time ranges with periods instead of colons
export const formatTimeRange = (startTime: string, endTime: string) => {
  // Assuming times are in format "HH:MM"
  return `${startTime.replace(':', '.')} - ${endTime.replace(':', '.')}`;
};
