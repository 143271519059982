import type { ISbStoryData } from 'storyblok-js-client';

declare type StorybookBlockData = {
  component: string;
  [x: string]: any;
};

declare type ContentCollectionData = {
  component: 'ContentCollection';
  items: ContentCollectionItemData[];
  [x: string]: any;
};

declare type ContentCollectionItemData = {
  component: 'ContentCollectionItem';
  storyToShow: ISbStoryData;
  [x: string]: any;
};

// Removes all but first block from story content collection blocks
// (we use the first hero block for SEO, title and bg image)
// Use this for the page story data
export const optimizeStoryContentCollections = (story: ISbStoryData) => {
  try {
    if (story.content?.body) {
      story.content.body = (story.content.body as StorybookBlockData[]).map((block) => {
        if (block.component !== 'ContentCollection') {
          return block;
        }
        block.items = (block.items as ContentCollectionItemData[]).map((contentCollectionItem) => {
          if (!contentCollectionItem.storyToShow?.content?.body?.length) {
            return contentCollectionItem;
          }
          contentCollectionItem.storyToShow.content.body =
            contentCollectionItem.storyToShow.content.body.slice(0, 1);
          return contentCollectionItem;
        });
        return block;
      });
    }
  } catch (error) {
    console.error('Failed to slim down story content collection items');
    console.error(error);
  }
  return story;
};

// Removes all but the first block from story body
// We use the first block to find the right media to show in collections
// Use this in content collections and listing type components for stories array
export const trimStoryBlocks = (story: ISbStoryData) => {
  if (story.content?.body?.length) {
    story.content.body = story.content.body.slice(0, 1);
  }

  return story
}