import type { SbLink } from '~/types/StoryBlok';

export const getStoryblokLink = (link: SbLink, replaceLocale: boolean = true) => {
  if (!link) {
    return '';
  }

  if (link?.linktype === 'url') {
    return link.cached_url;
  }

  return parseStoryblokFullSlug(link?.cached_url, replaceLocale);
};

export const parseStoryblokFullSlug = (
  fullSlug: string | undefined,
  replaceLocale: boolean = true,
) => {
  if (!fullSlug) return '';

  const runtimeConfig = useRuntimeConfig();
  const language = useState('language');

  // Note: prefixing siteUrl to make this an absolute URL would prevent Nuxt SPA navigation!
  const result = `${runtimeConfig.public.siteUrl}/${fullSlug.replace(/^\/+/, '')}`.replace(
    `/${runtimeConfig.public.websiteFolder}/`,
    '/',
  );

  if (replaceLocale) {
    return result.replace(`/${language.value}/${language.value}/`, `/${language.value}/`);
  }

  return result;
};
