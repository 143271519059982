<template>
  <div v-editable="content">
    <component
      :is="mainCta ? NuxtLink : 'div'"
      v-if="itemStyle === 'simple'"
      :to="mainCta ? getStoryblokLink(mainCta) : undefined"
      class="collection-item hasTransition block relative"
    >
      <MxAssetRenderer
        class="media flex h-[600px] px-5 py-8 lg:p-6 flex-col justify-end overflow-hidden"
        :asset="asset"
        :image-class="assetConfiguration.imageClass"
        :video-config="assetConfiguration.videoConfig"
      >
        <component
          class="flex relative z-10 gap-x-6 items-end justify-between text-4xl sm:text-5xl font-secondary text-white"
          :is="headingLevel"
        >
          {{ title }}

          <Icon
            v-if="!(description || parsedCustomText) && storyToShow?.full_slug"
            class="text-xl shrink-0 text-primary"
            name="ArrowRight"
            width="1.5"
          />
        </component>
        <div
          v-if="parsedCustomText || description"
          class="flex mt-3 pt-3 relative z-10 gap-x-6 items-end justify-between border-t border-dashed border-white/50"
        >
          <div class="text-xl text-white">
            <Vue3RuntimeTemplate v-if="parsedCustomText" :template="parsedCustomText" />

            <p v-else-if="description">{{ description }}</p>
          </div>

          <Icon
            v-if="storyToShow?.full_slug"
            class="text-xl shrink-0 text-primary"
            name="ArrowRight"
            width="1.5"
          />
        </div>
      </MxAssetRenderer>
      <AvailabilityLabel
        v-if="availabilityProps?.id"
        :id="availabilityProps.id"
        :type="availabilityProps.type"
      />
    </component>

    <!--Used in programme schedule-->
    <component
      :is="itemStyle === 'layered' ? Shade : 'div'"
      transparent
      :interactive="collapsible"
      v-if="itemStyle === 'layered' || itemStyle === 'layered-simple'"
      class="h-full"
    >
      <div
        class="flex collection-item relative 2xl:min-w-[300px] h-full"
        :class="{
          hasTransition: !collapsible,
          'flex-col lg:flex-row-reverse': priority === 'high',
          'flex-row lg:flex-col': priority === 'medium',
          'flex-col': priority === 'low' || priority === 'none',
        }"
      >
        <MxAssetRenderer
          class="media bg-light-accent overflow-hidden"
          :class="figureClass"
          :asset="asset"
          :image-class="assetConfiguration.imageClass"
          :video-config="assetConfiguration.videoConfig"
          :modifiers="{ gravity: 'center' }"
          fit="fill"
          :aspect-ratio="'1.3'"
        />
        <CardDetails
          class="grow details min-h-[250px] md:min-h-[300px] bg-gray/20 text-dark px-3 py-2 lg:px-5 lg:py-6"
          :content="description"
          :rich-text-content="parsedCustomText"
          :main-cta-type="ctaType"
          :main-href="mainCta"
          :main-cta-text="mainCtaText"
          :syncId="storyToShow?.content?.SyncId"
          :freeEvent="freeEvent"
          :priority="priority"
        >
          <template v-if="title" #lead>
            <component
              class="relative z-10 text-lg font-secondary break-words"
              :class="{
                '!text-2xl !lg:text-5xl lg:pt-2': priority === 'high',
                '!lg:text-3xl': priority !== 'high',
              }"
              :is="headingLevel"
            >
              {{ title }}
            </component>
            <p
              v-if="productionSubtitle"
              class="text-base lg:text-xl mb-2 break-words"
              :class="{ '!lg:text-2xl': priority === 'high', '!lg:text-xl': priority !== 'high' }"
            >
              {{ productionSubtitle }}
            </p>
          </template>

          <template v-if="storyType === 'Event' && eventProduction" #extras>
            <div
              class="flex flex-wrap mb-2 xl:mb-1 py-3 gap-2 border-y border-solid border-black/25 sm:text-xl"
            >
              <span
                v-if="storyToShow?.content?.SyncVenue.content || storyToShow?.content?.SyncScene"
                class="flex items-center gap-x-2 text-sm lg:text-base font-medium break-words"
              >
                <Icon class="text-primary" name="LocationMarker" />

                {{
                  storyToShow?.content?.SyncVenue.content?.Name ||
                  storyToShow?.content?.SyncVenue.content?.name ||
                  storyToShow?.content.SyncVenue.content?.SyncName ||
                  storyToShow?.content?.SyncScene
                }}
              </span>

              <span class="flex items-center gap-x-2 text-sm lg:text-base font-medium">
                <Icon class="text-primary" name="CheckmarkCircled" />

                Kl.
                {{
                  formatDate(addTimeZoneToSBDate(storyToShow?.content.SyncEventStartTime), {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })
                }}
                -
                {{
                  formatDate(addTimeZoneToSBDate(storyToShow?.content.SyncEventEndTime), {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })
                }}
              </span>
            </div>
          </template>
        </CardDetails>

        <AvailabilityLabel
          v-if="availabilityProps?.id"
          :id="availabilityProps.id"
          :type="availabilityProps.type"
        />
      </div>
    </component>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { NuxtLink } from '#components';
import Vue3RuntimeTemplate from 'vue3-runtime-template';
import type { SbAsset, SbLink } from '~/types/StoryBlok';
import type { CollectionItemStyle, CollectionItemType } from '~/types/ContentCollection';
import { getStoryblokLink } from '~/composables/storyblokLink';
import type { ISbStoryData } from 'storyblok-js-client';
import type { EventProps, ProductionProps } from '~/types/Production';
import { isEventPricingFree } from '~/composables/pricing';
import { addTimeZoneToSBDate, formatDate } from '~/utils/formatDate';
import { safelyRenderRichText } from '~/utils/richText';
import AvailabilityLabel from '../AvailabilityLabel.vue';
import Shade from '../Shade.vue';

const {
  content,
  headingLevel = 'h2',
  itemStyle = 'layered',
  collapsible = false,
  priority = 'none',
  figureClass,
} = defineProps<{
  content: CollectionItemType;
  headingLevel?: 'h2' | 'h3';
  itemStyle?: CollectionItemStyle;
  collapsible?: boolean;
  priority?: 'high' | 'medium' | 'low' | 'none';
  figureClass?: string;
}>();
const { storyToShow, customHeading, customText, customMedia, ctaText, ctaType } = content;

const translate = useTranslation();
const language = useState('language');

const assetConfiguration = {
  imageClass: 'w-full h-full absolute top-0 left-0',
  videoConfig: {
    wrapperClass: '!absolute top-0 left-0 media lg:!aspect-auto h-full min-w-full',
    playerClass: '!h-full w-full z-0 object-cover object-center',
    autoplay: true,
    muted: true,
    loop: true,
    hideControls: true,
    loadOnVisible: true,
    nativePlayer: true, // use native html video player with auto-adjusting source
    transformations: 'eo_7', // note: limit length to 7 seconds
  },
};

const storyType = storyToShow?.content?.component;

const eventProduction = computed<ISbStoryData<ProductionProps> | undefined>(() => {
  if (storyType === 'Event') {
    return (storyToShow as ISbStoryData<EventProps>)?.content?.SyncProduction.find((production) => {
      const fullSlugSegments = production.full_slug.split('/');

      if (fullSlugSegments[1].length === 2) {
        fullSlugSegments.shift();
      }

      return fullSlugSegments[0] === language.value;
    });
  }

  return undefined;
});
const title = computed(() => {
  if (storyType === 'Event') {
    return (
      eventProduction.value?.content?.summaryTitle ||
      eventProduction.value?.content?.SyncName ||
      storyToShow?.content?.Name ||
      storyToShow?.content?.SyncName ||
      ''
    );
  }

  return customHeading || storyToShow?.content?.summaryTitle || storyToShow?.name || '';
});

const description = computed(() => {
  if (storyType === 'Event' && eventProduction.value) {
    return (
      eventProduction.value?.content?.excerpt ||
      eventProduction.value?.content?.SyncDescription ||
      ''
    );
  }

  return storyToShow?.content?.excerpt || storyToShow?.content?.SyncDescription || '';
});
const parsedCustomText = computed(() => safelyRenderRichText(customText));

const mainCta = computed<SbLink | undefined>(() => {
  if (storyType === 'Event') {
    return {
      linktype: 'story',
      cached_url: eventProduction.value?.full_slug || '',
    };
  }

  if (storyToShow?.full_slug) {
    return {
      linktype: 'story',
      cached_url: storyToShow.full_slug,
    };
  }

  return undefined;
});

const freeEvent = isEventPricingFree(storyToShow?.content?.SyncPricing);

const mainCtaText = computed(() => {
  if (storyType === 'Event') {
    return ctaText || translate(freeEvent ? 'action.free' : 'action.purchase');
  }

  return ctaText;
});
const asset = computed<SbAsset>(() => {
  if (customMedia?.public_id) {
    return customMedia;
  }

  if (storyType === 'Event' && eventProduction.value) {
    if (eventProduction.value?.content.thumbnail?.public_id) {
      return eventProduction.value.content.thumbnail;
    }
    if (eventProduction.value?.content.SyncImages?.[0]?.public_id) {
      return eventProduction.value.content.SyncImages[0];
    }
  }

  if (storyToShow?.content?.thumbnail?.public_id) {
    return storyToShow?.content?.thumbnail;
  }

  if (
    storyToShow?.content?.body?.find((block: any) => block.component === 'Hero')?.asset?.public_id
  ) {
    return storyToShow?.content?.body?.find((block: any) => block.component === 'Hero')?.asset;
  }
});

const productionSubtitle = computed<string>(() => {
  if (storyType === 'Event') {
    return (
      eventProduction.value?.content?.Subtitle || eventProduction.value?.content?.SyncSubtitle || ''
    );
  }

  return storyToShow?.content?.Subtitle || (storyToShow as any)?.subtitle || '';
});

const availabilityProps = computed(() => {
  if (storyType === 'Production' && storyToShow?.content.SyncId) {
    return {
      id: storyToShow?.content.SyncId,
      type: 'Production',
    };
  }
  if (storyType === 'Event' && storyToShow?.content.SyncId) {
    return {
      id: storyToShow?.content.SyncId,
      type: 'Event',
    };
  }
  return;
});
</script>

<style scoped>
.collection-item {
  :deep(.media) {
    &:after {
      width: 100%;
      height: 50%;

      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 0;

      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 75%);
      content: '';
      opacity: 0.5;
    }
  }
}
.hasTransition {
  :deep(img) {
    transition: transform 0.15s ease-in 0s;
  }
  &:hover {
    :deep(img) {
      transform: scale(1.05);
    }
  }
}
</style>
