<template>
  <div
    class="pb-8 lg:pb-12"
    :class="{
      'bg-dark text-white': background === 'dark',
      'bg-light-white': background === 'light',
    }"
    v-editable="blok"
  >
    <div
      class="container"
      :class="{
        'grid grid-cols-8 lg:grid-cols-12 gap-x-3 lg:gap-x-6': headingAlign === 'grid',
      }"
    >
      <h2
        v-if="heading"
        class="mb-[0.5em] text-3xl md:text-5xl font-secondary font-semibold"
        :class="{
          'col-span-8 lg:col-span-10 lg:col-start-2': headingAlign === 'grid',
        }"
      >
        {{ heading }}
      </h2>
    </div>

    <div
      class="container grid grid-cols-8 lg:grid-cols-12 gap-x-3 lg:gap-x-6 space-y-6 lg:space-y-9"
    >
      <AccordionItem
        v-for="accordion in faqList"
        :key="accordion.content._uid"
        :summary="accordion.content.summary"
        :details="accordion.content.details"
        class="px-4 lg:px-9 col-span-8 lg:col-span-10 lg:col-start-2 border-2 border-primary rounded-[30px]"
        summary-class="py-3 text-xl lg:text-3xl"
        details-class="pt-4 pb-7 text-xl border-t border-dashed border-white/70"
        v-editable="accordion"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { FAQBlockProps } from '~/types/FAQBlock';

const { blok } = defineProps<{
  blok: FAQBlockProps;
}>();
const { faqList, heading, headingAlign, background } = blok;
</script>
