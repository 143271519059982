<template>
  <!--Remove bg-light-white for production-->
  <div class="grid grid-cols-12 bg-light-white">
    <h2 class="font-medium text-2xl w-[100vw] col-strt-1 md:col-start-2 my-4 mx-4">
      Result from Algolia
    </h2>
    <nav
      class="pb-4 md:pb-2 text-lg col-start-1 md:col-start-2 col-span-12 md:col-span-10 mb-4 mx-4 overflow-hidden max-md:overflow-x-scroll"
    >
      <ul class="flex flex-row gap-3">
        <li v-for="(pageType, index) in pageTypes" class="flex flex-row gap-3">
          <button
            :key="pageType"
            @click="handlePageTypeFilter(pageType)"
            class="py-2 px-4 text-md rounded-full w-full flex flex-row gap-1"
            :class="currentPageType === pageType ? 'bg-primary' : 'bg-gray/30'"
          >
            {{ translate(`action.${pageType.toLowerCase()}`) }}
            <span class="">({{ getCountByType(pageType) }})</span>
          </button>
          <div v-if="index === 0" class="h-full w-[2px] bg-gray/20"></div>
        </li>
      </ul>
    </nav>
    <div v-if="displayResults" class="col-start-1 col-span-12 md:col-start-2 md:col-span-11 px-2">
      <NuxtLink
        v-for="hit in displayResults.hits"
        :key="hit.id"
        :to="parseStoryblokFullSlug(`${hit.slug}`)"
      >
        <div
          class="flex md:flex-row flex-col px-2 md:px-0 gap-1 py-4 md:gap-4 md:mr-8 border-b-2 border-primary-accent cursor-pointer transition-all duration-300 ease-in"
          :class="{
            hasTransition: !collapsible,
          }"
        >
          <nuxtImg
            :src="hit.thumbnail.filename || placeholder.filename"
            :alt="hit.thumbnail.title"
            class="max-w-[full] min-w-[full] md:max-w-[200px] md:min-w-[200px] w-full md:h-[110px] h-[177px] object-cover my-auto mt-2 md:mt-0"
          />
          <div class="flex flex-col-reverse md:flex-col p-1 md:p-2 justify-between">
            <div>
              <p class="font-medium text-lg md:text-2xl">
                {{ hit.name }}
              </p>
              <p class="text-md md:text-lg">{{ sliceString(hit.excerpt, 80) }}</p>
            </div>
            <div class="flex flex-row gap-2 my-1 md:my-2">
              <p
                v-if="pageTypes.includes(hit.type)"
                class="bg-primary py-1.5 px-3 rounded-full text-sm"
              >
                {{ translate(`action.${hit.type.toLowerCase()}`) }}
              </p>
            </div>
          </div>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { algoliasearch } from 'algoliasearch';
import type { SbAsset } from '~/types/StoryBlok';
import type { AlgoliaBlokType, AlgoliaHit } from '~/types/Algolia';

const runtimeconfig = useRuntimeConfig();
const client = algoliasearch(
  runtimeconfig.public.algoliaAppId,
  runtimeconfig.public.algoliaAdminKey,
);
const language = useState('language');
const translate = useTranslation();
const siteUrl = runtimeconfig.public.siteUrl;

const currentPageType = ref('All');
const selectedCategory = ref('');
const dateFilter = ref('');

const { placeholder } = defineProps<{
  placeholder: SbAsset;
}>();

// Create a ref for search results
const searchResults = ref<any>(null);

// Computed property to handle filtered results
const displayResults = computed(() => {
  if (!searchResults.value) return null;

  if (currentPageType.value === 'All') {
    return searchResults.value;
  }

  return {
    ...searchResults.value,
    hits: searchResults.value.hits.filter((hit: AlgoliaHit) => hit.type === currentPageType.value),
  };
});

// Create a function to perform the search
const performSearch = async (query = 'grieg') => {
  try {
    const { results } = await client.search({
      requests: [
        {
          indexName: 'storyblok',
          query,
          filters: `lang:${language.value}`,
        },
      ],
    });
    searchResults.value = results[0];
  } catch (error) {
    console.error('Search error:', error);
    searchResults.value = null;
  }
};

// Handle page type filter
const handlePageTypeFilter = (pageType: string) => {
  currentPageType.value = pageType;
};

// Helper function for string truncation
const sliceString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + '... Les mer';
};

// Perform initial search when component mounts
onMounted(() => {
  performSearch();
});

const pageTypes = ['All', 'Production', 'Article', 'Venue'];

const getCountByType = (type: string) => {
  if (!searchResults.value?.hits) return 0;

  if (type === 'All') {
    return searchResults.value.hits.length;
  }

  return searchResults.value.hits.filter((hit: AlgoliaHit) => hit.type === type).length;
};
</script>
<style>
nav {
  -webkit-overflow-scrolling: touch;
  /* For Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 4px; /* For horizontal scrollbar */
    display: block;
  }

  &::-webkit-scrollbar-track {
    background: rgb(248, 159, 159);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e37ab2;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #e37ab2;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #e37ab2 gray;
}
</style>
