<template>
  <NuxtLayout>
    <StoryblokComponent v-if="is404 && story?.content" :blok="story.content" />
    <div v-else class="bg-dark text-white min-h-[70vh] pt-72 pb-16">
      <div class="container">
        <h1 class="text-8xl mb-8">{{ translate('heading.error') }} {{ error?.statusCode }}</h1>
        <h2 class="text-6xl">{{ error?.message }}: {{ error?.statusMessage }}</h2>
        <h3 class="text-4xl" v-if="error?.url">{{ error?.url }}</h3>
        <div v-if="isDevEnv" v-html="error?.stack"></div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import type { ISbStoryData } from 'storyblok-js-client';
import type { _AsyncData } from '#app/composables/asyncData';
import { useTranslation } from '~/composables/useTranslation';
import type { NewsletterContentType, TranslationsType, SocialNetwork } from '~/types/common';
import type { NuxtError } from '#app';
import { useMeta } from './composables/useMeta';
import { STORY_RELATIONS } from './composables/relations';

const props = defineProps({
  error: Object as () => NuxtError & { url?: string },
});

const is404 = computed(() => props.error?.statusCode === 404);

const route = useRoute();
const runtimeConfig = useRuntimeConfig();
const translate = useTranslation();

const { defaultLanguage, storyBlokApiKey, supportedLanguages, publishState } = runtimeConfig.public;
const { isDevEnv } = runtimeConfig.app;

useHead({
  link: [
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
    { rel: 'manifest', href: '/site.webmanifest' },
    { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#9d2f50' },
  ],
  meta: [
    { name: 'msapplication-TileColor', content: '#000000' },
    { name: 'theme-color', content: '#ffffff' },
  ],
});

let locale = defaultLanguage;
if (route.query.path) {
  locale = (route.query.path as string).split('/')?.[0];
} else if (route.params.slug?.[0] && supportedLanguages.includes(route.params.slug?.[0])) {
  locale = route.params.slug?.[0];
}

const language = useState('language', () => locale);
const headerContent = useState<any>('headerContent');
const footerContent = useState<any>('footerContent');
const newsletterContent = useState<NewsletterContentType>('newsletterContent');
const translations = useState<TranslationsType>('translations', () => {
  return {
    no: {},
    en: {},
  };
});

const socialNetworks = useState<any[]>('socialNetworks', () => []);
const story = useState<ISbStoryData>('story', () => ({}) as unknown as ISbStoryData);

const alternates = useState('alternates', () => []);
await callOnce(async () => {
  try {
    const baseParameters = {
      version: publishState as 'draft' | 'published',
      token: storyBlokApiKey,
      language: language.value,
    };
    const [siteConfig, storyResponse] = await Promise.all([
      $fetch(`/api/site-config`),
      useStoryblokApi().get(`cdn/stories/${language.value}/404`, {
        resolve_relations: STORY_RELATIONS,
        ...baseParameters,
      }),
    ]);

    const storyData = storyResponse?.data?.story || {};

    const { headerData, footerData, newsletterData, translationData, socialNetworksData } =
      (siteConfig as any) || {};

    headerContent.value = headerData;
    footerContent.value = footerData;
    newsletterContent.value = newsletterData;

    translationData?.forEach((translation: any) => {
      translations.value.no[translation.name] = translation.value;
      translations.value.en[translation.name] = translation.dimension_value;
    });

    const selectedSocialMedia = footerContent.value[language.value].social_media || [];
    socialNetworks.value = socialNetworksData.filter((network: SocialNetwork) =>
      selectedSocialMedia.includes(network.value),
    );

    story.value = storyData;
    alternates.value = storyData?.alternates || [];
  } catch (error) {
    console.error('Error page - failed to load site config & story');
    console.error(error);
  }
});

useMeta(
  {
    title: story.value.content?.summaryTitle || story.value.name,
  },
  language.value,
);
</script>
