<template>
  <div class="flex flex-col pb-3">
    <div
      v-if="heading"
      class="text-3xl uppercase font-secondary hyphens-auto sm:hyphens-none"
      :class="{
        'lg:text-5xl': featured,
      }"
    >
      {{ heading }}
    </div>

    <slot name="lead"></slot>

    <slot name="extras"></slot>

    <slot name="content">
      <div
        v-if="content || richTextContent"
        class="text-base lg:text-xl mb-4"
        :class="{ '!lg:text-xl': priority === 'high', '!lg:text-base': priority !== 'high' }"
      >
        <Vue3RuntimeTemplate v-if="richTextContent" :template="richTextContent" />

        <p v-else-if="content">{{ sliceString(content, 80) }}</p>
      </div>
    </slot>
    <div class="flex flex-row gap-1 lg:gap-2 mt-auto items-center">
      <MxButton
        v-if="mainHref && parsedMainCtaText && mainCtaType !== 'disabled'"
        class="main-link mt-auto self-start text-base 2xl:text-lg font-medium px-auto max-lg:py-2.2 lg:px-4 max-lg:flex-grow"
        :class="{
          'bg-primary-light': freeEvent,
        }"
        layout="solid"
        :href="getStoryblokLink(mainHref)"
        size="small"
      >
        {{ parsedMainCtaText }}
        <Icon v-if="width > 1024" class="text-xl" width="1.5" name="ArrowRight" />
      </MxButton>
      <template v-if="isLoggedIn">
        <MxButton
          v-if="favouritesState.favourites.includes(syncId)"
          class="text-base lg:text-xl 2xl:text-2xl w-fit !bg-black !hover:bg-black z-10"
          :class="{
            'cursor-wait': favouritesState.isLoading,
          }"
          size="small"
          :disabled="favouritesState.isLoading"
          @click="() => removeFavourite(syncId)"
        >
          <Icon name="Heart" class="text-primary" />
        </MxButton>
        <MxButton
          v-else
          class="text-base lg:text-xl 2xl:text-2xl w-fit !bg-gray/20 !border-gray/40 !hover:bg-black z-10"
          :class="{
            'cursor-wait': favouritesState.isLoading,
          }"
          size="small"
          :disabled="favouritesState.isLoading"
          @click="() => addFavourite(syncId)"
        >
          <Icon name="HeartOutline" />
        </MxButton>
      </template>
      <div v-else class="relative z-20">
        <MxButton
          :href="auth.logInUrl.value"
          class="text-base lg:text-xl 2xl:text-2xl h-fit !py-2 !px-2 !lg:py-3 !lg:px-3 !bg-gray/20 !border-gray/40 !hover:bg-black !hover:border-primary"
          size="small"
          @mouseenter="showTooltip = true"
          @mouseleave="showTooltip = false"
        >
          <Icon name="HeartOutline" />
        </MxButton>
        <div
          v-if="showTooltip"
          class="hidden md:block absolute bottom-full left-1/2 transform -translate-x-1/2 mb-3 px-3 pt-1.5 pb-2 bg-black text-white text-sm rounded whitespace-nowrap speech-bubble"
        >
          {{ loginTooltipText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Vue3RuntimeTemplate from 'vue3-runtime-template';
import { getStoryblokLink } from '~/composables/storyblokLink';
import type { CollectionLayoutType } from '~/types/ContentCollection';
import type { SbLink } from '~/types/StoryBlok';

const {
  heading,
  mainCtaText,
  mainCtaType,
  mainHref,
  content,
  featured,
  layout = 'grid',
  syncId,
  loginTooltipText = 'Logg inn for å legge til i favoritter',
  freeEvent,
  priority,
} = defineProps<{
  heading?: string;
  mainCtaType?: string;
  content?: string;
  richTextContent?: string;
  featured?: boolean;
  mainHref?: SbLink;
  mainCtaText?: string;
  layout?: CollectionLayoutType | 'sideBySide';
  syncId: string;
  loginTooltipText?: string;
  freeEvent: boolean;
  priority: 'high' | 'medium' | 'low' | 'none';
}>();

const sliceString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + '...';
};

const translate = useTranslation();
/**
 * defaults don't allow variables, so defaulting with translation here
 */
const parsedMainCtaText = mainCtaText || translate('action.read_more');

const showTooltip = ref(false);

// Favourites
const auth = useAuth();
const isLoggedIn = computed(() => auth.user.value.id);
const favouritesState = useFavourites();

const removeFavourite = async (SyncId: string) => {
  await customerIoAPI.removeEventFromFavourites(SyncId);
};

const addFavourite = async (SyncId: string) => {
  await customerIoAPI.addEventToFavourites(SyncId);
};

const isMounted = ref(false);
const width = ref(1920);

onMounted(() => {
  isMounted.value = true;

  nextTick(() => {
    width.value = window.innerWidth;

    window.addEventListener('resize', () => {
      width.value = window.innerWidth;
    });
  });
});
</script>

<style scoped>
.main-link {
  &:after {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    content: '';
  }
}

.speech-bubble::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px 8px 0;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
</style>
