<template>
  <component
    class="max-w-full inline-flex flex-col items-baseline leading-none text-dark"
    :is="headingLevel"
  >
    <FancyHeadingLine v-for="(line, lineIndex) in lines" :line="line" ref="linesReference" />
  </component>
</template>

<script setup lang="ts">
import type { FancyHeading } from '~/types/FancyHeading';

const { lines, headingLevel = 'h1' } = defineProps<{
  lines: FancyHeading;
  headingLevel?: 'h1' | 'h2' | 'h3';
}>();

const linesReference = ref([]);
</script>
