// 0 = Available
// 1 = Few tickets left (Above 80% sold out)
// 2 = Sold out

// export const useAvailabilityState = () => {
//   return useState('availability', () => ({
//     events: {} as Record<string, number>,
//     productions: {} as Record<string, number>,
//   }))
// }

export type AvailabilityState = {
  events: Record<string, number>;
  productions: Record<string, number>;
  lastLoaded: number | null;
};

const defaultAvailabilityState: AvailabilityState = {
  events: {},
  productions: {},
  lastLoaded: null,
};

// Note: when many components are rendered at once
// this prevent them each generating a separate request
let fetchDataPromise: Promise<any>;

const fetchData = async () => {
  if (fetchDataPromise) {
    return fetchDataPromise;
  }
  fetchDataPromise = $fetch(`/api/availability`, {
    method: 'GET',
  });
  return fetchDataPromise;
};

export const useAvailability = () => {
  const state = useState<AvailabilityState>('availability', () => defaultAvailabilityState);

  const loadAvailabilityData = async () => {
    const now = Date.now();
    const cutoffTimestamp = now - 60 * 5 * 100; // 5mins buffer
    if (state.value.lastLoaded && state.value.lastLoaded < cutoffTimestamp) {
      return;
    }

    const response = await fetchData();
    if (!response) {
      throw new Error(`Failed to fetch ticket's availability`);
    }
    state.value.events = (response as any).events;
    state.value.productions = (response as any).productions;
    state.value.lastLoaded = now;
  };

  loadAvailabilityData();

  return {
    loadAvailabilityData,
    state,
  };
};
