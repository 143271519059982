<template>
  <form method="GET" class="flex flex-col" :action="action">
    <h3 v-if="!hideLabel" class="mb-2 text-gray-500">{{ translate('label.search') }}</h3>
    <div :class="['grid grid-cols-[1fr_auto]', !compact && 'md:gap-4']">
      <input
        :class="[
          'flex items-center min-w-0 text-xl rounded-full bg-white pl-4 text-black',
          compact
            ? 'pr-12 shadow-[inset_0_2px_4px_0_rgba(0,0,0,0.1)] focus:outline-none'
            : 'pr-16 max-sm:h-[50px] md:pr-4 -mr-12 md:mr-0 border border-greyDark',
        ]"
        type="text"
        ref="inputRef"
        :placeholder="translate('label.searchPlaceholder')"
        name="q"
      />
      <MxButton
        type="submit"
        class="max-sm:h-[50px]"
        :class="[
          compact && 'shadow-[inset_10px_0_20px_-20px_rgba(0,0,0,0.5)] rounded-r-full -ml-12',
        ]"
      >
        <Icon class="text-2xl" name="Search" />
      </MxButton>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { useTranslation } from '~/composables/useTranslation';

defineProps<{ hideLabel?: boolean; compact?: boolean }>();
const inputRef = ref();

const translate = useTranslation();
const language = useState('language');

const action = computed(() => {
  if (language.value === 'no') {
    return '/sok';
  }
  if (language.value === 'en') {
    return '/en/search';
  }
  throw new Error(`Unknown language ${language.value}`);
});

const focusInput = () => {
  inputRef.value.focus();
};

defineExpose({ focusInput });
</script>
