<template>
  <span
    v-if="label"
    class="flex px-3 py-[6px] items-center gap-x-2 absolute left-0 top-0 bg-tertiary-light text-dark text-sm lg:text-base"
  >
    <Icon class="text-tertiary" name="Warning" />
    <span class="">{{ label }}</span>
  </span>
</template>

<script setup lang="ts">
import { useTranslation } from '~/composables/useTranslation';
import { useAvailability } from '~/composables/useAvailability';

const translate = useTranslation();
const availability = useAvailability();

const props = defineProps({
  id: {
    type: [Number, String],
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
});

const status = computed(() => {
  if (props.type === 'Event') {
    return availability.state.value.events[props.id];
  }
  if (props.type === 'Production') {
    return availability.state.value.productions[props.id];
  }
  console.error('Missing event or production id when checking availability');
});

const label = computed(() => {
  if (status.value === 1) {
    return translate('availability.fewTicketsLeft');
  }
  if (status.value === 2) {
    return translate('availability.soldOut');
  }
});
</script>
