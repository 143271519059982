<template>
  <i class="element" :style="iconSize" aria-hidden="true">
    <span :class="iconClass" class="w-full h-full" />
  </i>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string;
    width?: string;
    height?: string;
  }>(),
  {
    width: '1',
    height: '1',
  },
);

const iconSize = computed(() => {
  return {
    '--icon-width': `${props.width}em`,
    '--icon-height': `${props.height}em`,
  };
});

// Note: keep synced with '~/assets/icons/icon.collections'
const iconIndex: { [index: string]: string } = {
  ArrowLeft: 'i-custom-arrow-left',
  ArrowRight: 'i-custom-arrow-right',
  ArrowDown: 'i-custom-arrow-down',
  ArrowUp: 'i-custom-arrow-up',
  At: 'i-custom-at',
  Cart: 'i-custom-cart',
  Checkmark: 'i-custom-checkmark',
  CheckmarkCircled: 'i-custom-checkmark-circled',
  ChevronDown: 'i-custom-chevron-down',
  ChevronLeft: 'i-custom-chevron-left',
  ChevronRight: 'i-custom-chevron-right',
  ChevronUp: 'i-custom-chevron-up',
  Close: 'i-custom-close',
  Drink: 'i-custom-drink',
  Download: 'i-custom-download',
  Facebook: 'i-custom-facebook',
  HearingAid: 'i-custom-hearing-aid',
  Heart: 'i-custom-heart',
  HeartOutline: 'i-custom-heart-outline',
  Instagram: 'i-custom-instagram',
  Language: 'i-custom-language',
  Link: 'i-custom-link',
  LinkedIn: 'i-custom-linkedin',
  Loader: 'i-custom-loader',
  LocationMarker: 'i-custom-location-marker',
  MenuProgram: 'i-custom-menu-program',
  MenuSupport: 'i-custom-menu-support',
  Phone: 'i-custom-phone',
  Plus: 'i-custom-plus',
  Quote: 'i-custom-quote',
  Return: 'i-custom-return',
  Search: 'i-custom-search',
  Snapchat: 'i-custom-snapchat',
  Spinner: 'i-custom-spinner animate-spin',
  TikTok: 'i-custom-tiktok',
  Twitter: 'i-custom-twitter',
  Warning: 'i-custom-warning',
  Wheelchair: 'i-custom-wheelchair',
  YouTube: 'i-custom-youtube',
  Eye: 'i-custom-eye',
  Calendar: 'i-custom-calendar',
  Clock: 'i-custom-clock',
};

const iconClass = computed(() => {
  const matchedClass = iconIndex[props.name];
  if (!matchedClass) {
    console.error(`Missing icon with name ${props.name}`);
  }

  return matchedClass;
});
</script>

<style scoped>
.element {
  display: inline-flex;
  width: var(--icon-width);
  height: var(--icon-height);

  align-items: center;
  justify-content: center;
  vertical-align: bottom;

  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
