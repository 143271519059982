<template>
  <div
    class="py-8 lg:py-12"
    :class="{
      'bg-dark text-white': background === 'dark',
      'bg-light-white': background === 'light',
    }"
    v-editable="blok"
  >
    <div class="container">
      <h2 v-if="heading" class="mb-[0.5em] text-3xl md:text-6xl font-secondary">
        {{ heading }}
      </h2>

      <template v-if="featuredStoryItem && layout !== 'carousel'">
        <ContentCollectionItemFeatured
          v-if="itemStyle === 'layered'"
          class="mb-12"
          :content="featuredStoryItem"
        />

        <ContentCollectionItem
          v-else-if="itemStyle === 'simple'"
          class="mb-12"
          :content="featuredStoryItem"
          item-style="simple"
        />
      </template>

      <template v-if="collectionItems?.length">
        <ContentCollectionGrid
          v-if="layout === 'grid'"
          :items="collectionItems"
          :item-style="itemStyle"
          :collection-heading="!!heading"
        />

        <ContentCollectionColumns
          v-else-if="layout === 'columns'"
          :items="collectionItems"
          :item-style="itemStyle"
          :collection-heading="!!heading"
        />

        <ContentCollectionCarousel
          v-else-if="layout === 'carousel'"
          :items="collectionItems"
          :featured-item="featuredStoryItem"
          :item-style="itemStyle"
          :collection-heading="!!heading"
          :items-per-page="3"
        />
      </template>

      <div
        v-if="ctaText && ctaLink"
        class="flex mt-12"
        :class="{
          'justify-start': ctaPosition === 'left',
          'justify-center': ctaPosition === 'center',
          'justify-end': ctaPosition === 'right',
        }"
      >
        <MxButton
          class="text-2xl"
          size="medium"
          layout="outline"
          :background="background"
          :href="
            getStoryblokLink({
              linktype: 'story',
              cached_url: ctaLink.full_slug,
            })
          "
        >
          {{ ctaText }} <Icon class="text-xl" name="ArrowRight" width="1.5" />
        </MxButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ISbStoryData } from 'storyblok-js-client';
import { getStoryblokLink } from '~/composables/storyblokLink';
import { MxButton } from '#components';
import type {
  CollectionItemType,
  CollectionBlokType,
  CollectionItemFeaturedType,
} from '~/types/ContentCollection';
import type { PageType } from '~/types/Page';

const { blok } = defineProps<{
  blok: CollectionBlokType;
}>();

const {
  featuredItem,
  items,
  useCategory,
  category,
  numberOfStories,
  heading,
  ctaLink,
  ctaText,
  ctaPosition,
  layout,
  itemStyle,
  background,
} = blok;

const language = useState('language');

const removeDuplicateLang = (path: string) => {
  // Note: remove duplicate language from of the way the slugs are structured in Storyblok
  if (path.startsWith(`${language.value}/${language.value}`)) {
    return path.replace(`${language.value}/`, '');
  }
  return path;
};

const fetchArticlesByIDs = async (ids: string[] = []) => {
  let response: any = {};
  try {
    response = await $fetch(`/api/content-collection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        ids,
      },
    });
    if (!response) {
      throw new Error('Failed to load content collection items by ids');
    }
    return response;
  } catch (error) {
    console.error('Failed to load content collection items by ids', error);
    return [];
  }
};

const featuredStoryUid = featuredItem?.[0]?.storyToShow;
const featuredStoryItem = ref<CollectionItemFeaturedType>();

const fetchArticlesFromCategory = async (category: string) => {
  const route = useRoute();
  const currentFullPath = `${language.value}${route.fullPath}`;
  const pathsToExclude = [currentFullPath];
  const excludeSlugs = pathsToExclude.map(removeDuplicateLang);
  const excludeIds = featuredStoryUid ? [featuredStoryUid] : undefined;

  let response: any = {};
  try {
    response = await $fetch(`/api/content-collection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        category,
        numberOfStories,
        excludeSlugs,
        excludeIds,
        language: language.value,
      },
    });
    if (!response) {
      throw new Error('Failed to load content collection items by category');
    }
    return response;
  } catch (error) {
    console.error('Failed to load content collection items by category', error);
    return [];
  }
};

const collectionItems = ref<CollectionItemType[]>([]);

const loadMainItems = async () => {
  if (category && useCategory) {
    const categoryArticles = await useAsyncData(
      `content_collection_category_${category}_${language.value}`,
      () => fetchArticlesFromCategory(category),
    );

    collectionItems.value = categoryArticles.data.value.map((story: PageType) => {
      return {
        storyToShow: story,
        ctaType: 'icon',
      };
    });
  } else {
    const ids = items.map((item) => item.storyToShow).filter((storyId) => storyId) as string[];
    const idsString = ids.sort().join('-');
    const dataKey = `content_collection_ids_${idsString}`;
    const manualArticles = await useAsyncData<ISbStoryData[]>(dataKey, () =>
      fetchArticlesByIDs(ids),
    );

    collectionItems.value = items.map((item) => {
      const storyToShow =
        item.storyToShow && manualArticles.data.value
          ? manualArticles.data.value.find((article) => article.uuid === item.storyToShow)
          : undefined;
      return {
        ...item,
        storyToShow,
      };
    });
  }
};

const loadFeaturedItem = async () => {
  if (featuredStoryUid) {
    const featuredResponse = await useAsyncData(
      `content_collection_featured_ids_${featuredStoryUid}`,
      () => fetchArticlesByIDs([featuredStoryUid]),
    );
    if (featuredResponse.data.value[0]) {
      featuredStoryItem.value = {
        ...featuredStoryItem.value,
        storyToShow: featuredResponse.data.value[0],
      };
    }
  }
};

// Note need to await to prevent hydration issues (everything renders in SSR)
await Promise.all([loadMainItems(), loadFeaturedItem()]);
</script>
