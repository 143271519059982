<template>
  <div
    class="richText-embedWrapper grid mt-15 grid-cols-8 lg:grid-cols-12 gap-x-3 lg:gap-x-6"
    :class="[`richText-embedWrapper--${container}`]"
    v-editable="blok"
  >
    <Shade class="col-span-8 lg:col-span-6 lg:col-start-4">
      <Box class="rich-text-block" align="left">
        <h2 class="items-center text-primary font-secondary text-4xl">{{ title }}</h2>

        <RuntimeTemplate :template="parsedText" />
      </Box>
    </Shade>
  </div>
</template>

<style scoped>
.rich-text-block p:last-of-type {
  margin-bottom: 0;
}
</style>

<script setup lang="ts">
import { computed } from 'vue';
import RuntimeTemplate from 'vue3-runtime-template';
import type { ISbRichtext } from 'storyblok-js-client';
import type { RichTextContainerType } from '~/types/RichTextBlock';

const { blok, container } = defineProps<{
  blok: {
    title: string;
    info: ISbRichtext;
  };
  container: RichTextContainerType;
}>();
const { title, info } = blok;

const parsedText = computed(() => renderRichText(info));
</script>
