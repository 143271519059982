<template>
  <figure class="flex flex-col contacts">
    <component class="text-2xl md:text-3xl !leading-tight" :is="headingLevel">{{ name }}</component>

    <p class="text-xl font-light hyphens-auto !leading-none">{{ title }}</p>

    <a
      class="flex mt-auto items-center gap-x-2 border-dashed border-t border-white/70 text-xl leading-normal text-primary break-all"
      :href="`mailto:${email}`"
    >
      <Icon name="At" /> {{ email }}
    </a>
  </figure>
</template>

<script setup lang="ts">
import type { ContactProps } from '~/types/ContactsBlock';
import type { ISbStoryData } from 'storyblok-js-client';

const { content, headingLevel = 'h2' } = defineProps<
  ISbStoryData<ContactProps> & {
    headingLevel: 'h2' | 'h3';
  }
>();
const { name, title, email } = content;
</script>

<style></style>
