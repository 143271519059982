<template>
  <div :class="{ [`theme-${theme}`]: !!theme }" v-editable="blok">
    <StoryblokComponent v-for="blokItem in blok.body" :key="blokItem._uid" :blok="blokItem" />
  </div>
</template>

<script setup lang="ts">
const { blok } = defineProps<{ blok: any }>();
const displayAnnouncement = computed(() => blok?.displayAnnouncement);
const theme = computed(() => blok?.theme?.slug);
</script>
