<template>
  <div
    class="py-8 richText"
    :class="{
      'bg-dark text-white': background === 'dark',
      'bg-light-white': background === 'light',
    }"
    v-editable="blok"
  >
    <div class="container grid grid-cols-8 lg:grid-cols-12 gap-x-3 lg:gap-x-6">
      <div
        class="col-span-8"
        :class="{
          'lg:col-span-10 lg:col-start-2': container === 'wide',
          'lg:col-start-3': container === 'narrow',
        }"
      >
        <div class="prose max-w-none richTextHeadingsFontSecondary text-xl listBullets [&_ul]:pl-0">
          <Vue3RuntimeTemplate :template="parsedContent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Vue3RuntimeTemplate from 'vue3-runtime-template';
import type { RichTextBlockProps } from '~/types/RichTextBlock';

const { blok } = defineProps<{ blok: RichTextBlockProps }>();
const { content, background, container } = blok;

const parsedContent = computed(() =>
  renderRichText(content, {
    resolver(component, blok) {
      return `<component :blok='${JSON.stringify(blok).replace(/'/g, '&apos;')}' is="${component}" :container='${JSON.stringify(container)}' />`;
    },
  }),
);
</script>
<style>
.listBullets li::before {
  content: '•';
  font-size: 2rem;
  margin-right: 0.5rem;
  line-height: 0.8;
}

.listBullets li {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding-bottom: 0.7rem;
}
</style>
