<template>
  <div class="mb-10 pt-10 pb-20 bg-dark">
    <div class="container">
      <div class="flex flex-wrap items-center">
        <form class="w-full mb-16 grid grid-cols-2 gap-8" @submit.prevent="onSubmit">
          <div class="flex flex-grow space-x-4 pt-4 border-t-8 border-primary-accent">
            <input
              class="flex flex-grow items-center px-4 py-2 w-64 bg-white border border-greyDark text-xl rounded-full"
              type="text"
              :placeholder="translate('label.search')"
              name="search"
              v-model="searchTerm"
            />

            <MxButton type="submit">
              <Icon class="text-4xl" name="Search" />
            </MxButton>
          </div>
          <div
            v-if="categoriesOptions.length > 2"
            class="flex flex-grow space-x-4 pt-4 border-t-8 border-primary-accent"
          >
            <MxButton class="w-full h-full justify-between" @click="filtersOpen = !filtersOpen">
              {{ translate('label.filterByCategory') }}
              <Icon
                class="text-2xl transform transition-transform"
                :class="{
                  'rotate-180': filtersOpen,
                }"
                name="ChevronDown"
              />
            </MxButton>
          </div>
          <div v-if="filtersOpen" class="flex space-x-8 col-span-2 text-white text-xl">
            <MxChoice
              v-for="(option, idx) in categoriesOptions"
              :key="idx"
              type="checkbox"
              :id="`category-${idx}`"
              :label="option.label"
              :checked="selectedCategories.includes(option.value)"
              :value="option.value"
              @change="() => toggleCategoryFilter(option.value)"
            />
          </div>
        </form>

        <ContentCollectionColumns
          class="text-white"
          :items="storiesFormatted"
          item-style="simple"
          :collection-heading="false"
        />
        <div class="flex justify-center space-x-8 mt-10">
          <MxButton v-if="showLoadMore" size="large" class="text-xl" @click="loadMore">
            {{ translate('action.loadMore') }}
            <Icon class="text-xl" name="ArrowRight" width="1.5" />
          </MxButton>

          <MxButton @click="scrollToTop">
            <Icon class="text-4xl" name="ArrowUp" />
          </MxButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core';
import { useStoryblokApi } from '@storyblok/vue';
import { RELATIONS_FOR_COLLECTIONS } from '~/composables/relations';
import type { CollectionItemType } from '~/types/ContentCollection';
import type { ListingResults } from '~/types/common';
import type { ArticleListingProps } from '~/types/ArticleListing';
import { trimStoryBlocks } from '~/utils/optimizeStoryData';

const runtimeConfig = useRuntimeConfig();
const { publishState, storyBlokApiKey } = runtimeConfig.public;
const language = useState('language');
const translate = useTranslation();
const router = useRouter();
const route = useRoute();

const props = defineProps<{ blok: ArticleListingProps }>();
const { categories } = props.blok;

const perPage = 18;
const currentPage = ref(1);

const searchTerm = ref(route.query?.search as string);
const filtersOpen = ref<boolean>(false);

const selectedCategories = ref<string[]>(
  route.query?.categories ? String(route.query?.categories).split(',') : [],
);

const storyblokApi = useStoryblokApi();

const sortBy = props.blok.sortBy || 'first_published_at';
const sortDirection = props.blok.sortDirection || 'desc';

const fetchArticles = async (page = 1) => {
  const q = {
    version: publishState as 'draft' | 'published',
    token: storyBlokApiKey,
    language: language.value as string,
    resolve_relations: RELATIONS_FOR_COLLECTIONS,
    content_type: 'Article',
    starts_with: language.value as string,
    cv: new Date().getTime(),
    sort_by: `${sortBy}:${sortDirection}`,
    per_page: perPage,
    page,
  };

  if (searchTerm.value?.length) {
    Object.assign(q, { search_term: searchTerm.value });
  }

  const filter_query = {};
  if (selectedCategories.value?.length) {
    Object.assign(filter_query, {
      categories: {
        any_in_array: selectedCategories.value.join(','),
      },
    });
  } else if (categories?.length) {
    Object.assign(filter_query, {
      categories: {
        any_in_array: categories.map((story) => story.uuid).join(','),
      },
    });
  }

  if (Object.keys(filter_query).length) {
    Object.assign(q, { filter_query });
  }

  try {
    const response = await storyblokApi.get(`cdn/stories/`, q);
    currentPage.value = page;

    return {
      // note: incomplete type returned by storyblok
      total: parseInt((response?.headers as any)?.total || 0),
      stories: (response?.data?.stories || []).map(trimStoryBlocks),
    };
  } catch (error) {
    console.error(error);
    return {
      total: 0,
      stories: [],
    };
  }
};

const categoriesOptions = computed(() => {
  return (categories || []).map((story) => ({
    value: story.uuid,
    label: story.content?.name || story.name,
  }));
});

const asyncDataKey = computed(() => {
  const parts = ['articles', language.value, 'perPage', perPage, sortBy, sortDirection];
  if (categories?.length) {
    parts.push(['categories', ...categories.map((story) => story.uuid)].join('-'));
  } else if (selectedCategories.value?.length) {
    parts.push(['categories', ...selectedCategories.value].join('-'));
  }

  return parts.join('_');
});

const results = ref<ListingResults>(
  (
    await useAsyncData(asyncDataKey.value, async () => {
      return await fetchArticles();
    })
  ).data.value as unknown as ListingResults,
);

const storiesFormatted = computed<CollectionItemType[]>(() => {
  return (results.value?.stories || []).map((story) => {
    return {
      _uid: story.uuid,
      storyToShow: story,
      customHeading: story.content.name || story.name,
    };
  });
});

const toggleCategoryFilter = (uuid: string) => {
  const existingIndex = selectedCategories.value.indexOf(uuid);
  if (existingIndex !== -1) {
    selectedCategories.value.splice(existingIndex, 1);
  } else {
    selectedCategories.value.push(uuid);
  }
  onSubmit();
};

const totalPages = computed(() => Math.ceil((results.value?.total || 0) / perPage));

const showLoadMore = computed(() => totalPages.value && currentPage.value !== totalPages.value);

const loadMore = async () => {
  if (currentPage.value === totalPages.value) return;
  const response = await fetchArticles(currentPage.value + 1);

  results.value.total = response.total;
  results.value.stories = [...results.value.stories, ...response.stories];
};

const onSubmit = async () => {
  router.push({
    query: {
      ...(route.query.value as any),
      search: searchTerm.value ? encodeURIComponent(searchTerm.value) : undefined,
      categories: selectedCategories.value.length ? selectedCategories.value.join(',') : undefined,
    },
  });
  results.value = await fetchArticles();
};

const { y } = useWindowScroll();

const scrollToTop = () => {
  y.value = 0;
};
</script>
