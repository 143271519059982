<template>
  <div class="carousel-container">
    <Splide ref="splideRef" :has-track="false" :options="configuration" @mounted="onSplideMount">
      <SplideTrack>
        <slot></slot>
      </SplideTrack>

      <slot name="controls">
        <template v-if="arrowPosition === 'bottom'">
          <div class="splide__arrows flex mt-12 lg:mt-15 gap-x-3 justify-center text-primary">
            <slot name="prevArrow">
              <button
                class="splide__arrow splide__arrow--prev inline-flex shrink text-6xl lg:text-4xl"
              >
                <Icon name="ChevronLeft" />
              </button>
            </slot>

            <slot name="pagination">
              <ul
                class="splide__pagination gap-x-3 lg:gap-x-6 gap-y-3 lg:gap-y-6 text-2xl lg:text-base"
              />
            </slot>

            <slot name="nextArrow">
              <button
                class="splide__arrow splide__arrow--next inline-flex shrink text-6xl lg:text-4xl"
              >
                <Icon name="ChevronRight" />
              </button>
            </slot>
          </div>
        </template>

        <template v-else-if="arrowPosition === 'side'">
          <slot name="arrows">
            <div
              class="splide__arrows w-full h-full absolute top-0 left-0 pointer-events-none text-primary"
            >
              <slot name="prevArrow">
                <button
                  :class="[
                    'splide__arrow splide__arrow--prev left-0 absolute top-1/2 transform -translate-y-1/2 pointer-events-auto leading-none',
                    `${arrowSize}`,
                  ]"
                >
                  <Icon name="ChevronLeft" />
                </button>
              </slot>

              <slot name="nextArrow">
                <button
                  :class="[
                    'splide__arrow splide__arrow--next right-0 absolute top-1/2 transform -translate-y-1/2 pointer-events-auto leading-none',
                    `${arrowSize}`,
                  ]"
                >
                  <Icon name="ChevronRight" />
                </button>
              </slot>
            </div>
          </slot>

          <slot name="pagination">
            <ul class="splide__pagination gap-x-3 lg:gap-x-6 text-2xl lg:text-base"></ul>
          </slot>
        </template>

        <template v-else-if="arrowPosition === 'top'">
          <slot name="arrows">
            <div
              class="splide__arrows w-full h-full absolute top-0 left-0 pointer-events-none text-primary"
            >
              <slot name="prevArrow">
                <button
                  :class="[
                    'max-lg:hidden flex justify-center items-center splide__arrow splide__arrow--prev right-20 absolute -top-10 transform -translate-y-0 pointer-events-auto leading-none rounded-2xl px-6 py-1 border-1 bg-black border-primary text-primary hover:bg-primary hover:text-black',
                    `${arrowSize}`,
                  ]"
                >
                  <Icon name="ChevronLeft" class="text-sm" />
                </button>
              </slot>

              <slot name="nextArrow">
                <button
                  :class="[
                    'max-lg:hidden flex justify-center items-center splide__arrow splide__arrow--next right-0 absolute -top-10 transform -translate-y-0 pointer-events-auto leading-none bg-primary rounded-2xl px-6 py-1 border-1 border-primary text-black hover:bg-black hover:text-primary',
                    `${arrowSize}`,
                  ]"
                >
                  <Icon name="ChevronRight" class="text-sm" />
                </button>
              </slot>
            </div>
          </slot>

          <slot name="pagination">
            <ul class="splide__pagination gap-x-3 lg:gap-x-6 text-2xl lg:text-base"></ul>
          </slot>
        </template>
      </slot>
    </Splide>
  </div>
</template>

<script setup lang="ts">
import type { Options } from '@splidejs/vue-splide';
import { Splide, SplideTrack } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/core';
import { ref, onMounted, nextTick } from 'vue';

const emit = defineEmits(['splide:mounted']);

const {
  items,
  arrowPosition = 'bottom',
  arrowSize = 'text-4xl',
} = defineProps<{
  configuration: Options;
  items?: any[];
  arrowPosition?: 'bottom' | 'side' | 'top';
  arrowSize?: string;
}>();

const splideRef = ref(null);

onMounted(() => {
  nextTick(() => {
    if (splideRef.value) {
      const splideInstance = splideRef.value.splide;
      emit('splide:mounted', splideInstance);
    }
  });
});
</script>

<style>
.splide__pagination {
  display: flex;
  justify-content: flex-start;
}

.splide__pagination li {
  line-height: 0;
}

.splide__pagination__page {
  width: 1rem;
  height: 1rem;
  border: 2px solid #3a3738;
  background-color: theme('colors.light');
  opacity: 0.35;

  &.is-active {
    background-color: theme('colors.primary');
    border: 2px solid theme('colors.primary');
    opacity: 1;
  }
}

.splide__arrow {
  align-items: center;

  &[disabled] {
    opacity: 0.35;
  }
}

.splide:not(.is-overflow) .splide__arrow {
  display: none;
}
</style>
