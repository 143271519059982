<template>
  <CldVideoPlayer
    :id="id"
    :width="asset?.width"
    :height="asset?.height"
    :src="encodeURI(asset?.public_id)"
    :auto-play="autoplay ? 'on-scroll' : 'never'"
    :loop="loop || autoplay"
    :muted="autoplay"
    :on-pause="pause"
    :on-play="play"
    :controls="!hideControls"
    :logo="false"
    :transformation="transformation"
    :class="wrapperClass"
    :class-name="playerClass"
    v-element-visibility="onVideoVisibility"
    v-set-poster="posterUrl"
  />
</template>

<script setup lang="ts">
import { vElementVisibility } from '@vueuse/components';
import { computed, onUnmounted, ref } from 'vue';
import type { Directive } from 'vue';
import { getTransformations } from '@cloudinary-util/util';
import useUtils from '~/composables/useUtils';

const props = defineProps<{
  asset: { filename: string; public_id: string; width: number; height: number };
  autoplay?: boolean;
  loop?: boolean;
  hideControls?: boolean;
  wrapperClass?: string;
  playerClass?: string;
  poster?: string;
  transform?: Array<Record<string, any>>;
}>();

const videoReference = ref<{ videoRef: HTMLVideoElement; playerRef: any } | null>(null);
const isPlaying = ref(props.autoplay);

const posterUrl = computed(() =>
  props.poster ? `https://res.cloudinary.com/fib/image/upload/v1726828959/${props.poster}` : null,
);

// Note: this prevents hydration missmatch between server & client render
const { slugify } = useUtils();
const id = computed(() => slugify(props.asset?.public_id.replaceAll('/', '-')));

const onVideoVisibility = (isVisible: boolean) => {
  if (isVisible && props.autoplay) {
    videoReference.value?.videoRef.play();
  } else {
    videoReference.value?.videoRef.pause();
  }
};

onUnmounted(() => {
  videoReference.value?.videoRef.pause();
});

const play = (): void => {
  isPlaying.value = true;
  videoReference.value?.videoRef.play();
};

const pause = (): void => {
  isPlaying.value = false;
  videoReference.value?.videoRef.pause();
};

const transformation = computed(() => [
  ...getTransformations(props.asset.filename),
  ...(props.transform || []),
]);

// Note: poster has to be set this way as it's being set on the wrong element by setting :poster on the cldvideoplayer element
const vSetPoster: Directive = {
  mounted: (el, binding) => {
    const setPoster = () => {
      const pictureElement = el.querySelector('picture.vjs-poster');
      if (pictureElement) {
        const imgElement = pictureElement.querySelector('img');
        if (imgElement && binding.value) {
          imgElement.setAttribute('src', binding.value);
          imgElement.setAttribute('alt', 'Video Poster');
        }
      }
    };

    setPoster();

    // Set up a MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(setPoster);
    observer.observe(el, { childList: true, subtree: true });

    // Clean up the observer when the component is unmounted
    el._posterObserver = observer;
  },
  beforeUnmount: (el) => {
    if (el._posterObserver) {
      el._posterObserver.disconnect();
    }
  },
};
</script>

<style>
.vjs-tech {
  object-fit: cover;
}
.video-player-adaptive {
  .vjs-fluid {
    padding: 0;
    height: 100%;
    width: 100%;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
