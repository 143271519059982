<template>
  <div class="mt-4 relative w-full" :class="{ 'hidden ': hideScrollIndicator }">
    <div class="w-full rounded scroll-track" :class="scrollTrackClasses">
      <div
        ref="scrollIndicator"
        class="absolute top-0 rounded cursor-grab active:cursor-grabbing"
        :class="scrollBarClasses"
        :style="{ width: `${scrollProgress}%`, left: `${scrollPosition}%` }"
        @mousedown="startDrag"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, nextTick, watch } from 'vue';

const {
  container,
  scrollBarClasses = 'bg-primary h-0.3',
  scrollTrackClasses = 'bg-white/25 h-0.3',
} = defineProps<{
  container: HTMLElement | null;
  scrollBarClasses?: string;
  scrollTrackClasses?: string;
}>();

const scrollIndicator = ref<HTMLElement | null>(null);
const scrollProgress = ref(10);
const scrollPosition = ref(0);
const isDragging = ref(false);
const startX = ref(0);
const startLeft = ref(0);

const scrollContainer = ref<HTMLElement | null>(null);

watch(
  () => container,
  (newContainer) => {
    if (scrollContainer.value) {
      scrollContainer.value.removeEventListener('scroll', updateScrollIndicator);
    }

    scrollContainer.value = newContainer;

    if (newContainer) {
      newContainer.addEventListener('scroll', updateScrollIndicator);
      nextTick(updateScrollIndicator);
    }
  },
  { immediate: true },
);

const hideScrollIndicator = ref<boolean>();

const updateScrollIndicator = () => {
  const container = scrollContainer.value;
  if (!container) return;

  const scrollWidth = container.scrollWidth;
  const clientWidth = container.clientWidth;
  const scrollLeft = container.scrollLeft;

  if (scrollWidth > clientWidth) {
    scrollProgress.value = (clientWidth / scrollWidth) * 100;
    scrollPosition.value =
      (scrollLeft / (scrollWidth - clientWidth)) * (100 - scrollProgress.value);
  } else {
    scrollProgress.value = 100;
    scrollPosition.value = 0;
    hideScrollIndicator.value = true;
  }
};

const startDrag = (e: MouseEvent) => {
  if (!scrollContainer.value) return;

  isDragging.value = true;
  startX.value = e.clientX;
  startLeft.value = scrollPosition.value;

  window.addEventListener('mousemove', onDrag);
  window.addEventListener('mouseup', stopDrag);
};

const onDrag = (e: MouseEvent) => {
  if (!isDragging.value || !scrollContainer.value) return;

  const deltaX = e.clientX - startX.value;
  const container = scrollContainer.value;

  const trackElement = scrollIndicator.value?.parentElement;
  if (!trackElement) return;

  const trackWidth = trackElement.clientWidth;
  if (trackWidth === 0) return;

  let newPosition = startLeft.value + (deltaX / trackWidth) * 100;

  newPosition = Math.max(0, Math.min(newPosition, 100 - scrollProgress.value));
  scrollPosition.value = newPosition;

  const scrollWidth = container.scrollWidth;
  const clientWidth = container.clientWidth;
  const maxScroll = scrollWidth - clientWidth;
  container.scrollLeft = (newPosition / (100 - scrollProgress.value)) * maxScroll;
};

const stopDrag = () => {
  isDragging.value = false;
  window.removeEventListener('mousemove', onDrag);
  window.removeEventListener('mouseup', stopDrag);
};

onMounted(() => {
  nextTick(() => {
    if (scrollContainer.value && !isDragging.value) {
      updateScrollIndicator();
    }
  });
});

onUnmounted(() => {
  if (scrollContainer.value) {
    scrollContainer.value.removeEventListener('scroll', updateScrollIndicator);
  }
  window.removeEventListener('mousemove', onDrag);
  window.removeEventListener('mouseup', stopDrag);
});
</script>
