<template>
  <iframe
    class="w-full aspect-video"
    :src="embedUrl"
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    :title="caption"
    loading="lazy"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import getVideoId from 'get-video-id';

const props = defineProps<{
  url: string;
  caption?: string;
}>();

const embedUrl = computed(() => {
  const { id, service } = getVideoId(props.url);
  if (!id) {
    return '';
  }

  switch (service) {
    case 'youtube':
      return `https://www.youtube-nocookie.com/embed/${id}`;
    case 'vimeo':
      return `https://player.vimeo.com/video/${id}`;
    default:
      return '';
  }
});
</script>
