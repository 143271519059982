<template>
  <textarea
    v-if="type === 'textarea'"
    class="field field--area"
    :id="id"
    :name="name"
    :placeholder="placeholder"
    v-model="value"
  />

  <input
    v-else
    class="field"
    :type="type"
    :id="id"
    :name="name"
    :placeholder="placeholder"
    v-model="value"
  />
</template>

<script setup lang="ts">
const {
  type = 'text',
  id,
  name,
  placeholder,
} = defineProps<{
  type?: 'text' | 'number' | 'email' | 'password' | 'tel' | 'url' | 'textarea' | 'file' | 'hidden';
  id?: string;
  name?: string;
  placeholder?: string;
}>();

const value = defineModel();
</script>

<style scoped>
.field {
  padding: 20px;

  border-radius: 30px;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.25);
  font-size: 1.25rem;
  line-height: 1;
}
.field--area {
  resize: none;
}
</style>
