import { defineNuxtPlugin } from '#app';

/** Maverix-ui bloks */
import Article from '~/storyblok/Article.vue';
import Embed from '~/storyblok/Embed.vue';
import Hero from '~/storyblok/Hero.vue';
import Image from '~/storyblok/Image.vue';
import MediaBlock from '~/storyblok/MediaBlock.vue';
import Page from '~/storyblok/Page.vue';
import Video from '~/storyblok/Video.vue';

import Announcement from '~/storyblok/Announcement.vue';
import ContentCollection from '~/storyblok/ContentCollection.vue';
import Contacts from '~/storyblok/Contacts.vue';
import InfoBlock from '~/storyblok/InfoBlock.vue';
import MapEmbed from '~/storyblok/MapEmbed.vue';
import NewsletterSignup from '~/storyblok/NewsletterSignup.vue';
import RichText from '~/storyblok/RichText.vue';
import SearchResultsPage from '~/storyblok/SearchResultsPage.vue';
import ArticleInfo from '~/storyblok/ArticleInfo.vue';
import ArticleListing from '~/storyblok/ArticleListing.vue';
import QuoteBlock from '~/storyblok/QuoteBlock.vue';
import SideBySide from '~/storyblok/SideBySide.vue';
import Production from '~/storyblok/Production.vue';
import ProductionDetails from '~/storyblok/ProductionDetails.vue';
import ProfilePage from '~/storyblok/ProfilePage.vue';
import FAQ from '~/storyblok/FAQ.vue';
import FeaturedText from '~/storyblok/FeaturedText.vue';
import RichTextMedia from '~/storyblok/RichTextMedia.vue';
import ProgrammeSchedule from '~/storyblok/ProgrammeSchedule.vue';
import RichTextHighlight from '~/storyblok/RichTextHighlight.vue';
import Separator from '~/storyblok/Separator.vue';
import LeadBlock from '~/storyblok/LeadBlock.vue';
import PublicFundingBlock from '~/storyblok/PublicFundingBlock.vue';
import Venue from '~/storyblok/Venue.vue';
import VenueDetails from '~/storyblok/VenueDetails.vue';
import VenueListing from '~/storyblok/VenueListing.vue';
import DefaultButton from '~/storyblok/DefaultButton.vue';
import Scrollytelling from '~/storyblok/Scrollytelling.vue';
import AlgoliaSearch from '~/storyblok/AlgoliaSearch.vue';

const MapBlock = defineAsyncComponent(() => import('~/storyblok/MapBlock.vue'));

export default defineNuxtPlugin((nuxtApp) => {
  // Register the Page component globally
  nuxtApp.vueApp.component('Article', Article);
  nuxtApp.vueApp.component('Embed', Embed);
  nuxtApp.vueApp.component('FeaturedText', FeaturedText);
  nuxtApp.vueApp.component('Hero', Hero);
  nuxtApp.vueApp.component('Image', Image);
  nuxtApp.vueApp.component('MediaBlock', MediaBlock);
  nuxtApp.vueApp.component('Page', Page);
  nuxtApp.vueApp.component('Video', Video);
  nuxtApp.vueApp.component('Announcement', Announcement);
  nuxtApp.vueApp.component('ContentCollection', ContentCollection);
  nuxtApp.vueApp.component('Contacts', Contacts);
  nuxtApp.vueApp.component('InfoBlock', InfoBlock);
  nuxtApp.vueApp.component('MapEmbed', MapEmbed);
  nuxtApp.vueApp.component('NewsletterSignup', NewsletterSignup);
  nuxtApp.vueApp.component('RichText', RichText);
  nuxtApp.vueApp.component('SearchResultsPage', SearchResultsPage);
  nuxtApp.vueApp.component('ArticleInfo', ArticleInfo);
  nuxtApp.vueApp.component('ArticleListing', ArticleListing);
  nuxtApp.vueApp.component('QuoteBlock', QuoteBlock);
  nuxtApp.vueApp.component('SideBySide', SideBySide);
  nuxtApp.vueApp.component('Production', Production);
  nuxtApp.vueApp.component('ProductionDetails', ProductionDetails);
  nuxtApp.vueApp.component('MapBlock', MapBlock);
  nuxtApp.vueApp.component('ProfilePage', ProfilePage);
  nuxtApp.vueApp.component('FAQ', FAQ);
  nuxtApp.vueApp.component('RichTextMedia', RichTextMedia);
  nuxtApp.vueApp.component('RichTextHighlight', RichTextHighlight);
  nuxtApp.vueApp.component('ProgrammeSchedule', ProgrammeSchedule);
  nuxtApp.vueApp.component('Separator', Separator);
  nuxtApp.vueApp.component('LeadBlock', LeadBlock);
  nuxtApp.vueApp.component('PublicFundingBlock', PublicFundingBlock);
  nuxtApp.vueApp.component('Venue', Venue);
  nuxtApp.vueApp.component('VenueDetails', VenueDetails);
  nuxtApp.vueApp.component('VenueListing', VenueListing);
  nuxtApp.vueApp.component('DefaultButton', DefaultButton);
  nuxtApp.vueApp.component('Scrollytelling', Scrollytelling);
  nuxtApp.vueApp.component('AlgoliaSearch', AlgoliaSearch);
});
