// Relations for stories (only when viewing the story)
//  IMPORTANT - DO NOT ADD ContentCollectionItem.storyToShow to relations!!
//  It will destroy performance, trigger multiple invisible httprequests in useStoryblokApi
//  and absolutely bloat the page payload (adding around 1Mb average!)
export const STORY_RELATIONS = [
  'Article.categories',
  'Page.Category',
  'Article.theme',
  'ArticleListing.categories',
  'Contacts.contactList',
  'ContentCollection.ctaLink',
  'Event.SyncProduction',
  'Event.SyncVenue',
  'FAQ.faqList',
  'FAQs.faqs',
  'Page.theme',
  'Production.Categories',
  'Production.SyncVenue',
  'ProductionDetails.eventList',
  'ProductionDetails.venue',
  'SideBySide.story_to_show',
  'Venue.Categories',
  'Venue.facilities',
  'Venue.SyncVenueHalls',
];

// Relations for site config pages
export const APP_CONFIG_RELATIONS = [
  'Footer.certificates',
  'Footer.partners',
  'Footer.publicFunding',
  'Footer.sponsors',
  'Header.news',
  'LOGOS.logo_columns',
];

// Note: loading all the known relations when fetching stories will inadvertently load
// more related information than we actually need. This mostly affects content collections and article listing
// but is in general a bad practice. Aim to specify exactly the relations needed for each request!
export const RELATIONS_FOR_COLLECTIONS = [
  'Event.SyncProduction',
  'Event.SyncVenue',
  'Production.Categories',
  'Production.SyncVenue',
];
