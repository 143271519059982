<template>
  <div :class="classNames[type].blokWrapper">
    <div :class="classNames[type].grid">
      <div :class="classNames[type].gridChild">
        <slot name="top" />

        <div :class="classNames[type].wrapper">
          <FancyHeading
            v-if="heading?.length"
            :lines="heading"
            headingLevel="h1"
            :shrink="shrinkHeading"
          />

          <h1 class="text-4xl lg:text-8xl text-white font-secondary" v-else-if="title">
            {{ title }}
          </h1>
        </div>

        <slot name="bottom" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { HeroBlockProps } from '~/types/HeroBlock';

const { blok } = defineProps<{
  blok: HeroBlockProps;
}>();
const { asset, title, heading, shrinkHeading } = blok;

const type: string = !asset?.filename ? 'noBackground' : 'hasBackground';

const defaultClassNames = {
  blokWrapper: ['flex h-full pb-15 flex-col justify-end lg:justify-end relative z-1000'],
  grid: 'container',
  gridChild: '',
  wrapper: ['mb-10 lg:mb-0'],
  intro: 'font-secondary text-2xl leading-normal',
};

const classNames: { [index: string]: any } = {
  hasBackground: defaultClassNames,
  noBackground: {
    blokWrapper: 'flex flex-col justify-end lg:pt-20',
    grid: defaultClassNames.grid,
    gridChild:
      'col-span-12 items-start flex flex-col text-left md:col-span-10 md:col-start-1 lg:col-span-8 lg:col-start-1',
    wrapper: ['flex flex-col gap-9'],
    title: [
      'font-bell font-medium uppercase text-2xl md:text-5xl leading-none md:text-6xl xl:text-8xl',
    ],
    intro: 'font-secondary text-2xl leading-normal',
  },
};
</script>
