<script setup lang="ts">
import { SplideSlide } from '@splidejs/vue-splide';

const props = defineProps<{ class?: string | object | any[] }>();
</script>

<template>
  <SplideSlide class="max-h-full" :class="props.class">
    <slot />
  </SplideSlide>
</template>
