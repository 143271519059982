<template>
  <div v-editable="blok">
    <!-- hero -->
    <section class="bg-dark text-light">
      <div class="container grid grid-cols-12 mx-auto pt-32 md:pt-[240px] pb-16 md:pb-8 gap-4">
        <h1
          v-if="blok?.hero_title"
          class="col-start-1 col-span-12 font-secondary text-5xl leading-tight md:text-6xl lg:text-7xl"
        >
          {{ blok.hero_title }}
        </h1>
        <Search
          :hide-label="true"
          :compact="true"
          class="col-start-1 col-span-12 md:col-span-6 lg:col-span-5 2xl:col-span-4 w-full"
        />
      </div>
    </section>

    <!-- summary and results -->
    <section class="bg-light-white">
      <!-- summary -->
      <div class="container mx-auto pt-2 pb-3">
        <div v-if="isLoadingSearchResults" class="space-y-2">
          <div class="h-7 mb-4 w-[70%] bg-gray-300 rounded"></div>
          <div class="h-12 w-[90%] bg-gray-200 rounded"></div>
        </div>
        <h2
          v-else
          class="font-primary text-Theme/Dark text-2xl md:text-2xl font-medium lg:text-4xl py-2"
          v-html="getSearchResultsSummaryText()"
        ></h2>
        <p class="text-sm lg:text-lg">{{ blok?.filter_label }}</p>
      </div>

      <!-- loading state -->
      <div
        v-if="isLoadingSearchResults"
        class="space-y-10 container mx-auto pb-0 md:pb-24 lg:pb-28"
      >
        <div v-for="n in Array.from(Array(perPage).keys())" :key="`loading-${n}`" class="w-full">
          <div class="h-7 mb-4 w-[70%] bg-gray-300 rounded"></div>
          <div class="h-12 w-[90%] bg-gray-200 rounded"></div>
        </div>
      </div>

      <!-- results state -->
      <div v-else class="container mx-auto pb-0 md:pb-24 lg:pb-28">
        <nav
          class="pb-4 md:pb-2 text-lg col-start-1 md:col-start-2 col-span-12 md:col-span-10 mb-4 overflow-hidden max-md:overflow-x-scroll"
        >
          <ul class="flex flex-row gap-3">
            <li
              v-for="(pageType, index) in pageTypes.filter((page) => getCountByType(page) > 0)"
              class="flex flex-row gap-3"
            >
              <button
                :key="pageType"
                @click="handlePageTypeFilter(pageType)"
                class="py-2 px-4 text-md rounded-full w-full flex flex-row gap-1"
                :class="currentPageType === pageType ? 'bg-primary' : 'bg-gray/30'"
              >
                {{ translate(`action.${pageType.toLowerCase()}`) }}
                <span class="">({{ getCountByType(pageType) }})</span>
              </button>
              <div v-if="index === 0" class="h-full w-[2px] bg-gray/20"></div>
            </li>
          </ul>
        </nav>
        <div v-if="displayResults" class="col-start-1 col-span-12 md:col-start-2 md:col-span-11">
          <NuxtLink
            v-for="hit in displayResults.hits.slice(0, resultsToShow)"
            :key="hit.id"
            :to="parseStoryblokFullSlug(`${hit.slug}`)"
          >
            <div
              class="flex md:flex-row flex-col py-4 md:py-3 md:px-0 gap-1 md:gap-4 border-b-2 border-primary-accent cursor-pointer transition-all duration-300 ease-in"
            >
              <NuxtImg
                v-if="!hit.thumbnail.filename && blok.placeholder?.filename"
                :src="blok?.placeholder.filename"
                :alt="hit.thumbnail.title"
                loading="lazy"
                class="max-w-[full] min-w-[full] md:max-w-[200px] md:min-w-[200px] w-full md:h-[110px] h-[177px] object-cover my-auto"
              />
              <MxAssetRenderer
                v-if="hit.thumbnail.filename"
                class="media overflow-hidden max-w-[full] min-w-[full] md:max-w-[200px] md:min-w-[200px] w-full md:h-[110px] h-[177px] object-cover my-auto"
                :asset="hit.thumbnail"
                fit="fill"
                :aspect-ratio="'3:2'"
                :video-config="videoConfig"
                image-class="h-full w-full"
                :imageWidth="800"
              />
              <div class="flex flex-col-reverse md:flex-col p-1 md:p-2 justify-between">
                <div>
                  <p class="font-medium text-lg md:text-2xl">
                    {{ hit.name }}
                  </p>
                  <p class="text-md md:text-lg">{{ sliceString(hit.excerpt, 160) }}</p>
                </div>
                <div class="flex flex-row gap-2 my-1 md:my-2">
                  <p
                    v-if="pageTypes.includes(hit.type)"
                    class="bg-primary py-1.5 px-3 rounded-full text-sm"
                  >
                    {{ translate(`action.${hit.type.toLowerCase()}`) }}
                  </p>
                </div>
              </div>
            </div>
          </NuxtLink>
          <div v-if="isLoadingMore" class="text-center py-4">
            <div
              class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-middle"
            ></div>
          </div>

          <div v-if="hasMoreResultsToShow" ref="loadMoreTrigger" class="h-10 w-full my-4"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { algoliasearch } from 'algoliasearch';
import type { AlgoliaHit } from '~/types/Algolia';

const runtimeConfig = useRuntimeConfig();
const client = algoliasearch(
  runtimeConfig.public.algoliaAppId,
  runtimeConfig.public.algoliaAdminKey,
);
const language = useState('language');
const translate = useTranslation();

const { blok } = defineProps({ blok: Object });

// Disabled search middleware as it seems to break the static page build
definePageMeta({
  middleware: ['search'],
});

const route = useRoute();

const videoConfig = {
  wrapperClass: '!absolute top-0 left-0 media h-full min-w-full',
  playerClass: '!h-full w-full z-0 object-cover object-center',
  autoplay: true,
  muted: true,
  loop: true,
  hideControls: true,
  loadOnVisible: true,
  nativePlayer: true, // use native html video player with auto-adjusting source
  transformations: 'eo_7', // note: limit length to 7 seconds
};

const searchableContentTypes = computed(() => blok?.searchable_content_types || []);

const currentPageType = ref('All');
const selectedCategory = ref('');
const dateFilter = ref('');
const perPage = 20;

// Lazy loading variables
const observer = ref<IntersectionObserver | null>(null);
const loadMoreTrigger = ref(null);
const isLoadingSearchResults: Ref<Boolean> = ref(false);
const isLoadingMore = ref(false);
const resultsToShow = ref(perPage);

const searchResults = ref<any>(null);

const displayResults = computed(() => {
  if (!searchResults.value) return null;

  if (currentPageType.value === 'All') {
    return searchResults.value;
  }

  return {
    ...searchResults.value,
    hits: searchResults.value.hits
      .filter((hit: AlgoliaHit) => hit.type === currentPageType.value)
      .sort((a: AlgoliaHit, b: AlgoliaHit) => {
        if (a.type === 'Production' && b.type !== 'Production') {
          return -1;
        }
        if (a.type !== 'Production' && b.type === 'Production') {
          return 1;
        }
        return 0;
      }),
  };
});

const hasMoreResultsToShow = computed(() => {
  if (!displayResults.value) return false;
  return resultsToShow.value < displayResults.value.hits.length;
});

watch([currentPageType], () => {
  resultsToShow.value = perPage;
  nextTick(() => {
    setupIntersectionObserver();
  });
});

watch([displayResults], () => {
  nextTick(() => {
    setupIntersectionObserver();
  });
});

const setupIntersectionObserver = () => {
  if (!process.client) return;

  if (observer.value) {
    observer.value.disconnect();
  }

  // Only set up observer if there are more results to load
  if (hasMoreResultsToShow.value) {
    observer.value = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoadingMore.value) {
          loadMore();
        }
      },
      {
        rootMargin: '100px',
        threshold: 0.1,
      },
    );

    if (loadMoreTrigger.value) {
      observer.value.observe(loadMoreTrigger.value);
    }
  }
};

const loadMore = async () => {
  if (isLoadingMore.value || !hasMoreResultsToShow.value) return;

  isLoadingMore.value = true;

  setTimeout(() => {
    resultsToShow.value += perPage;
    isLoadingMore.value = false;

    // Re-check if we need to observe again
    nextTick(() => {
      setupIntersectionObserver();
    });
  }, 500);
};

const performSearch = async () => {
  try {
    const { results } = await client.search({
      requests: [
        {
          indexName: 'storyblok',
          query: getSearchTerm(),
          filters: `lang:${language.value}`,
          hitsPerPage: getSearchTerm() === '' ? 0 : 1000,
          page: 0,
        },
      ],
    });
    searchResults.value = results[0];
    isLoadingSearchResults.value = false;
  } catch (error) {
    console.error('Search error:', error);
    searchResults.value = null;
  }
};

const handlePageTypeFilter = (pageType: string) => {
  currentPageType.value = pageType;
};

const sliceString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + '...';
};

onMounted(async () => {
  await performSearch();
});

const getCountByType = (type: string) => {
  if (!searchResults.value?.hits) return 0;

  if (type === 'All') {
    return searchResults.value.hits.length;
  }

  return searchResults.value.hits.filter((hit: AlgoliaHit) => hit.type === type).length;
};

const pageTypes = ['All', 'Production', 'Article', 'Venue', 'Photo'];

const getSearchTerm = (): string | undefined => {
  const matchingSearchTerm: string | undefined = route.query['q'] as string | undefined;
  if (matchingSearchTerm) {
    const modifiedMatchingSearchTerm: string | undefined = matchingSearchTerm
      ?.toString()
      ?.replace(/,/g, ', ');
    return modifiedMatchingSearchTerm;
  }
  /*-- we handle this in order to display in the storyblok preview --*/
  return '';
};

const hasSearchTerm = (): boolean => {
  return getSearchTerm() !== undefined;
};

const getSearchResultsSummaryText = (): string => {
  if (isLoadingSearchResults.value) {
    return '';
  } else {
    /*-- we need an extra unwrap here because we are needing to consider even an empty ?q= value as a searchTerm. --*/
    return hasSearchTerm() && getSearchTerm() !== ''
      ? getWithSearchTermResultsSummaryText()
      : getWithoutSearchTermResultsSummaryText();
  }
};

const getNumberOfResultsPlaceholder = (): string => {
  return '<number-of-results>';
};

const getWithSearchTermResultsSummaryText = (): string => {
  const NUMBER_OF_RESULTS_PLACEHOLDER: string = getNumberOfResultsPlaceholder();
  const SEARCH_TERM_PLACEHOLDER: string = '<search-term>';
  const textWithNumber = blok?.search_results_summary_text_search_term_found.replaceAll(
    NUMBER_OF_RESULTS_PLACEHOLDER,
    `${displayResults?.value?.hits.length || 0}`,
  );
  return textWithNumber.replaceAll(
    SEARCH_TERM_PLACEHOLDER,
    `<span class="italic">"${getSearchTerm()}"</span>`,
  );
};

const getWithoutSearchTermResultsSummaryText = (): string => {
  const NUMBER_OF_RESULTS_PLACEHOLDER: string = getNumberOfResultsPlaceholder();
  return blok?.search_results_summary_text_no_search_term_found.replaceAll(
    NUMBER_OF_RESULTS_PLACEHOLDER,
    `${displayResults?.value?.hits.length || 0}`,
  );
};
</script>
<style>
nav {
  -webkit-overflow-scrolling: touch;
  /* For Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 4px; /* For horizontal scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: rgb(255, 231, 231);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e37ab2;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #e37ab2;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #e37ab2 lightgray;
}
</style>
