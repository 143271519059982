<template>
  <div class="scrollbar-container">
    <div class="scrollbar-track overflow-hidden">
      <div
        class="scrollbar-thumb"
        :style="{
          width: `${thumbWidth}%`,
          transform: `translateX(${thumbPosition}%)`,
        }"
      ></div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps({
  mainSplide: {
    type: Object,
    required: true,
  },
  totalItems: {
    type: Number,
    required: true,
  },
  visibleItems: {
    type: Number,
    default: 1,
  },
});

const visibleItems = computed(() => props.visibleItems - 0.3);

// Calculate thumb width as percentage of the track
const thumbWidth = computed(() => {
  if (props.totalItems <= props.visibleItems) return 100;
  return Math.min(100, (visibleItems.value / props.totalItems) * 100);
});

// Calculate thumb position based on current index
const thumbPosition = ref(0);

// Function to update the thumb position
// Correct thumb position calculation
const updateThumbPosition = () => {
  if (!props.mainSplide || props.totalItems <= visibleItems.value) {
    thumbPosition.value = 0;
    return;
  }

  // Get the current index and the last possible index
  const currentIndex = props.mainSplide.index;
  const lastIndex = props.mainSplide.Components.Controller.getEnd();

  // Avoid division by zero
  if (lastIndex === 0) {
    thumbPosition.value = 0;
    return;
  }

  // Calculate the progress (how far we've scrolled)
  const progress = currentIndex / lastIndex;

  // Calculate the available space for the scrollbar-thumb to move
  const availableSpace = (thumbWidth.value / lastIndex) * 100 - thumbWidth.value;

  // Ensure the thumb moves within the correct range
  thumbPosition.value = (100 / visibleItems.value) * currentIndex;

  console.log('Updated thumb position:', {
    currentIndex,
    lastIndex,
    progress,
    availableSpace,
    thumbWidth: thumbWidth.value,
    thumbPosition: thumbPosition.value,
  });
};

// Set up direct event listeners on the Splide instance
onMounted(() => {
  console.log('SplideScrollbar mounted, mainSplide:', props.mainSplide);

  if (props.mainSplide) {
    // Register event listeners on the Splide instance
    props.mainSplide.on('move', updateThumbPosition);
    props.mainSplide.on('moved', updateThumbPosition);
    props.mainSplide.on('active', updateThumbPosition);
    props.mainSplide.on('refresh', updateThumbPosition);

    // Initial position update
    setTimeout(() => {
      updateThumbPosition();
    }, 100); // Short delay to ensure Splide is fully initialized
  }
});

// Clean up event listeners when unmounting
onBeforeUnmount(() => {
  if (props.mainSplide) {
    props.mainSplide.off('move', updateThumbPosition);
    props.mainSplide.off('moved', updateThumbPosition);
    props.mainSplide.off('active', updateThumbPosition);
    props.mainSplide.off('refresh', updateThumbPosition);
  }
});

// Watch for changes in the Splide index
watch(
  () => props.mainSplide?.index,
  () => {
    updateThumbPosition();
  },
  { immediate: true },
);

// Also watch the totalItems and visibleItems props
watch([() => props.totalItems, () => props.visibleItems], () => {
  updateThumbPosition();
});
</script>
<style scoped>
.scrollbar-container {
  width: 100%;
  padding: 1.5rem 0;
}
.scrollbar-track {
  width: 100%;
  height: 1px;
  background-color: rgba(128, 128, 128, 0.4);
  border-radius: 2px;
  position: relative;
}
.scrollbar-thumb {
  position: absolute;
  height: 1px;
  background-color: theme('colors.primary');
  border-radius: 2px;
  transition: transform 0.3s ease;
}
</style>
