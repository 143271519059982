<template>
  <div
    class="py-8 lg:py-12"
    :class="{
      'bg-dark text-white': background === 'dark',
      'bg-light-white': background === 'light',
    }"
    v-editable="blok"
  >
    <div class="2xl:container relative">
      <form
        class="grid mb-8 max-lg:grid-rows-3 lg:grid-cols-12 gap-y-3 lg:gap-x-12 lg:border-t-8 lg:border-t-primary-accent"
        :class="{
          container: width < 1024,
        }"
        @submit.prevent="searchEvents"
      >
        <div
          class="flex py-6 xs:gap-x-2 lg:col-span-4 grow border-t-8 border-primary-accent lg:border-0"
        >
          <MxField
            class="w-full flex items-center min-w-0 text-xl rounded-full bg-white pl-4 text-black pr-12 shadow-[inset_0_2px_4px_0_rgba(0,0,0,0.1)] focus:outline-none"
            v-model="searchData.term"
            id="eventSearch"
            :placeholder="translate('label.searchArtistEvent')"
          />

          <MxButton
            type="submit"
            :class="'shadow-[inset_10px_0_20px_-20px_rgba(0,0,0,0.5)] rounded-r-full -ml-12'"
          >
            <Icon class="text-2xl" name="Search" />
          </MxButton>
        </div>

        <div class="py-6 grow border-t-8 lg:col-span-4 border-primary-accent lg:border-0 lg:mr-1/5">
          <MxButton class="w-full h-full justify-between" @click="filtersOpen = !filtersOpen">
            {{ translate('label.filterByCategory') }}
            <Icon
              class="text-2xl transform transition-transform"
              :class="{
                'rotate-180': filtersOpen,
              }"
              name="ChevronDown"
            />
          </MxButton>
        </div>

        <div class="py-6 shrink-0 lg:col-span-4 border-t-8 border-primary-accent lg:border-0">
          <MxButton
            class="flex justify-between w-full h-full"
            theme="inverted"
            @click="toggleLayout"
          >
            {{ translate(layout === 'single' ? 'action.fullProgramme' : 'action.byDate') }}
            <Icon class="text-2xl" name="ArrowRight" width="1.5" />
          </MxButton>
        </div>

        <div
          class="grid w-full lg:col-span-12 -mx-4 px-4 grid-cols-8 lg:grid-cols-12 gap-x-6 overflow-hidden transform transition-[height]"
          :style="{
            height: filtersOpen ? `${filtersReference?.scrollHeight}px` : '0',
          }"
          ref="filtersReference"
        >
          <div class="py-5 col-span-8 lg:col-span-10 lg:col-start-2">
            <div class="py-8">
              <p class="text-4xl">{{ translate('heading.filterDates') }}:</p>

              <div class="flex mt-4 flex-wrap gap-x-4 gap-y-6">
                <div v-for="(date, dateIndex) in festivalDates" class="max-w-[60px] grow">
                  <label
                    class="flex flex-col gap-y-1 relative text-center leading-none"
                    :for="`date-${dateIndex}`"
                  >
                    <MxChoice
                      class="!absolute -top-4 -right-2 text-xl"
                      type="checkbox"
                      :name="`dates-${dateIndex}`"
                      :id="`date-${dateIndex}`"
                      :value="formatDateForSB(date)"
                      :checked="searchData.dateFilters.includes(formatDateForSB(date))"
                      :key="date.toISOString()"
                      @change="handleFilterChange('dateFilters', $event)"
                    />

                    <span>{{ formatDate(date.toISOString(), { weekday: 'short' }) }}</span>

                    <span class="border-t-2 border-primary text-5xl font-secondary">{{
                      formatDate(date.toISOString(), { day: '2-digit' })
                    }}</span>

                    <span class="block px-2 py-0.5 bg-primary">{{
                      formatDate(date.toISOString(), { month: 'short' })
                    }}</span>
                  </label>
                </div>
              </div>
            </div>

            <div
              v-for="filterGroup in filterGroups"
              class="py-8 border-t border-black/25 overflow-hidden"
            >
              <p class="text-4xl">{{ filterGroup.heading }}:</p>

              <div class="flex mt-4 -ml-2 flex-col xs:flex-row xs:flex-wrap gap-x-2 gap-y-1">
                <div v-for="option in filterGroup.options" class="filterItem flex gap-x-2 text-xl">
                  <MxChoice
                    type="checkbox"
                    :name="`filters-${filterGroup.heading}`"
                    :id="`filter-${option.value}`"
                    :label="option.label"
                    :value="option.id"
                    :checked="searchData.filters.includes(option.id)"
                    :key="option.id"
                    @change="handleFilterChange('filters', $event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div id="schedule" ref="scheduleReference">
        <client-only v-if="layout === 'single'">
          <div
            class="grid grid-cols-12 gap-x-6"
            :class="{
              container: width < 1024,
            }"
          >
            <p class="lg:hidden col-span-12 text-grey font-medium text-base mb-2">
              {{ scrollLabel }}
            </p>
            <div class="col-span-12">
              <MxCarousel
                v-if="festivalDates?.length"
                class="col-span-12"
                :configuration="{
                  autoWidth: true,
                  gap: '24px',
                  pagination: false,
                  rewind: false,
                  focus: 0,
                  trimSpace: false,
                  isNavigation: true,
                  start: startingSlide,
                }"
                arrow-position="side"
              >
                <MxCarouselSlide v-for="date in festivalDates" :key="date.toISOString()">
                  <button
                    class="date flex flex-col items-center"
                    :class="{
                      'date--current': currentDate.toISOString() === date.toISOString(),
                    }"
                    type="button"
                    @click="fetchEvents([date])"
                  >
                    <span class="text-7xl font-secondary">{{ date.getDate() }}</span>

                    <span class="text-xl uppercase mb-1">{{
                      formatDate(date.toISOString(), { weekday: 'short' })
                    }}</span>
                  </button>
                </MxCarouselSlide>

                <template #prevArrow>
                  <button
                    class="arrowPrev hidden sm:block splide__arrow splide__arrow--prev absolute top-1/2 transform -translate-y-1/2 pointer-events-auto leading-none text-4xl"
                  >
                    <Icon name="ChevronLeft" />
                  </button>
                </template>

                <template #nextArrow>
                  <button
                    class="arrowNext hidden sm:block splide__arrow splide__arrow--next absolute top-1/2 transform -translate-y-1/2 pointer-events-auto leading-none text-4xl"
                  >
                    <Icon name="ChevronRight" />
                  </button>
                </template>
              </MxCarousel>
            </div>
          </div>

          <ol
            v-if="(currentDateEvents?.length || currentDateHighlightedEvents?.length) && !isLoading"
            class="mt-4 grid grid-cols-12 gap-x-3 2xl:gap-x-6"
          >
            <li
              v-for="(event, eventIndex) in currentDateHighlightedEvents"
              :key="event.uuid"
              class="pt-3"
              :class="{
                'col-span-12': event.content.SyncProduction[0].content.priority === 'high',
                'col-span-12 lg:col-span-6':
                  event.content.SyncProduction[0].content.priority === 'medium',
                'col-span-6 lg:col-span-3':
                  event.content.SyncProduction[0].content.priority === 'low' ||
                  !event.content.SyncProduction[0].content.priority,
              }"
            >
              <ContentCollectionItem
                :class="{
                  'mt-8': !eventIndex,
                  itemWidth,
                }"
                :itemStyle="itemStyle"
                :priority="event.content.SyncProduction[0].content.priority"
                figureClass="flex aspect-12/13 flex-col justify-end"
                :content="{
                  storyToShow: event,
                }"
                :collapsible="true"
              />
            </li>

            <li
              v-for="(event, eventIndex) in currentDateEvents.slice(0, amountOfEventsToShow)"
              class="pt-3"
              :class="{
                'col-span-12': event.content.SyncProduction[0].content.priority === 'high',
                'col-span-12 lg:col-span-6':
                  event.content.SyncProduction[0].content.priority === 'medium',
                'col-span-6 lg:col-span-3':
                  event.content.SyncProduction[0].content.priority === 'low' ||
                  event.content.SyncProduction[0].content.priority === 'none' ||
                  !event.content.SyncProduction[0].content.priority,
              }"
              :key="event.uuid"
            >
              <ContentCollectionItem
                class="h-full"
                :itemStyle="itemStyle"
                :priority="event.content.SyncProduction[0].content.priority"
                :figureClass="
                  event.content.SyncProduction[0].content.priority === 'high'
                    ? 'col lg:flex-row aspect-16/9 lg:w-7/12'
                    : event.content.SyncProduction[0].content.priority === 'medium'
                      ? 'flex min-h-[200px] max-lg:w-1/2 lg:aspect-2/1 flex-row lg:flex-col justify-end'
                      : 'flex aspect-12/13 flex-col justify-end'
                "
                :content="{
                  storyToShow: event,
                }"
                :collapsible="true"
              />
            </li>
          </ol>

          <p v-else-if="!isLoading" class="flex py-10 place-content-center text-3xl font-secondary">
            {{ translate('label.noEventsFound') }}
          </p>
        </client-only>

        <template v-else>
          <dl v-if="Object.keys(sortedTimeline).length" class="space-y-12 lg:space-y-16">
            <template v-for="(events, date) in sortedTimeline">
              <div v-if="events?.length" class="grid grid-cols-12 gap-y-4 gap-x-3 lg:gap-6">
                <dt class="max-2xl:ml-4 col-span-12 text-5xl lg:text-6xl font-secondary">
                  {{
                    formatDate(date as string, { month: 'long', weekday: 'long', day: '2-digit' })
                  }}
                </dt>

                <dd v-for="event in events" :key="event.uuid" class="col-span-6 lg:col-span-3">
                  <ContentCollectionItem
                    class="h-full"
                    :itemStyle="itemStyle"
                    figureClass="flex aspect-3/2 lg:aspect-12/13 flex-col justify-end"
                    :content="{ storyToShow: event }"
                    :collapsible="true"
                  />
                </dd>
              </div>
            </template>
          </dl>

          <p v-else-if="!isLoading" class="flex py-10 place-content-center text-3xl font-secondary">
            {{ translate('label.noEventsFound') }}
          </p>
        </template>
      </div>

      <div class="flex mt-12 lg:mt-24 flex-wrap gap-5 w-full max-2xl:container">
        <div
          v-if="isLoading"
          class="flex w-full h-full p-20 absolute inset-0 z-20 items-center justify-center"
          aria-hidden="true"
        >
          <Icon class="sticky bottom-1/2 text-primary text-8xl" name="Spinner" />
        </div>

        <!-- Intersection Observer trigger element for lazy loading-->
        <div v-if="showLoadMore" ref="loadMoreTrigger" class="w-full h-4"></div>

        <div v-if="isLoadingMore" class="w-full flex justify-center items-center py-4">
          <Icon class="text-primary text-4xl animate-spin" name="Spinner" />
        </div>

        <MxButton href="#schedule" layout="outline" class="max-lg:w-1/2" :background="background">
          <Icon name="Return" width="1.5" />
        </MxButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProgrammeScheduleBlock } from '~/types/ProgrammeScheduleBlock';
import type { ISbStoriesParams, ISbStoryData } from 'storyblok-js-client';
import type { EventProps } from '~/types/Production';
import {
  addTimeZoneToSBDate,
  formatDate,
  formatDateForSB,
  areDatesSameDay,
} from '~/utils/formatDate';
import { STORY_RELATIONS } from '~/composables/relations';

const { blok } = defineProps<{
  blok: ProgrammeScheduleBlock;
}>();
const { start, end, showHighlightedOnly, background, scrollLabel } = blok;

const runtimeConfig = useRuntimeConfig();
const language = useState<string>('language');
const translate = useTranslation();
const storyBlokApi = useStoryblokApi();

const { storyBlokApiKey, publishState } = runtimeConfig.public;

type TimelineType = {
  [index: string]: ISbStoryData<EventProps>[];
};
type filterGroupType = {
  heading: string;
  options: {
    id: string;
    label: string;
    value: string;
  }[];
};

const isMounted = ref(false);
const width = ref(1920);

const itemStyle = computed(() => {
  return width.value > 1440 ? 'layered' : 'layered-simple';
});

const sacredTimeline = ref<TimelineType>({});
const searchTimeline = ref<TimelineType>({});
const filterGroups = ref<filterGroupType[]>([]);
const filtersOpen = ref<boolean>(false);
const filtersReference = ref<HTMLDivElement | null>(null);
const scheduleReference = ref<HTMLDivElement | null>(null);

const festivalDates = computed<Date[]>(() => {
  const dates = [];
  const startDate = new Date(start);
  const endDate = new Date(end);

  for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
    dates.push(new Date(date));
  }

  return dates;
});

const scrollContainerDates = ref<HTMLDivElement | null>(null);

// Lazy loading refs
const observer = ref<IntersectionObserver | null>(null);
const loadMoreTrigger = ref(null);

const layout = ref<'single' | 'full' | 'search'>('single');
const isLoading = ref<boolean>(true);
const showWholeDay = ref<boolean>(false);

let today = new Date();

const getCurrentDateToUse = () => {
  // Makes sure the current date update every day during the festival
  let matched = festivalDates.value[0];
  festivalDates.value.forEach((item) => {
    if (areDatesSameDay(today, item)) {
      matched = item;
      return;
    }
  });

  return matched;
};

const currentDate = ref(getCurrentDateToUse());

const getStartingSlide = () => {
  // Focuses the current day in splide to match the vertical time line border visually
  let index = 0;
  let matchedIndex = 0;
  festivalDates.value.forEach((item) => {
    if (areDatesSameDay(today, item)) {
      matchedIndex = index;
      return;
    }
    index++;
  });

  return matchedIndex;
};

const startingSlide = getStartingSlide();

const arrangeEventsByPriority = (events: ISbStoryData<EventProps>[]) => {
  const highPriorityEvents = events.filter(
    (event) => event.content.SyncProduction[0].content.priority === 'high',
  );
  const mediumPriorityEvents = events.filter(
    (event) => event.content.SyncProduction[0].content.priority === 'medium',
  );

  const lowPriorityEvents = events.filter(
    (event) =>
      event.content.SyncProduction[0].content.priority === 'low' ||
      event.content.SyncProduction[0].content.priority === 'none' ||
      !event.content.SyncProduction[0].content.priority,
  );

  return [...highPriorityEvents, ...mediumPriorityEvents, ...lowPriorityEvents];
};

const currentDateHighlightedEvents = computed<ISbStoryData<EventProps>[]>(() => {
  const events = sacredTimeline.value[`${formatDateForSB(currentDate.value)}`];
  const highlightedEvents = events?.filter((eventItem) => eventItem.content.Highlighted);

  return highlightedEvents?.sort((a, b) =>
    a.content.SyncEventStartTime.localeCompare(b.content.SyncEventStartTime),
  );
});

const currentDateEvents = computed<ISbStoryData<EventProps>[]>(() => {
  const events = sacredTimeline.value[`${formatDateForSB(currentDate.value)}`];
  const regularEvents = events?.filter((eventItem) => !eventItem.content.Highlighted);
  const sortRegularEvents = regularEvents?.sort((a, b) =>
    a.content.SyncEventStartTime.localeCompare(b.content.SyncEventStartTime),
  );

  return sortRegularEvents
    ? arrangeEventsByPriority(sortRegularEvents)
    : regularEvents?.sort((a, b) =>
        a.content.SyncEventStartTime.localeCompare(b.content.SyncEventStartTime),
      );
});
const amountOfEventsToShow = computed<number>(() => {
  return showHighlightedOnly && currentDateHighlightedEvents.value?.length && !showWholeDay.value
    ? 0
    : showHighlightedOnly && !currentDateHighlightedEvents.value?.length && !showWholeDay.value
      ? 3
      : currentDateEvents.value?.length;
});

const sortedTimeline = computed<TimelineType>(() => {
  const currentTimeline = layout.value === 'search' ? searchTimeline.value : sacredTimeline.value;

  return Object.keys(currentTimeline)
    .sort((a, b) => a.localeCompare(b))
    .reduce((accumulator: TimelineType, key) => {
      accumulator[key] = currentTimeline[key].sort((a, b) =>
        a.content.SyncEventStartTime.localeCompare(b.content.SyncEventStartTime),
      );

      return accumulator;
    }, {});
});

const fetchEvents = async (dates: Date[], useSearch: boolean = false) => {
  const { term, filters } = searchData;

  showWholeDay.value = false;

  for (const date of dates) {
    const dateToCheck = new Date(date);
    const dateEnd = new Date(date);
    dateToCheck.setDate(date.getDate());
    dateEnd.setDate(date.getDate() + 1);

    if (sacredTimeline.value[`${formatDateForSB(dateToCheck)}`] && !useSearch) {
      currentDate.value = dateToCheck;

      continue;
    }

    isLoading.value = true;

    try {
      const payload: ISbStoriesParams = {
        starts_with: 'data/arrangement',
        filter_query: {
          SyncEventStartTime: {
            gt_date: formatDateForSB(dateToCheck),
          },
          SyncEventEndTime: {
            lt_date: formatDateForSB(dateEnd),
          },
          /**
           * excluding private productions
           */
          SyncPrivateEvent: {
            is: false,
          },
        },
        version: publishState as 'draft' | 'published',
        token: storyBlokApiKey,
        resolve_relations: STORY_RELATIONS,
        language: language.value,
        per_page: 100,
      };

      if (term) {
        payload.search_term = term;
      }
      if (filters.length) {
        payload.filter_query.SyncCategories = {
          any_in_array: filters.join(','),
        };
      }

      const events = await storyBlokApi.getAll('cdn/stories', payload);

      if (useSearch) {
        if (events.length) {
          searchTimeline.value = {
            ...searchTimeline.value,
            [`${formatDateForSB(dateToCheck)}`]: events,
          };
        }
      } else {
        sacredTimeline.value = {
          ...sacredTimeline.value,
          [`${formatDateForSB(dateToCheck)}`]: events,
        };

        currentDate.value = dateToCheck;
      }
    } catch (error) {
      console.error(error);

      return;
    } finally {
      isLoading.value = false;
    }
  }
};

type searchDataType = {
  term: string;
  filters: string[];
  dateFilters: string[];
};
const searchData = reactive<searchDataType>({
  term: '',
  filters: [],
  dateFilters: [],
});

const searchEvents = async () => {
  searchTimeline.value = {};
  layout.value = 'search';

  const filterDates = searchData.dateFilters.map((date) => new Date(date));

  await fetchEvents(filterDates.length ? filterDates : festivalDates.value, true);
};

const toggleLayout = () => {
  searchData.term = '';
  searchData.filters = [];
  searchData.dateFilters = [];
  filtersOpen.value = false;
  layout.value = layout.value === 'single' ? 'full' : 'single';

  if (layout.value === 'full') {
    fetchEvents(festivalDates.value.slice(0, 3));
  }
};

const prepareFilters = async () => {
  const filterData = [
    {
      heading: translate('heading.filterVenue'),
      path: 'data/kategori/arena',
    },
    {
      heading: translate('heading.filterCategories'),
      path: 'data/kategori/arrangement',
    },
  ];

  for (const group of filterData) {
    try {
      const response = await storyBlokApi.getAll('cdn/stories', {
        version: publishState as 'draft' | 'published',
        token: storyBlokApiKey,
        cv: new Date().getTime(),
        starts_with: group.path,
        language: language.value,
        content_type: 'Category',
        per_page: 100,
      });

      const sortedResponse = response.sort((a, b) => {
        const nameA = a.content.name || a.name;
        const nameB = b.content.name || b.name;
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

      filterGroups.value.push({
        heading: group.heading,
        options: sortedResponse.map((item) => {
          return {
            id: item.uuid,
            label: item.content.name || item.name,
            value: item.slug,
          };
        }),
      });
    } catch (error) {
      console.warn(`failed to fetch "${group}"`);
      console.error(error);
    }
  }
};

const handleFilterChange = async (type: 'filters' | 'dateFilters', event: Event) => {
  const targetElement = event.target as HTMLInputElement;
  const indexOfFilter = searchData[type].indexOf(targetElement.value);

  if (indexOfFilter >= 0) {
    searchData[type].splice(indexOfFilter, 1);
  } else {
    searchData[type].push(targetElement.value);
  }

  if (searchData.filters.length || searchData.dateFilters.length) {
    await searchEvents();
  } else {
    layout.value = 'single';
    await fetchEvents([currentDate.value]);
  }

  const scheduleTopPosition = scheduleReference.value?.getBoundingClientRect().top || 0;

  window.scrollTo({
    top: scheduleTopPosition + window.scrollY,
    behavior: 'smooth',
  });
};

const setupIntersectionObserver = () => {
  if (!process.client) return;

  if (observer.value) {
    observer.value.disconnect();
  }

  observer.value = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting && !isLoading.value && showLoadMore.value) {
        loadMore();
      }
    },
    {
      rootMargin: '200px',
      threshold: 0.1,
    },
  );

  if (loadMoreTrigger.value) {
    observer.value.observe(loadMoreTrigger.value);
  }
};

watch([layout, currentDate], () => {
  nextTick(() => {
    setupIntersectionObserver();
  });
});

onUnmounted(() => {
  if (observer.value) {
    observer.value.disconnect();
  }
});

const isLoadingMore = ref(false);
const hasMoreContent = ref(true);

const loadMore = async () => {
  if (isLoadingMore.value) return; // Prevent multiple simultaneous loads

  isLoadingMore.value = true;

  try {
    if (layout.value === 'single') {
      showWholeDay.value = true;
      hasMoreContent.value = false;
    }

    if (layout.value === 'full') {
      const currentDateIndex = festivalDates.value.findIndex(
        (date) => date.getTime() === currentDate.value.getTime(),
      );

      if (currentDateIndex >= festivalDates.value.length - 4) {
        hasMoreContent.value = false;
      }

      await fetchEvents(festivalDates.value.slice(currentDateIndex + 1, currentDateIndex + 4));
    }
  } catch (error) {
    console.error('Error loading more content:', error);
  } finally {
    isLoadingMore.value = false;
  }
};

const showLoadMore = computed(() => {
  if (!hasMoreContent.value) return false;

  if (layout.value === 'single') {
    return (
      showHighlightedOnly &&
      (currentDateHighlightedEvents.value?.length ||
        (!currentDateHighlightedEvents.value?.length &&
          !showWholeDay.value &&
          currentDateEvents.value?.length > 3))
    );
  }

  if (layout.value === 'full') {
    return (
      currentDate.value.getTime() < festivalDates.value[festivalDates.value.length - 1].getTime()
    );
  }

  return false;
});

// Reset hasMoreContent when layout or search changes
watch(
  [layout, () => searchData.term, () => searchData.filters, () => searchData.dateFilters],
  () => {
    hasMoreContent.value = true;
  },
);

onMounted(() => {
  isMounted.value = true;

  fetchEvents([currentDate.value]);
  prepareFilters();

  nextTick(() => {
    width.value = window.innerWidth;

    window.addEventListener('resize', () => {
      width.value = window.innerWidth;
    });

    setupIntersectionObserver();
  });
});
</script>

<style scoped>
.arrowPrev {
  @screen sm {
    right: calc(100% + ((0.5 / 10) * 100%));
  }
}
.arrowNext {
  @screen sm {
    left: calc(100% + ((0.5 / 10) * 100%));
  }
}
.date {
  width: 88px;
  height: 100%;

  opacity: 0.35;

  &:after {
    display: block;
    width: 100%;
    height: 10px;
    margin-top: auto;

    border-bottom: 2px solid theme('colors.primary');
    content: '';
    transition: border-bottom-width 0.15s ease-in;
  }
}
.date--current {
  opacity: 1;

  &:after {
    border-bottom-width: 10px;
  }
}
.timeline {
  --timeline-color: theme('colors.primary.accent');

  &:before,
  &:after {
    border-left: 2px solid var(--timeline-color);
    content: '';
  }
  &:before {
    flex: 0.01 1 auto;
  }
  &:after {
    flex: 1 1 auto;
  }

  @screen lg {
    &:before {
      flex: 1 1 auto;
    }
  }
}
.timeline--highlighted {
  --timeline-color: theme('colors.primary');
}
.filterItem {
  &:before {
    width: 2px;
    height: 0.8em;
    margin-top: 0.4em;

    background-color: rgba(0, 0, 0, 0.15);
    content: '';
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
