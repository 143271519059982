<template>
  <dl class="flex flex-col lg:items-end lg:gap-2 lg:flex-row text-xl lg:text-2xl">
    <template v-for="detail in details">
      <div v-if="detail.value" class="group flex lg:flex-end" :key="detail.value">
        <dt v-if="detail.label">{{ detail.label }}:&nbsp;</dt>

        <dd
          class="font-bold"
          :class="{
            'text-primary': background === 'dark',
          }"
        >
          <a v-if="venue && detail.value.includes(venue)" :href="venueSlug">
            {{ filterString(detail.value) }}
          </a>
          <p v-else class="text-white">
            {{ detail.value }}
          </p>
        </dd>
      </div>
    </template>
  </dl>
</template>

<script setup lang="ts">
import type { BlockBackgroundType } from '~/types/common';

const {
  details,
  background = 'dark',
  slug,
  venue,
} = defineProps<{
  details: {
    label?: string;
    value?: string;
  }[];
  background?: BlockBackgroundType;
  venue?: string | undefined;
  slug?: string | undefined;
}>();

const filterString = (inputString: string) => {
  if (!inputString) return '';

  const parts = inputString.split(',').map((part) => part.trim());
  const uniqueParts = [
    ...new Set(parts.filter((part) => part.toLowerCase() !== 'undefined' && part.trim() !== '')),
  ];

  return uniqueParts.length > 1 ? uniqueParts.join(', ') : uniqueParts[0] || '';
};

const runtimeConfig = useRuntimeConfig();
const language = useState('language');

const venueSlug = ref(
  (runtimeConfig.public.siteUrl + '/' + slug).replace(`/${language.value}/`, '/'),
);
</script>

<style>
.group {
  & ~ & {
    &:before {
      margin-right: 8px;
    }
  }
}
</style>
