<template>
  <div class="overflow-hidden">
    <details ref="wrapperReference" :open="isOpen">
      <summary
        class="block pr-10 lg:pr-12 relative cursor-pointer list-none"
        :class="summaryClass"
        ref="summaryReference"
        @click.prevent="handleClick"
      >
        {{ summary }}
        <Icon
          class="absolute top-1/2 right-0 text-xl lg:text-4xl text-primary transform -translate-y-1/2 transition-transform"
          :class="{
            'rotate-180': isOpen,
          }"
          name="ChevronDown"
        />
      </summary>

      <div class="prose max-w-none richText" :class="detailsClass" ref="detailsReference">
        <Vue3RuntimeTemplate :template="renderRichText(details)" />
      </div>
    </details>
  </div>
</template>
<script setup lang="ts">
import Vue3RuntimeTemplate from 'vue3-runtime-template';
import type { AccordionItemProps } from '~/types/common';

defineProps<AccordionItemProps>();

const isOpen = ref(false);
const isAnimating = ref(false);
const wrapperReference = ref<HTMLDetailsElement | null>(null);
const summaryReference = ref<HTMLElement | null>(null);
const detailsReference = ref<HTMLDivElement | null>(null);

const handleClick = () => {
  if (isAnimating.value) {
    return;
  }

  const expandedHeight = `${(summaryReference?.value?.scrollHeight || 0) + (detailsReference?.value?.scrollHeight || 0)}px`;
  const collapsedHeight = `${summaryReference.value?.scrollHeight}px`;
  const height = isOpen.value
    ? [expandedHeight, collapsedHeight]
    : [collapsedHeight, expandedHeight];

  isAnimating.value = true;

  const animation = wrapperReference.value?.animate(
    {
      height,
    },
    {
      easing: 'ease-in-out',
      duration: 300,
    },
  );

  if (!isOpen.value) {
    isOpen.value = true;

    animation!.onfinish = () => {
      isAnimating.value = false;
    };
  } else {
    animation!.onfinish = () => {
      isAnimating.value = false;
      isOpen.value = false;
    };
  }
};
</script>
