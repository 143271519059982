<template>
  <figure class="relative" :class="{ 'bg-black': !asset }">
    <NuxtImg
      v-if="asset && assetType === 'image'"
      class="object-cover"
      :class="imageClass"
      :src="encodeURI(asset.public_id)"
      provider="represent"
      :sizes="!imageWidth ? imageSizes : ''"
      :fit="cFit"
      :modifiers="{
        aspectRatio,
        gravity: focalPoint ? 'xy_center' : 'auto',
        w: imageWidth ? imageWidth : null,
        x: focalPoint ? focalPoint.x : null,
        y: focalPoint ? focalPoint.y : null,
        customOverlay: overlayModifiers,
      }"
      :alt="asset.alt"
      :loading="imageLoading"
    />

    <MxVideoPlayerNative
      v-else-if="asset && assetType === 'video' && videoConfig?.nativePlayer"
      :wrapper-class="videoConfig?.wrapperClass"
      :player-class="videoConfig?.playerClass"
      :asset="asset"
      :autoplay="videoConfig?.autoplay"
      :muted="videoConfig?.muted"
      :loop="videoConfig?.loop"
      :hide-controls="videoConfig?.hideControls"
      :load-on-visible="videoConfig?.loadOnVisible"
      :transformations="videoConfig?.transformations"
      :poster="poster"
    />

    <MxVideoPlayer
      v-else-if="asset && assetType === 'video'"
      :wrapper-class="videoConfig?.wrapperClass"
      :player-class="videoConfig?.playerClass"
      :asset="asset"
      :autoplay="videoConfig?.autoplay"
      :muted="videoConfig?.muted"
      :loop="videoConfig?.loop"
      :hide-controls="videoConfig?.hideControls"
      :transform="videoConfig?.transform"
      :poster="poster"
    />

    <slot v-else name="empty" />

    <figcaption
      class="flex w-full px-5 py-6 justify-between items-end gap-x-10 absolute left-0 bottom-0 text-xl bg-black/50"
      v-if="(asset?.title && showCaption) || enableDownload"
    >
      <span v-if="asset?.title && showCaption" class="text-white">{{ asset.title }}</span>

      <span v-if="enableDownload" class="ml-auto shrink-0 text-primary font-semibold">
        <a :href="download ? download : asset?.filename" download>
          {{ translate('action.download') }}
        </a>
      </span>
    </figcaption>

    <slot />
  </figure>
</template>

<script setup lang="ts">
import type { SbAsset, PosterImage } from '~/types/StoryBlok';
import { getAssetType } from '~/utils/getAssetType';
import { computed } from 'vue';
import { getFocalCoordinates } from '~/utils/getFocalCoordinates';
import { useTranslation } from '~/composables/useTranslation';

const {
  asset,
  showCaption,
  imageClass,
  imageSizes = '320px xs:640px sm:768px md:1024px lg:1280px xl:1536px',
  poster,
  wrapperClass,
  fit,
  videoConfig,
  imageLoading = 'lazy',
} = defineProps<{
  asset?: SbAsset | PosterImage;
  showCaption?: boolean;
  enableDownload?: boolean;
  wrapperClass?: string;
  imageClass?: string;
  imageSizes?: string;
  aspectRatio?: string;
  fit?: string;
  imageWidth?: number;
  poster?: string;
  download?: string;
  videoConfig?: {
    autoplay?: boolean;
    muted?: boolean;
    loop?: boolean;
    hideControls?: boolean;
    wrapperClass?: string;
    playerClass?: string;
    nativePlayer?: boolean; // use native html video player instead of cloudinary (mostly for heros & collection items)
    loadOnVisible?: boolean;
    poster?: string;
    transform?: Array<Record<string, any>>; // for cloudinary player, object form
    transformations?: string; // for native html player, string form
  };
  imageLoading?: 'lazy' | 'eager';
}>();

const translate = useTranslation();
const runtimeConfig = useRuntimeConfig();
const { cloudinaryBaseUrl } = runtimeConfig.public;

const cFit = computed(() => (fit ? fit : 'fill'));
const assetType = getAssetType(asset?.filename);
const focalPoint = computed(() => {
  if (!asset?.focus) {
    return null;
  }

  return getFocalCoordinates(asset.focus);
});
const filenameSegments = asset?.filename.replace(cloudinaryBaseUrl, '').split('/');
const overlayModifiers = !/^v(\d+)?$/.test(filenameSegments?.[0]) ? filenameSegments?.[0] : null;
</script>
