<template>
  <div
    class="richText-embedWrapper grid grid-cols-8 lg:grid-cols-12 gap-x-3 lg:gap-x-6"
    :class="[`richText-embedWrapper--${container}`]"
    v-editable="blok"
  >
    <p :class="classStack">{{ text }}</p>
  </div>
</template>

<script setup lang="ts">
import type { RichTextContainerType, RichTextHighlightProps } from '~/types/RichTextBlock';

const { blok, container = 'narrow' } = defineProps<{
  blok: RichTextHighlightProps;
  container: RichTextContainerType;
}>();
const { alignment, text } = blok;

const classStack = [
  'col-span-8 text-4xl lg:text-6xl font-secondary',
  {
    'lg:col-span-6 lg:col-start-4 xl:col-span-4 xl:col-start-5 text-center whitespace-pre-wrap':
      alignment === 'center',
    'lg:col-start-5': alignment === 'right',
  },
];
</script>
