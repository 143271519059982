interface SEOData {
  _uid: string;
  plugin: string;
  title: string;
  description: string;
  og_url: string;
  og_title: string;
  og_description: string;
  og_image: string;
  twitter_image: string;
  twitter_title: string;
  twitter_description: string;
}

export const useMeta = (data: Partial<SEOData> = {}, pageLang: string) => {
  const {
    title = '',
    og_image = '',
    og_title = '',
    og_description = '',
    og_url = '',
    description = '',
    twitter_image = '',
    twitter_title = '',
    twitter_description = '',
  } = data;

  useHead({
    title: title || '',
    htmlAttrs: {
      lang: pageLang,
    },
    link: [
      {
        rel: 'icon',
        type: 'image/ico',
        href: '/favicon.ico',
      },
    ],
    meta: [
      { name: 'description', content: description },
      { name: 'twitter:title', content: twitter_title || title },
      { name: 'twitter:image', content: twitter_image || og_image },
      { name: 'twitter:description', content: twitter_description || description },
      { property: 'og:title', content: og_title || title },
      { property: 'og:image', content: og_image },
      { property: 'og:description', content: og_description || description },
      { property: 'og:url', content: og_url },
    ],
  });
};
