<template>
  <div class="bg-dark overflow-x-hidden text-white relative z-50" :id="blok?.id" v-editable="blok">
    <!-- media -->
    <div
      v-if="asset"
      class="relative pt-28 lg:pt-48 pb-10"
      :class="{
        'h-screen max-h-[600px] lg:max-h-[755px]': hasBackground,
      }"
    >
      <div v-if="hasBackground" class="absolute top-0 right-0 bottom-0 left-0 overflow-hidden">
        <!-- video -->
        <MxAssetRenderer
          v-if="isVideo && asset.public_id"
          :class="['h-full', brightness]"
          :asset="asset"
          :video-config="{
            autoplay: true,
            muted: true,
            loop: true,
            hideControls: true,
            nativePlayer: true,
            wrapperClass: 'h-full',
            playerClass: 'w-full min-w-full min-h-full max-w-none object-cover object-center',
          }"
        />

        <!-- image -->
        <template v-else-if="isImage && asset.public_id">
          <MxAssetRenderer
            v-for="size in coverImageSizes"
            :key="size.aspect"
            class="w-full h-full object-cover object-center sm:hidden"
            :class="[size.classnames, brightness]"
            :asset="asset"
            fit="fill"
            :aspect-ratio="size.aspect"
            image-class="w-full h-full object-cover object-center"
            image-loading="lazy"
            :modifiers="{ gravity: 'center' }"
          />
        </template>

        <div
          v-if="hasBackground && !blok.hideGradient"
          class="absolute bottom-0 left-0 w-full aspect-video bg-gradient-to-t from-dark to-transparent"
          aria-hidden="true"
        />
      </div>

      <HeroContentFactory :blok="blok">
        <template #top>
          <slot name="top" />
        </template>

        <template #bottom>
          <slot name="bottom" />
        </template>
      </HeroContentFactory>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { HeroBlockProps } from '~/types/HeroBlock';

const props = defineProps<{
  blok: HeroBlockProps;
}>();

const hasBackground = computed<boolean>(() => !!props.blok.asset?.filename || false);
const asset = computed(() => props.blok.asset || undefined);
const isImage = computed(() => asset.value?.filename?.includes('/image'));
const isVideo = computed(() => asset.value?.filename?.includes('/video'));
const brightness = computed(() => {
  const value = props.blok.brightness?.value;
  return value ? `brightness-${value}` : '';
});

const coverImageSizes = [
  {
    aspect: '0.625',
    classnames: 'sm:hidden',
  },
  {
    aspect: '1.3',
    classnames: 'hidden sm:block md:hidden',
  },
  {
    aspect: '2.3',
    classnames: 'hidden md:block lg:hidden',
  },
  {
    aspect: '3.33',
    classnames: 'hidden lg:block',
  },
];
</script>
