<template>
  <div :class="wrapperClass" :id="id" v-element-visibility="onVideoVisibility">
    <video :class="playerClass" ref="videoRef" v-bind="videoConfig" :poster="poster">
      <source
        v-for="(source, idx) in sources"
        :key="idx"
        :media="`(orientation: ${source.orientation}) and (max-width: ${source.breakpoint}px)`"
        :src="source.src"
        :type="source.type"
        :poster="poster"
      />
    </video>
  </div>
</template>

<script setup lang="ts">
import { vElementVisibility } from '@vueuse/components';
import { computed, onUnmounted, ref } from 'vue';
import useUtils from '~/composables/useUtils';

/**
 * Note: Native video player with media query based video source selection
 * It is intended to be used in heros and collection items
 */

const {
  asset,
  autoplay,
  loop,
  muted,
  hideControls,
  playerClass,
  wrapperClass,
  transformations,
  loadOnVisible,
  poster,
} = defineProps<{
  asset: { filename: string; public_id: string; width: number; height: number };
  autoplay?: boolean;
  loop?: boolean;
  muted?: boolean;
  hideControls?: boolean;
  playerClass?: string;
  wrapperClass?: string;
  loadOnVisible?: boolean;
  poster?: string;
  // https://cloudinary.com/documentation/transformation_reference
  transformations?: string; // format: 'eo_7,so_2' or eo_7/so_2 for chained transformations
}>();

const videoRef = ref<HTMLVideoElement | null>(null);

const videoConfig = computed(() => {
  const config = { playsinline: true };
  if (loop || autoplay) Object.assign(config, { loop: true });
  if (muted || autoplay) Object.assign(config, { muted: true });
  if (autoplay) Object.assign(config, { autoplay: true });
  if (!hideControls) Object.assign(config, { controls: true });

  return config;
});

// Note: this prevents hydration missmatch between server & client render
const { slugify } = useUtils();
const id = computed(() => slugify(asset?.public_id.replaceAll('/', '-')));

const sizes = [
  {
    // Mobile devices in portrait mode
    breakpoint: 640,
    orientation: 'portrait',
    // https://console.cloudinary.com/pm/c-dcbbd838f734d29018b8bab2d72c13/transformation_builder?transformationSignature=17066988-cd4b-399e-af2f-92526a103309
    namedTransformation: 't_fib-video-p-sm', // video sized to 480px portrait
  },
  {
    // Mobile devices (most) landscape mode (Iphone 14 ProMax is 932px wide in landscape)
    breakpoint: 960,
    orientation: 'landscape',
    // https://console.cloudinary.com/pm/c-dcbbd838f734d29018b8bab2d72c13/transformation_builder?transformationSignature=9281ff92-a3b2-3357-a176-088226b42644
    namedTransformation: 't_fib-video-l-sm', // video sized to 640px
  },
  {
    breakpoint: 1280,
    orientation: 'landscape',
    // https://console.cloudinary.com/pm/c-dcbbd838f734d29018b8bab2d72c13/transformation_builder?transformationSignature=53a4e7c6-457e-3029-a1fc-dad909011479
    namedTransformation: 't_fib-video-l-md', // video sized to 960px
  },
  {
    breakpoint: 1920,
    orientation: 'landscape',
    // https://console.cloudinary.com/pm/c-dcbbd838f734d29018b8bab2d72c13/transformation_builder?transformationSignature=99ef1e1f-7ad0-39a3-b98c-dc8f533826a0
    namedTransformation: 't_fib-video-l-lg', // video sized to 1280px
  },
  {
    breakpoint: 3840,
    orientation: 'landscape',
    // https://console.cloudinary.com/pm/c-dcbbd838f734d29018b8bab2d72c13/transformation_builder?transformationSignature=9235d947-4d5d-3dca-8ed5-613eb5ec7a5f
    namedTransformation: 't_fib-video-l-xl', // video sized to 1920px
  },
];

const baseFilename = computed(() => {
  // Removes file extension from the url by stripping everything that comes after public id
  // https://res.cloudinary.com/CLOUD_NAME/video/upload/vASSET_VERSION_NO/ASSET_PUBLIC_ID.mp4
  const publicIdEncoded = encodeURI(asset.public_id);
  const extensionIndex = asset.filename.indexOf(publicIdEncoded) + publicIdEncoded.length;
  return asset.filename.slice(0, extensionIndex);
});

// Note: this will delay rendering the video until it is visible
const activated = ref(!loadOnVisible);

const sources = computed(() => {
  const sourcesArray: any[] = [];

  if (!activated.value) {
    // If loadOnVisible, return an empty source array until video is activated (visible on screen)
    return sourcesArray;
  }

  for (const size of sizes) {
    // Prepare custom transformations passed via props
    const appendTransformations = transformations?.length ? `${transformations}/` : '';
    // Inject named transformation to source url
    const srcBase = baseFilename.value.replace(
      'video/upload/',
      `video/upload/${size.namedTransformation}/${appendTransformations}`,
    );
    // Note: order matters - browser will use first valid one, so keep webm in first position
    sourcesArray.push({ ...size, src: `${srcBase}.webm`, type: 'video/webm' });
    sourcesArray.push({ ...size, src: `${srcBase}.mp4`, type: 'video/mp4' });
  }

  return sourcesArray;
});

const onVideoVisibility = (isVisible: boolean) => {
  if (isVisible && !activated.value) {
    activated.value = true;
  }

  if (!autoplay) {
    return;
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement
  if (isVisible) {
    videoRef.value?.play();
  } else {
    videoRef.value?.pause();
  }
};

onUnmounted(() => {
  videoRef.value?.pause();
});
</script>
