import { $fetch } from 'ofetch';
import type { EventProps } from '~/types/Production';
import { useFavourites } from '~/composables/useFavourites';

// Note: /api/cio proxy is configured in server/api/[...].ts (catchAll route)

export type TickslyFavouriteEvent = Pick<EventProps, 'SyncId'>;

const addEventToFavourites = async (SyncId: string) => {
  const favouritesState = useFavourites();
  const alreadyPresent = favouritesState.value.favourites.find((f) => f === SyncId);
  if (alreadyPresent) {
    console.warn('Trying to add event to favourites but it already is there');
    return;
  }

  const tickslyFavouriteEvents = [...favouritesState.value.favourites, SyncId];
  await saveAttrubutes({ tickslyFavouriteEvents });
  storeFavouritesToState(tickslyFavouriteEvents);
};

const removeEventFromFavourites = async (SyncId: string) => {
  const favouritesState = useFavourites();
  const tickslyFavouriteEvents = favouritesState.value.favourites.filter((f) => f !== SyncId);
  await saveAttrubutes({ tickslyFavouriteEvents });
  storeFavouritesToState(tickslyFavouriteEvents);
};

const storeFavouritesToState = (favourites: string[]) => {
  const favouritesState = useFavourites();
  favouritesState.value.favourites = favourites;
};

const loadFavourites = async () => {
  const currentAttributes = await getCustomerAttributes();
  const tickslyFavouriteEvents = JSON.parse(
    currentAttributes.tickslyFavouriteEvents || '[]',
  ) as string[];
  storeFavouritesToState(tickslyFavouriteEvents);

  return tickslyFavouriteEvents;
};

// Note: if not found, it will create an identity
const getCustomerAttributes = async () => {
  const favouritesState = useFavourites();
  const email = window.sessionStorage.getItem('email');
  if (!email) {
    throw new Error('Tried to add an event to favorites but user email is empty');
  }

  try {
    favouritesState.value.isLoading = true;
    // https://customer.io/docs/api/app/?region=eu#operation/getPersonAttributes
    const response = await $fetch(`/api/cio/customers/${email}/attributes`, {
      query: { id_type: 'email' },
    });

    favouritesState.value.isLoading = false;
    const attributes = response?.customer?.attributes;
    if (!attributes) {
      console.error('Recevied an empty atrributes response from customerIo', response);
      return {};
    }
    return attributes;
  } catch (error) {
    console.log('Failed to load customer record at customerIo, creating one');
    console.error(error);
    // No identity found at customerIo, create one
    const language = useState('language');
    await saveAttrubutes({
      language_code: String(language.value),
    });
    // return an empty attributes object
    return {};
  }
};

const saveAttrubutes = async (attributes: Record<string, any> = {}) => {
  const email = window.sessionStorage.getItem('email');
  if (!email) {
    throw new Error('Tried to add an event to favorites but user email is empty');
  }
  const favouritesState = useFavourites();
  favouritesState.value.isLoading = true;
  await sendIdentifyRequest(email, attributes);
  favouritesState.value.isLoading = false;
};

const sendIdentifyRequest = async (email: string, attributes: Record<string, any> = {}) => {
  // https://customer.io/docs/api/track/#operation/entity
  // note: returns {}
  return $fetch(`/api/cio-track/v2/entity`, {
    method: 'POST',
    body: {
      type: 'person',
      identifiers: {
        email,
      },
      action: 'identify',
      attributes,
    },
  });
};

const clear = () => {
  const favouritesState = useFavourites();
  favouritesState.value.isLoading = false;
  favouritesState.value.favourites = [];
};

export const customerIoAPI = {
  loadFavourites,
  addEventToFavourites,
  removeEventFromFavourites,
  sendIdentifyRequest,
  clear,
};
