export const getAssetType = (filename: string = ''): 'video' | 'image' | undefined => {
  if (!filename) {
    return undefined;
  }

  if (filename.includes('/video')) {
    return 'video';
  }

  if (filename.includes('/image')) {
    return 'image';
  }
};
