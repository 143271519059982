import type { ISbRichtext } from 'storyblok-js-client';

export const isRichTextEmpty = (text?: ISbRichtext) => {
  // If no content field, assume empty
  if (!text?.content) {
    return true;
  }

  // If more than one paragraph, assume not empty
  if (text.content.length > 1) {
    return false;
  }

  // If on paragraph only, with no inner text assume empty
  const firstChild = text.content[0];
  if (firstChild.type === 'paragraph' && Object.keys(firstChild).length === 1) {
    return true;
  }

  // Otherwise assume not empty
  return false;
};

// This will allow rendering of a field with text content in place of a rich text field
// It allows us to safely change field type from text to rich text and not break existing content
export const safelyRenderRichText = (text?: ISbRichtext) => {
  const safeText = ensureRichText(text)
  if (!safeText || isRichTextEmpty(safeText)) {
    return '';
  }

  return renderRichText(safeText)
}

export const ensureRichText = (text?: ISbRichtext | string) => {
  if (typeof text !== 'string') {
    return text
  }

  return {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text,
          },
        ],
      },
    ],
  };
}