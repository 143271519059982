<template>
  <div>
    <AlgoliaResult :placeholder="placeholder" />
    <!-- <storyblok-stories /> -->
  </div>
</template>
<script setup lang="ts">
import AlgoliaResult from '~/components/AlgoliaResult.vue';
import type { AlgoliaBlokType } from '~/types/Algolia';

const { blok } = defineProps<{
  blok: AlgoliaBlokType;
}>();

const { placeholder } = blok;
</script>
