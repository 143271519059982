import { defineNuxtPlugin } from '#app/nuxt'
import { LazyCldImage, LazyCldMediaLibrary, LazyCldOgImage, LazyCldProductGallery, LazyCldUploadButton, LazyCldUploadWidget, LazyCldVideoPlayer } from '#components'
const lazyGlobalComponents = [
  ["CldImage", LazyCldImage],
["CldMediaLibrary", LazyCldMediaLibrary],
["CldOgImage", LazyCldOgImage],
["CldProductGallery", LazyCldProductGallery],
["CldUploadButton", LazyCldUploadButton],
["CldUploadWidget", LazyCldUploadWidget],
["CldVideoPlayer", LazyCldVideoPlayer],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
