<template>
  <div
    class="richText-embedWrapper !grid !grid-cols-8 !lg:grid-cols-12 gap-x-3 lg:gap-x-6"
    :class="[`richText-embedWrapper--${container}`]"
    v-editable="blok"
  >
    <div class="lg:col-start-3 col-span-8" :class="buttonAlignment">
      <NuxtLink :to="linkUrl" class="block">
        <MxButton
          :label="label"
          :layout="style === 'secondary' ? 'outline' : 'solid'"
          :class="classStack"
          :theme="style"
          size="medium"
        >
          <Icon class="text-xl" width="1.5" name="ArrowRight" />
        </MxButton>
      </NuxtLink>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { RichTextContainerType } from '~/types/RichTextBlock.js';

const runtimeConfig = useRuntimeConfig();

type Style = 'primary' | 'secondary';
type Alignment = 'left' | 'center' | 'right';

type linkType = {
  id: string;
  url: string;
  linktype: 'url' | 'story';
  cached_url: string;
};

const { blok, container } = defineProps<{
  blok: {
    label: string;
    link: linkType;
    style: Style;
    alignment: Alignment;
  };
  container: RichTextContainerType;
}>();

const { label, link, style, alignment } = blok;

const buttonAlignment = computed(() => {
  return {
    'flex justify-start': alignment === 'left',
    'flex justify-center': alignment === 'center',
    'flex justify-end': alignment === 'right',
  };
});

const classStack = 'flex font-normal min-w-max text-xl !px-4 !py-3';

const siteUrl = runtimeConfig.public.siteUrl;

const linkUrl = link.linktype === 'story' ? `${siteUrl}/${link.cached_url}` : link.url;
</script>
